<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="85%"
    >
      <v-card>
        <v-card-title>
          <h3>{{title}}</h3>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-card-text>
          <v-container>
            <slot name="modal-content"></slot>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="modal-actions"></slot>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name:'BmModal',
    data: () => ({
      dialog: true,
    }),
    props:{
      title:{
        type:String,
        default:null
      }
    }
  }
</script>
<style scoped>
  .h3{
    color: red; 
  }
</style>