<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-breadcrumbs
        class="pl-1"
        :items="breadCrumbs"
        large
        divider="/"
      ></v-breadcrumbs>
      <v-card elevation="0">
        <v-card-title>
          <h4>Clientes</h4>
        </v-card-title>
      <v-card-text>
        <v-row class="text-right mb-2">
          <v-col>
              <v-btn
                color="success"
                type="button"
                @click="modalClientes.isShowModal = true, modalClientes.titleModal = 'AGREGAR UN NUEVO CLIENTE ',modalClientes.isNuevo = true, cliente = {}"
              >
                Agregar Cliente
              </v-btn>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="clientes"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
          <template v-slot:[`item.nombre_completo`]="{ item }">{{ item.nombre }} {{ item.apellido_paterno }} {{item.apellido_materno}}</template>
          
          <template v-slot:[`item.activo_estatus`]="{ item }">
            <v-chip
              class="ma-2"
              :color="item.activo == 1 ? 'green_status' : 'red_status'"
              text-color="white"
            >
              {{item.activo == 1 ? 'Activo' : 'Inactivo'}}
            </v-chip>
          </template>
          
          <template v-slot:[`item.acciones`]="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="cliente = {...item},getMunicipios(cliente.estado_id), modalClientes.isShowModal = true,modalClientes.titleModal = 'EDITAR CLIENTE', modalClientes.isNuevo = false">
                  <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="eliminarCliente(item)">
                  <v-list-item-title><v-icon left> mdi-table-row-remove </v-icon>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
         <!-- class="grey lighten-5" -->
      </v-card>
      <bm-clientes-detalles 
        :modalClientes="modalClientes"
        :cliente="cliente"
        :tipoClientes="tipoClientes"
        :estados="estados"
        :municipios="municipios"
        @nuevo-cliente="nuevoCliente"
        @editar-cliente="editarCliente"
        @obtener-municipios="getMunicipios"
        @cancelar="modalClientes.isShowModal = false"
      >
      </bm-clientes-detalles>
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue'
import BmClientesDetalles from '@/views/clientes/components/BmClientesDetalles.vue'
export default {
    name:'BmClientes',
    mixins:[AlertMixin],
    components:{BmClientesDetalles,BmLoader},
    data: () => ({
        search: '',
        headers: [
          { text: 'Razon social', value: 'razon_social' },
          { text: 'RFC', value: 'rfc' },
          { text: 'Tipo', value: 'tipocliente.desc_tipocliente' },
          { text: 'Telefono', value: 'telefono' },
          { text: 'Municipio', value: 'municipio.desc_municipio'},
          { text: 'Acciones', value: 'acciones' },
        ],
        load:false,
        modalClientes:{
          isShowModal:false,
          isNuevo:false,
          titleModal:''
        },
        loader:{isLoad:false,text:''},
        cliente:{},
        activo: false,
        tipoClientes:[],
        estados:[],
        municipios:[],
        breadCrumbs:[
          {
            text: 'Clientes',
            disabled: false,
            href: '/clientes',
          },
        ]
        
    }),
    methods:{

        async getData(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.getClientes()
                await this.getTipoCliente()
                await this.getEstados()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getClientes() {
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.fetchClientes)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getTipoCliente(){
            let data = {idCatalogo:7,id:null}
            try{
                this.tipoClientes = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getEstados(){
            let data = {idCatalogo:8,id:null}
            try{
                this.estados = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getMunicipios(estado_id){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {idCatalogo:9,id:estado_id}
                this.municipios = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async nuevoCliente(cliente){
            this.modalClientes.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el cliente?',null,"Si, crear!","No, cancelar!")){
                this.modalClientes.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando cliente, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.createCliente,cliente)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
                this.modalClientes.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async editarCliente(cliente){
            this.modalClientes.isShowModal = false
            if(!await this.alertQuestion('¿Desea editar el cliente?',null,"Si, editar!","No, cancelar!")){
                this.modalClientes.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Editando cliente, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.updateCliente,cliente)
                this.alertSuccess(null,response.message)
                this.cliente = {}
            }catch(error){
                this.alertError(null,error.message)
                this.modalClientes.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async eliminarCliente(cliente){
            if(!await this.alertQuestion('¿Desea eliminar el cliente '+cliente.razon_social+'?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
                return
            }      
            this.loader = {isLoad:true,text:'Eliminando cliente, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.deleteCliente,cliente.cliente_id)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        }
    },
    computed:{
      clientes(){
        return this.$store.getters[mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.getClientes]
      }
    },
    async mounted(){
      await this.getData()
    }
}
</script>