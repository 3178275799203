<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="isShowModal" :title="'Pagos Pedido ' + pedido.pedido_id">          
            <template v-slot:modal-content>
                <v-form ref="formPedidoPagos" v-model="validPedidoPago" @submit.prevent="handleFormPedidoPagos" class="mb-4">
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-file-input
                                label="Archivo"
                                v-model="pedidoPago.file"
                                counter
                                outlined
                                hide-details="auto"
                                :rules="archivoRules"
                                dense
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Importe"
                                v-model.number="pedidoPago.importe"
                                :rules="precioRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="pedidoPago.fecha_pago"
                                    label="Fecha pago"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    :rules="fechaRules"
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="pedidoPago.fecha_pago"
                                @input="menu = false"              
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :items="metodoPagos"
                                item-text="desc_metodopago"
                                item-value="metodopago_id"
                                v-model="pedidoPago.metodopago_id"
                                :rules="metodoPagooRules"
                                label="Metodo pago"
                                outlined
                                hide-details="auto"
                                dense
                            >
                            </v-autocomplete> 
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Concepto"
                                v-model.number="pedidoPago.concepto"
                                :rules="conceptoRules"
                                @input="pedidoPago.concepto = pedidoPago.concepto.toUpperCase()"
                                outlined
                                type="Text"
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Crear Pago
                            </v-btn>
                        </v-col>         
                    </v-row>
                </v-form>
                <v-data-table
                    :headers="headers"
                    :items="pedidopagos"
                    :search="search"
                >
                <template v-slot:[`item.importe`]="{ item }">{{ item.importe | precio}}</template>
                <template v-slot:[`item.acciones`]="{ item }">
                    <v-btn
                        color="secondary"
                        fab
                        x-small
                        dark
                        @click="descargarArchivoPago(item.pedidopago_id)"
                        class="m-1"
                    >
                        <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        fab
                        x-small
                        dark
                        class="m-1"
                        @click="handleReporte(item.pedidopago_id,5)"
                    >
                        <v-icon>mdi-file-document</v-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        fab
                        x-small
                        dark
                        class="m-1"
                        @click="eliminarPago(item)"
                    >
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </template>
                </v-data-table>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Total pedido"
                            :value="pedido.total_pedido | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Total Pagado"
                            :value="totalPagado | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Total a pagar"
                            :value="pedido.total_pedido - totalPagado | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="text-right">          
                    <v-col>
                        <v-btn
                            color="error"
                            type="button"
                            @click="$emit('salir')"
                            class="mr-2"
                        >
                        Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
import mapHelper from '@/store/modules/common/map-helper'
import AlertMixin from '@/mixins/AlertMixin.vue'
export default {
    name:'BmPedidoPagos',
    components:{BmModal,BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            loader:{isLoad:false,text:''},
            search:null,
            validPedidoPago:null,
            headers: [
                { text: 'Id', value: 'pedidopago_id' },
                { text: 'Importe', value: 'importe' },
                { text: 'Metodo Pago', value: 'metodopago.desc_metodopago' },
                { text: 'Concepto', value: 'concepto' },
                { text: 'Fecha pago', value: 'fecha_pago' },
                // { text: 'Eliminado', value: 'eliminado' },
                { text: 'Acciones', value: 'acciones' },
            ],
            pedidoPago:{
                file:[],
                pedido_id:null,
                importe:0,
                fecha_pago:null
            },
            precioRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v > 0 && v <= 9999999999 || 'Ingrese un numero mayor a o igual 0',
            ],
            fechaRules:[
                v => !!v || 'La fecha es requerida',
            ],
            archivoRules:[
                v => !!v || 'El archivo es requerido',
            ],
            metodoPagooRules:[
                v => !!v || 'El metodo de pago es requerido',
            ], 
             conceptoRules:[
                v => !!v || 'El concepto es requerido',
                v => ( v && v.length < 100 ) || "Limite de caracteres permitido",
            ], 
                   
            isShowModal:false,
            menu: false,
            metodoPagos:[]
        }
    },
    props:{
        pedido:{
            type:Object,
            default: () => {}
        },
    },
    methods:{
        async handleFormPedidoPagos(){
            if(!this.$refs.formPedidoPagos.validate()){
                return
            }

            this.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el pago?',null,"Si, crear!","No, cancelar!")){
                this.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando pago, por favor espere...'}
            try{
                this.pedidoPago.pedido_id = this.pedido.pedido_id
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDO_PAGOS+mapHelper.mapfn.createPedidoPago,this.pedidoPago)
                this.pedidoPago = {
                    file:[],
                    pedido_id:null,
                    importe:0,
                    fecha_pago:null,
                    concepto:'',
                    metodopago_id:0
                },
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }

        },
        async eliminarPago(pedidoPago){

            this.isShowModal = false
            if(!await this.alertQuestion('¿Desea eliminar el pago?',null,"Si, eliminar!","No, cancelar!")){
                this.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Eliminado pago, por favor espere...'}
            try{
                
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDO_PAGOS+mapHelper.mapfn.updatePedidoPago,pedidoPago)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }

        },
        async getMetodoPagos(){
            try{
                let data = {
                    tipo:2,
                    idCatalogo:15
                }
                this.metodoPagos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)          
            }
        },
        async buscarPedidoPagos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDO_PAGOS+mapHelper.mapfn.fetchPedidoPagos,this.pedido.pedido_id)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },
        async descargarArchivoPago(pedidopago_id){
            this.isShowModal = false
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDO_PAGOS+mapHelper.mapfn.descargarArchivo,{pedidopago_id:pedidopago_id})
                
                const href = URL.createObjectURL(response);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                //link.setAttribute('download','_blank'); //or any other extension
                //document.body.appendChild(link);
                //link.click();

                // clean up "a" element & remove ObjectURL
                window.open(link, '_blank');
                //document.body.removeChild(link);
                //URL.revokeObjectURL(href);



            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },     
        async handleReporte(pedidopago_id,tipo_reporte){

            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                let data = await this.$store.dispatch(mapHelper.mapmod.BYM_REPORTES+mapHelper.mapfn.generarReporte,{pedidopago_id:pedidopago_id,tipo_reporte:tipo_reporte})
                const href = URL.createObjectURL(data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                //link.setAttribute('download','_blank'); //or any other extension
                //document.body.appendChild(link);
                //link.click();

                // clean up "a" element & remove ObjectURL
                window.open(link, '_blank');
                //document.body.removeChild(link);
                //URL.revokeObjectURL(href);
            }catch(error){
                    this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },  
    },
    computed:{
        pedidopagos(){
            return this.$store.getters[mapHelper.mapmod.BYM_PEDIDO_PAGOS+mapHelper.mapfn.getPedidoPagos]
        },
        totalPagado(){
          return (this.pedidopagos?this.pedidopagos.reduce((n,{importe}) => n+importe,0):0)
      },
    },
    mounted(){
        this.buscarPedidoPagos()
        this.getMetodoPagos()
    }
}
</script>