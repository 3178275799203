<template>
  <v-card>
    <v-tabs
      v-model="model"
      bg-color="primary"
    >
      <v-tab value="pedidos">Pedidos</v-tab>
      <v-tab value="categorias">Categorías</v-tab>
      <v-tab value="productos">Productos</v-tab>
      <v-tab value="clientes">Clientes</v-tab>
      <v-tab value="analisis">Analisis</v-tab>

    </v-tabs>
    <v-card-text>
      <template v-if="model==0">
        <bm-orders/> 
      </template>
      <template v-if="model==1">
        Categorías 
      </template>
      <template v-if="model==2">
        <bm-products/> 
      </template>
      <template v-if="model==3">
        <bm-customers/>
      </template>
      <template v-if="model==4">
        Analisis 
      </template>
      <!-- <v-window v-model="model">
        <v-window-item value="0">
          <code>{{response}}</code>
        </v-window-item>

        <v-window-item value="Categorías">
          
        </v-window-item>

        <v-window-item value="Pedidos">
          
        </v-window-item>

        <v-window-item value="Clientes">
          
        </v-window-item>

        <v-window-item value="Analisis">
          
        </v-window-item>
      </v-window> -->
    </v-card-text>
  </v-card>
</template>
<script>
import BmCustomers from './BmCustomers.vue'
import BmOrders from './BmOrders.vue'
import BmProducts from './BmProducts.vue'

export default {
  name:'BmEcommerce',
  components:{BmProducts,BmCustomers,BmOrders},
  data (){
    return{
      model: 'productos',
      response:null
    }
  },
  methods:{
  },
}
</script>

