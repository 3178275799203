import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import productoService from '@/services/productos.service'

/*eslint-disable*/

const state = () => ({
    productos: []
})

const getters = {
    getProductos : state => state.productos
}

const actions = {
    async createProducto( { dispatch }, payload){

        try{
            let response = await productoService.CrearProducto(payload)
            let filtro = { categoria_id: 0, material_id: 0, modelo_id: 0, tamano_id: 0, tipo_id: 0 }
            dispatch(mapHelper.mapfn.fetchProductos,filtro);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateProducto( { dispatch }, _payload){

        try{
            const {filtro,payload} = _payload
            let response = await productoService.EditarProducto(payload)
            dispatch(mapHelper.mapfn.fetchProductos,filtro);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async deleteProducto( { dispatch }, paylaod){

        try{
            let response = await productoService.EliminarProducto(paylaod)
            let filtro = { categoria_id: 0, material_id: 0, modelo_id: 0, tamano_id: 0, tipo_id: 0 }
            dispatch(mapHelper.mapfn.fetchProductos,filtro);        
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchProductos( { commit }, payload){

        try{
            let response = await productoService.ObtenerProductos(payload)
            commit(types.SET_PRODUCTOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async cleanProductos( { commit }){

        try{
            commit(types.SET_PRODUCTOS,[])
        }catch(error){
            throw new Error(error.message)
        }        
    }, 
}

const mutations = {

    [types.SET_PRODUCTOS](state,productos){
        state.productos = productos
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}