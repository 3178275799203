<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <h4  class="text-center" v-if="pedidos.length == 0">Sin datos</h4>
        <v-card elevation="4" class="mb-3 mx-auto" v-for="item,index in pedidos" :key="index">
            <v-card-text>
                <v-row>
                    <v-col>
                        <strong><span> Pedido {{item.pedido_id}}</span></strong><br>
                        <span>{{item.fecha_pedido}}</span>
                    </v-col>
                    <v-col>
                        <strong><span>{{item.cliente.razon_social}}</span></strong><br>
                        <span>Numero Pedido: {{item.numero_pedido}}</span>
                    </v-col>
                    <v-col>
                        <strong><span>Etapa estatus: {{item.desc_estatuscontrol?item.desc_estatuscontrol:'Sin datos'}}</span></strong><br>
                        <span>{{item.prioridad.desc_prioridad}}</span>
                    </v-col>
                    <v-col>
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            large
                            color="primary"
                            @click="pedidoControl = {isShowModal:true,pedido:item}"
                        >
                            <v-icon dark>
                                mdi-garage
                            </v-icon>
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            large
                            color="primary"
                             v-if="isPermiso(6)" 
                            @click="pedidoCaja = {isShowModal:true,pedido:item}"
                        >
                            <v-icon dark>
                                mdi-package-variant-closed
                            </v-icon>
                        </v-btn>
                    </v-col>

                </v-row>
            </v-card-text>
        </v-card>
        <bm-control-estatus
             v-if="pedidoControl.isShowModal"
            :pedido="pedidoControl.pedido"
            @salir="pedidoControl.isShowModal = false, fetchPedidosControl()"
        >
        </bm-control-estatus>
        <bm-caja-pedido
            v-if="pedidoCaja.isShowModal"
            :pedido="pedidoCaja.pedido"
            @salir="pedidoCaja.isShowModal = false"
        >
        </bm-caja-pedido>
    </div>
</template>
<script>
    import BmLoader from '@/components/loader/BmLoader.vue' 
    import BmControlEstatus from '@/views/control/components/BmControlEstatus.vue'
    import AlertMixin from '@/mixins/AlertMixin.vue'
    import mapHelper from '@/store/modules/common/map-helper'
    import BmCajaPedido from '@/views/cajas/BmCajaPedido.vue'
    import {isPermisoAccionValid} from '@/utils/permisoValidate'
    export default{
        name:'ControlPedido',
        components:{BmLoader,BmControlEstatus,BmCajaPedido},
        mixins:[AlertMixin],
        data(){
            return{
                 loader:{isLoad:false,text:''},
                 pedidoControl:{
                    isShowModal:false,
                    pedido:{},
                },
                pedidoCaja:{
                    isShowModal:false,
                    pedido:{},
                }
            }
        },
        methods:{
            async fetchPedidosControl(){
                this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
                try{              
                    await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.fetchPedidosControl)
                }catch(error){
                        this.alertError(null,error.message)
                }finally{
                    this.loader = {isLoad:false,text:''}
                }
            },
            isPermiso(accion_id){
                return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
            }
        },
        computed:{
            pedidos(){
                return this.$store.getters[mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidos]
            },
            usuario(){
                return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
            },
        },
        mounted(){
            this.fetchPedidosControl()
        }
    }
</script>
