<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-breadcrumbs
            class="pl-1"
            :items="breadCrumbs"
            large
            divider="/"
        ></v-breadcrumbs>
        <div v-if="pedido.pedido_id">
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Cliente</h4>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Razón social"
                                :value="pedido.cliente.razon_social"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>         
                </v-card-text>
            </v-card>
            <bm-filtro-producto :tipo_filtro="2" :tipo_cliente="parseInt(pedido.cliente.tipocliente_id)">
                <template v-slot:productos>
                    <v-data-table
                        :headers="headersFiltro"
                        :items="productos"
                    >
                        <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.tamano.desc_tamano }} - {{ item.tamano.medida }} </template>
                        <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                        <template v-slot:[`item.peso`]="{ item }">{{ item.peso | peso}}</template>
                        <template v-slot:[`item.acciones`]="{ item }">
                            <v-btn
                                color="success"
                                type="button"
                                @click="productoSeleccionado = item,showModalProductoDetalle = true, isNuevoProducto = true "
                            >Agregar
                            </v-btn>
                        </template>
                    </v-data-table>
                </template>
            </bm-filtro-producto>
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Productos Pedido ({{totalProductosPedido}})</h4>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headersProductos"
                        :items="pedidodetalles"
                    >
                        <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.desc_tamano }} - {{ item.medida }} </template>
                        <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                        <template v-slot:[`item.importe`]="{ item }">{{ item.precio * item.cantidad | precio}}</template>
                        <template v-slot:[`item.peso_total`]="{ item }">{{ item.peso_total | peso}}</template>
                        <template v-slot:[`item.acciones`]="{ item }">
                            <v-menu left bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="eliminarProducto(item)">
                                        <v-list-item-title><v-icon left> mdi-table-row-remove </v-icon>Eliminar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="productoSeleccionado = item,showModalProductoDetalle = true, isNuevoProducto = false ">
                                        <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <bm-pedido-producto-detalles 
                :colores="colores" 
                :producto="{...productoSeleccionado}"
                :showModalProductoDetalle="showModalProductoDetalle"
                :isNuevo="isNuevoProducto"
                @agregar-producto="agregarProducto"
                @editar-producto="editarProducto"
                @cancelar="showModalProductoDetalle = false, productoSeleccionado = {}"
            >
            </bm-pedido-producto-detalles>
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Detalles</h4>
                </v-card-title>
                <v-card-text>
                    <v-form ref="formPedido" v-model="validFormPedido" @submit.prevent="handlePedido" class="mb-4">
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="pedido.factura"
                                >
                                    <template v-slot:label>Factura</template>
                                </v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :items="prioridades"
                                    item-text="desc_prioridad"
                                    item-value="prioridad_id"
                                    v-model="pedido.prioridad_id"
                                    :rules="prioridadRules"
                                    label="Prioridad"
                                    outlined
                                    hide-details="auto"
                                    dense
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Total cajas"
                                    :value="getNumeroCajas()"
                                    hide-details="auto"
                                    outlined
                                    readonly
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Peso total"
                                    :value="pesoTotal | peso"
                                    outlined
                                    hide-details="auto"
                                    readonly
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Fecha pedido"
                                    :value="pedido.fecha_pedido"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>        
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Total productos"
                                    :value="totalProductos | precio"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-autocomplete
                                    :items="tiposenvio"
                                    item-text="desc_tipoenvio"
                                    item-value="tipoenvio_id"
                                    v-model="pedido.tipoenvio_id"
                                    :rules="tipoEnvioRules"
                                    label="Tipo Envio"
                                    outlined
                                    hide-details="auto"
                                    @change="handleTipoEnvio"
                                    dense
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Importe envio"
                                    v-model.number="pedido.envio"
                                    :rules="precioRules"
                                    outlined
                                    type="Number"
                                    :readonly="!isModificableImporteEnvio"
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Cargo MM"
                                    v-model.number="pedido.cargo_extra"
                                    :rules="precioRules"
                                    outlined
                                    type="Number"
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field
                                    label="Descuento"
                                    v-model.number="pedido.descuento"
                                    :rules="descuentoRules"
                                    outlined
                                    type="Number"
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    label="Subtotal"
                                    :value="subTotal | precio"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    label="IVA"
                                    :value="pedido.iva | precio"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    label="Importe total"
                                    :value="importeTotal | precio"
                                    outlined
                                    readonly
                                    hide-details="auto"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    label="Observaciones"
                                    v-model.trim="pedido.observaciones"
                                    outlined
                                    hide-details="auto"
                                    @input="pedido.observaciones = pedido.observaciones.toUpperCase()"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class="text-right">
                            <v-col>
                                <v-btn
                                    color="success"
                                    type="submit"
                                >
                                Guardar cambios
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>

        </div>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmFiltroProducto from '@/components/producto/BmFiltroProducto.vue'
import BmPedidoProductoDetalles from '@/views/pedidos/components/BmPedidoProductoDetalles.vue'
export default {
    name:'BmPedidoNuevo',
    components:{BmLoader,BmFiltroProducto,BmPedidoProductoDetalles},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            breadCrumbs:[
                // {
                //     text: 'Pedidos',
                //     disabled: false,
                //     href: '/pedidos',
                // },
                {
                    text: 'Editar',
                    disabled: true,
                    href: '',
                },
            ],
            prioridades:[],
            tiposenvio:[],
            colores:[],
            pedido:{
                cliente_id:'',
                prioridad_id:'',
                tipoenvio_id:'',
                observaciones:'',
                envio:0,
                cargo_extra:0,
                peso:0,
                iva:0,
                factura:false,
                pedidodetalles:[],   
                fecha_pedido:'',
                fecha_entrega:''    
            },
            cliente:{},
            headersFiltro: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'categoria.desc_categoria' },
                { text: 'Material', value: 'material.desc_material' },
                { text: 'Modelo', value: 'modelo.desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'tipo.desc_tipo' },
                { text: 'Stock', value: 'stock' , width: '5%'},
                { text: 'Peso', value: 'peso', width: '5%' },
                { text: 'Precio', value: 'precio', width: '10%' },
                { text: 'Acciones', value: 'acciones' , width: '5%'},
            ],
            headersProductos: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'desc_categoria' },
                { text: 'Material', value: 'desc_material' },
                { text: 'Modelo', value: 'desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'desc_tipo' },
                { text: 'Color', value: 'desc_color'},
                { text: 'Peso', value: 'peso_total', width: '5%' },
                { text: 'Cantidad', value: 'cantidad', width: '5%' },
                { text: 'Precio', value: 'precio', width: '10%' },
                { text: 'Importe', value: 'importe', width: '10%' },
                { text: 'Acciones', value: 'acciones' , width: '5%'},
            ],
            productoSeleccionado:{},
            showModalProductoDetalle:false,
            validFormPedido:null,
            valor_iva:0.16,
            caja:25,
            isModificableImporteEnvio:false,
            precioRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseInt(v)) && v >= 0 && v <=  99999999999|| 'Ingrese un numero mayor o igual a 0',
            ],
            prioridadRules:[
                v => !!v || 'La prioridad es requerida',
            ], 
            tipoEnvioRules:[
                v => !!v || 'El tipo de envio es requerido',
            ],
            isNuevoProducto:true,
            menu: false,
            fechaRules:[
                v => !!v || 'La fecha es requerida',
            ],
        }
    },
    methods:{
        async getPrioridades(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:11
                }
                this.prioridades = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getTiposEnvio(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:12
                }
                this.tiposenvio = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)          
            }
        },
        async getColores(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:6
                }
                this.colores = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)          
            }
        },
        async getConfiguration(){
            try{
                this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
                await this.getPrioridades()
                await this.getTiposEnvio()
                await this.getColores()
                await this.getPedido()
                await this.getPedidoDetalles()
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async agregarProducto(producto){
            
            this.showModalProductoDetalle = false
            this.productoSeleccionado = {}

            if(this.pedidodetalles.find(x => x.producto_id == producto.producto_id && x.color_id == producto.color_id)){

                if(!await this.alertQuestion('Ya existe el producto seleccionado en la lista de productos del pedido, desea agregar la cantidad ingresada al producto?',null,"Si, agregar!","No, cancelar!")){
                    return
                }

                const pedidodetalles = this.pedido.pedidodetalles.map(obj => {

                if (obj.producto_id == producto.producto_id && obj.color_id == producto.color_id) {
                        obj.cantidad += producto.cantidad
                        return obj;
                    }

                    return obj;

                })

                this.pedido.pedidodetalles = [...pedidodetalles]


            }else{
                this.pedido.pedidodetalles.unshift(producto)
            }

            //let stock = this.productos.find(x => x.producto_id == producto.producto_id).stock
            //let cantidad = this.getCantidadProducto(producto.producto_id)
            // let cantidad_stock = this.getCantidadStockProducto(producto.producto_id)

            //let stock_total = stock - cantidad >= 0 ? stock - cantidad : 0

            //producto.cantidad_stock = stock_total > producto.cantidad ? stock_total - producto.cantidad : stock_total

            // this.pedido.pedidodetalles.unshift(producto)
            this.calcularIVA()
        },
        async editarProducto(data){
            const {producto,productoDetalle} = data
            this.showModalProductoDetalle = false
            this.productoSeleccionado = {}

            if(producto.color_id != productoDetalle.color_id){
                if(this.pedidodetalles.find(x => x.producto_id == productoDetalle.producto_id && x.color_id == productoDetalle.color_id)){
                    this.alertError(null,'Ya existe el producto seleccionado en la lista de productos del pedido')
                    return
                }
            }

            const pedidodetalles = this.pedido.pedidodetalles.map(obj => {

                if (obj.producto_id == producto.producto_id && obj.color_id == producto.color_id) {
                    //return {obj, name: 'Harry'};
                    return {...productoDetalle};
                }

                return obj;

            })

            this.pedido.pedidodetalles = [...pedidodetalles]
            this.calcularIVA()
        
        },
        async eliminarProducto(producto){

            if(producto.pedidodetalle_id){

                if(!await this.alertQuestion('¿Desea eliminar el producto?',null,"Si, eliminar!","No, cancelar!")){
                    return
                }

                const pedidodetalles = this.pedido.pedidodetalles.map(obj => {

                    if (obj.pedidodetalle_id == producto.pedidodetalle_id) {
                        //return {obj, name: 'Harry'};
                        obj.eliminado = true
                        return obj
                    }

                    return obj;

                })

                this.pedido.pedidodetalles = [...pedidodetalles]
            }else{
                let index = this.pedido.pedidodetalles.findIndex( (x) => x.producto_id == producto.producto_id && x.color_id == producto.color_id )
                if(index < 0){
                    this.alertError(null,'No se encontró el producto seleccionado')
                    return
                }
                if(!await this.alertQuestion('¿Desea eliminar el producto?',null,"Si, eliminar!","No, cancelar!")){
                    return
                }
                
                this.pedido.pedidodetalles.splice(index,1)
            }
            this.calcularIVA()

        },
        getCantidadProducto(producto_id){
            return (this.pedido.pedidodetalles?this.pedido.pedidodetalles.filter(x => x.producto_id == producto_id).reduce((n,{cantidad}) => n+cantidad,0):0)
        },
        getCantidadStockProducto(producto_id){
            return (this.pedido.pedidodetalles?this.pedido.pedidodetalles.filter(x => x.producto_id == producto_id).reduce((n,{cantidad_stock}) => n+cantidad_stock,0):0)
        },
        async handlePedido(){
            if(!this.$refs.formPedido.validate()){
                return
            }

            if(this.pedido.pedidodetalles.length<=0){
                this.alertError(null,'Ingrese al menos un producto')
                return
            }

            if(!await this.alertQuestion('¿Desea editar el pedido?',null,"Si, editar!","No, cancelar!")){
                return
            }

            this.editarPedido()

        },
        async editarPedido(){
            this.loader = {isLoad:true,text:'Editando pedido, por favor espere...'}
            this.pedido.peso = this.pesoTotal
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.updatePedido,this.pedido)
                this.alertSuccess(null,response.message)
                this.$router.push({ name: 'BmPedidosCotizacion' })
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        handleTipoEnvio(){
            switch(this.pedido.tipoenvio_id){
                case 1: 
                    this.pedido.envio = 0
                    this.isModificableImporteEnvio = true
                    break;
                case 2: 
                    this.pedido.envio = 0
                    this.isModificableImporteEnvio = true
                    break;
                case 3: 
                    this.pedido.envio = 0
                    this.isModificableImporteEnvio = false
                    break;
                case 4: 
                    this.pedido.envio = 100
                    this.isModificableImporteEnvio = false
                    break;
                case 5: 
                    this.pedido.envio = 0
                    this.isModificableImporteEnvio = false
                    break;
            }
        },
        calcularEnvio(){
            let resultado = 0
            const {precio_kilo,precio_caja} = this.tiposenvio.find(x => x.tipoenvio_id == 3)

            let total_cajas = this.getNumeroCajas() * precio_caja
            let total_kilos = Math.ceil(this.pesoTotal) * precio_kilo

            resultado = total_cajas + total_kilos

            return resultado
        },
        getNumeroCajas(){
            return Math.ceil(this.pesoTotal/this.caja)
        },
        calcularIVA(){
            let iva = 0 
            if(this.pedido.factura){
                iva = this.subTotal * this.valor_iva
            }
            this.pedido.iva = iva
        },
        async getPedido(){
            try{
                let data = {
                    pedido_id:this.pedido_id
                }
                this.pedido = {...((await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedido,data)).data)[0]}
                this.pedido.pedidodetalles = []
            }catch(error){
                throw new Error(error.message)          
            }
        },
        async getPedidoDetalles(){
            try{
                let data = {
                    pedido_id:this.pedido_id
                }
                this.pedido.pedidodetalles = [...(await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidoDetalles,data)).data]
                this.pedido.prioridad_id = this.pedido.prioridad.prioridad_id
                this.pedido.tipoenvio_id = this.pedido.tipoenvio.tipoenvio_id
                this.calcularIVA()
            }catch(error){
                throw new Error(error.message)          
            }
        }
    },
    computed:{
        productos(){
                return (this.$store.getters[mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.getProductos]).filter(x => x.activo == true)
        },
        totalProductosPedido:{
            cache:false,
            get(){            
                return (this.pedidodetalles?this.pedidodetalles.reduce((n,{cantidad}) => n+cantidad,0):0)
            } 
         
        },
        pesoTotal:{
            cache:false,
            get(){            
                return (this.pedidodetalles?this.pedidodetalles.reduce((n,{peso_total}) => n+peso_total,0):0)
            }         
        },
        subTotal:{
            cache:false,
            get(){            
                return (this.pedidodetalles?this.pedidodetalles.reduce((n,{cantidad,precio}) => n+cantidad*precio,0):0) + (this.pedido.envio ? this.pedido.envio : 0) + (this.pedido.cargo_extra ? this.pedido.cargo_extra : 0 )  - (this.pedido.descuento ? this.pedido.descuento : 0 )
            }
        },
        pedidodetalles:{
            cache:false,
            get(){            
                return this.pedido.pedidodetalles.filter(function(item) { return item.eliminado == false})
            }
        },
        importeTotal:{
            cache:false,
            get(){            
                return this.subTotal + this.pedido.iva
            }
            
        },     
        totalProductos:{
            cache:false,
            get(){
                return (this.pedido.pedidodetalles?this.pedido.pedidodetalles.reduce((n,{cantidad,precio}) => n+cantidad*precio,0):0)
            }
      }
    },
    watch:{
        pedido:{
            /* eslint-disable */ 
            handler:function() {
                if(this.pedido.tipoenvio_id == 3){
                    this.pedido.envio = this.calcularEnvio()
                }
                this.calcularIVA()
            },
            deep:true,
            immediate: true
            
        }
    },
    created(){
        this.pedido_id = this.$route.params.id;
        this.getConfiguration()
    }
}
</script>