<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="isShowModal" :title="'Cajas Pedido ' + pedido.pedido_id">          
            <template v-slot:modal-content>
                <div v-if="!isDetalles">
                    <v-row class="text-right mb-2" v-if="isPermiso(14)">
                        <v-col>
                            <v-btn
                                color="success"
                                type="button"
                                @click="isDetalles = true, getDetallesPedidoCajas(), isNuevo = true"
                            >                               
                                Nueva caja
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="mb-2">
                        <v-col>
                            <v-text-field
                                label="Peso total pedido"
                                :value="pedido.peso | peso"
                                outlined
                                hide-details="auto"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                label="Peso total cajas"
                                :value="pesoCajas | peso"
                                outlined
                                hide-details="auto"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table
                        :headers="headers"
                        :items="cajas"
                        :search="search"
                    >
                        <template v-slot:[`item.acciones`]="{ item }">
                            <v-menu left bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item @click="getCajaPedidoDetalles(item)">
                                        <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isPermiso(14)" @click="eliminarCaja(item)">
                                        <v-list-item-title><v-icon left> mdi-table-row-remove </v-icon>Eliminar</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                        <template v-slot:[`item.activo`]="{ item }">
                            <v-chip
                                class="ma-2"
                                :color="item.activo ? 'green_status' : 'red_status'"
                                text-color="white"
                            >
                                {{item.activo ? 'Activo' : 'Inactivo'}}
                            </v-chip>
                        </template>
                    </v-data-table>
                    <v-row class="text-right">          
                        <v-col>
                            <v-btn
                                color="error"
                                type="button"
                                @click="$emit('salir')"
                                class="mr-2"
                            >
                            Cerrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <v-card elevation="0" class="mb-4" v-if="isPermiso(14)">
                        <v-card-title>
                            <h4>Detalles de Pedido: Piezas {{pedidoDetallesCaja ? pedidoDetallesCaja.reduce((n,{cantidad}) => n+cantidad,0) : 0}}</h4>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :headers="headersProductos"
                                :items="pedidoDetallesCaja.filter(x=>x.cantidad>0)"
                            >
                                <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.desc_tamano }} - {{ item.medida }} </template>
                                <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                                <template v-slot:[`item.importe`]="{ item }">{{ item.precio * item.cantidad | precio}}</template>
                                <template v-slot:[`item.peso_total`]="{ item }">{{ (item.peso_total) | peso}}</template>
                                <template v-slot:[`item.acciones`]="{ item }">
                                    <v-select
                                        :items="getCantidadCajas(item.cantidad)"
                                        label="Cantidad en caja"
                                        :value="0"
                                        v-model="item.cantidad_caja"
                                        @change="addProductoCaja(item,item.cantidad_caja)"
                                    ></v-select>
                                </template>
                            </v-data-table>

                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="mb-4">
                        <v-card-title>
                            <h4>Detalles en Caja: Piezas {{cajaPedido.cajapedidodetalles ? cajaPedido.cajapedidodetalles.reduce((n,{cantidad}) => n+cantidad,0) : 0}}</h4>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                :headers="headersCajas"
                                :items="cajaPedido.cajapedidodetalles.filter(x=>x.eliminado==false)"
                            >
                                <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.desc_tamano }} - {{ item.medida }} </template>
                                <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                                <template v-slot:[`item.importe`]="{ item }">{{ item.precio * item.cantidad | precio}}</template>
                                <template v-slot:[`item.peso`]="{ item }">{{ item.peso | peso}}</template>
                                <!-- <template v-slot:[`item.acciones`]="{ item }">
                                </template> -->
                            </v-data-table>

                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        label="Peso total"
                                        :value="cajaPedido.peso_total | peso"
                                        outlined
                                        hide-details="auto"
                                        readonly
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                        label="Titulo"
                                        v-model.trim="cajaPedido.titulo"
                                        outlined
                                        hide-details="auto"
                                        @input="cajaPedido.titulo = cajaPedido.titulo.toUpperCase()"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="text-right">          
                                <v-col>
                                    <v-btn
                                        color="error"
                                        type="button"
                                        @click="isDetalles = false"
                                        class="mr-2"
                                    >
                                    Cerrar
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        type="button"
                                        @click="handleGuardarCaja"
                                        class="mr-2"
                                        v-if="isPermiso(14)"
                                    >
                                    Guardar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
            </template>
        </bm-modal>
    </div>
</template>
<script>

import BmModal from '@/components/modal/BmModalFull.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
import mapHelper from '@/store/modules/common/map-helper'
import AlertMixin from '@/mixins/AlertMixin.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    components:{BmModal,BmLoader},
    loader:{isLoad:false,text:''},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            isShowModal:false,
            headers: [
                { text: 'Id', value: 'cajapedido_id' },
                { text: 'Peso total', value: 'peso_total' },
                //{ text: 'Finalizado', value: 'finalizado' },
                { text: 'Titulo', value: 'titulo' },
                { text: 'Acciones', value: 'acciones' },
            ],
            search: '',
            isDetalles:false,
            pedidoDetallesCaja:[],
            cajaPedidoDetalles:[],
            headersProductos: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'desc_categoria' },
                { text: 'Material', value: 'desc_material' },
                { text: 'Modelo', value: 'desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'desc_tipo' },
                { text: 'Color', value: 'desc_color'},
                { text: 'Peso', value: 'peso_total', width: '5%' },
                { text: 'Cantidad', value: 'cantidad', width: '5%' },
                // { text: 'Precio', value: 'precio', width: '10%' },
                // { text: 'Importe', value: 'importe', width: '10%' },
                { text: 'Acciones', value: 'acciones' , width: '50%'},
            ],
            headersCajas: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'desc_categoria' },
                { text: 'Material', value: 'desc_material' },
                { text: 'Modelo', value: 'desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'desc_tipo' },
                { text: 'Color', value: 'desc_color'},
                { text: 'Peso', value: 'peso', width: '5%' },
                { text: 'Cantidad', value: 'cantidad', width: '5%' },
                // { text: 'Precio', value: 'precio', width: '10%' },
                // { text: 'Importe', value: 'importe', width: '10%' },
                // { text: 'Acciones', value: 'acciones' , width: '50%'},
            ],
            cajaPedido:{
                peso_total:0,
                pedido_id:0,
                titulo:'',
                cajapedidodetalles:[]
            },
            isNuevo:true
        }
    },
    props:{
        pedido:{
            type:Object,
            default: () => {}
        },
    },
    methods:{
        async buscarCajas(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.fetchCajaPedidos,{pedido_id:this.pedido.pedido_id})          
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },
        async getDetallesPedidoCajas(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            this.cajaPedido = {
                peso_total:0,
                pedido_id:0,
                titulo:'',
                cajapedidodetalles:[]
            }

            try{
                this.pedidoDetallesCaja = (await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidoDetallesCajas,{pedido_id:this.pedido.pedido_id})).data
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getCajaPedidoDetalles(cajaPedido){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            this.isDetalles = true
            this.isNuevo = false
            this.cajaPedido = {
                peso_total:0,
                pedido_id:0,
                titulo:'',
                cajapedidodetalles:[]
            }
            this.pedidoDetallesCaja = []
            try{
                let response = (await this.$store.dispatch(mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.getPedidoCajaDetalles,{cajapedido_id:cajaPedido.cajapedido_id})).data
                this.pedidoDetallesCaja = (await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidoDetallesCajas,{pedido_id:this.pedido.pedido_id,cajapedido_id:cajaPedido.cajapedido_id})).data.map(function(item){
                    return {...item,cantidad_caja: (response.find(x => x.producto_id == item.producto_id) ? response.find(x => x.producto_id == item.producto_id).cantidad : 0 ) }
                })
                this.cajaPedido = {
                    ...cajaPedido,
                    cajapedidodetalles:response
                }
            }catch(error){
                this.isDetalles = false
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        addProductoCaja(producto,cantidad){
            
            if(isNaN(cantidad) || cantidad<0){
                return
            }

            
            if(this.isNuevo){
                let index_producto = this.cajaPedido.cajapedidodetalles.findIndex( (x) => x.pedidodetalle_id == producto.pedidodetalle_id)
                if(cantidad == 0){
                    if(index_producto>=0){
                        if(this.isNuevo){
                            this.cajaPedido.cajapedidodetalles.splice(index_producto,1)
                        }
                    }
    
                }else{
                    if(index_producto>=0){
                        this.cajaPedido.cajapedidodetalles[index_producto].peso = (this.cajaPedido.cajapedidodetalles[index_producto].peso/this.cajaPedido.cajapedidodetalles[index_producto].cantidad) * cantidad
                        this.cajaPedido.cajapedidodetalles[index_producto].cantidad = cantidad
                    }else{
                        let cajaPedidoDetalle = {
                            ...producto,
                            cajapedido_id:0,
                            peso:(producto.peso_total/producto.cantidad) * cantidad,
                            cantidad: cantidad
                        }
                        this.cajaPedido.cajapedidodetalles.push(cajaPedidoDetalle)
                    }
                }
            }else{
                let index_producto = this.cajaPedido.cajapedidodetalles.findIndex( (x) => x.pedidodetalle_id == producto.pedidodetalle_id && x.eliminado == false)
                if(cantidad == 0){
                    if(index_producto>=0){
                        if(this.cajaPedido.cajapedidodetalles[index_producto].cajapedidodetalle_id){
                            this.cajaPedido.cajapedidodetalles[index_producto].eliminado = true
                        }else{
                            this.cajaPedido.cajapedidodetalles.splice(index_producto,1)
                        }
                    }
    
                }else{
                    if(index_producto>=0){
                        this.cajaPedido.cajapedidodetalles[index_producto].peso = (this.cajaPedido.cajapedidodetalles[index_producto].peso/this.cajaPedido.cajapedidodetalles[index_producto].cantidad) * cantidad
                        this.cajaPedido.cajapedidodetalles[index_producto].cantidad = cantidad
                    }else{
                        let cajaPedidoDetalle = {
                            ...producto,
                            cajapedido_id:0,
                            cajapedidodetalle_id:0,
                            eliminado:false,
                            peso:(producto.peso_total/producto.cantidad) * cantidad,
                            cantidad: cantidad
                        }
                        this.cajaPedido.cajapedidodetalles.push(cajaPedidoDetalle)
                    }
                }
            }
            
        },
        getCantidadCajas(cantidad){
            let data = []
            for(let i = 0; i <= cantidad ; i++){
                data[i] = i;
            }
            return data
        },
        async handleGuardarCaja(){
            
            if(!this.cajaPedido.cajapedidodetalles || this.cajaPedido.cajapedidodetalles.length <= 0){
                return
            }

            if(!await this.alertQuestion('¿Desea guarda la caja?',null,"Si, guardar!","No, cancelar!")){
                return
            }
            
            this.loader = {isLoad:true,text:'Guardando caja, por favor espere...'}
            try{
                this.cajaPedido.pedido_id = this.pedido.pedido_id
                let response = null
                if(this.isNuevo){
                    response = await this.$store.dispatch(mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.createCajaPedido,this.cajaPedido)
                }else{
                    this.cajaPedido.eliminado = false
                    response = await this.$store.dispatch(mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.updateCajaPedido,this.cajaPedido)
                }
                this.alertSuccess(null,response.message)
                this.isDetalles = false
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async eliminarCaja(caja){
  
            if(!await this.alertQuestion('¿Desea elimiar la caja?',null,"Si, eliminar!","No, cancelar!")){
                return
            }
            
            this.loader = {isLoad:true,text:'Eliminado caja, por favor espere...'}
            try{
                caja.pedido_id = this.pedido.pedido_id
                caja.eliminado = true
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.updateCajaPedido,caja)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
    },
    computed:{
        cajas(){
            return this.$store.getters[mapHelper.mapmod.BYM_CAJA_PEDIDOS+mapHelper.mapfn.getCajaPedidos]
        },
        pesoCajas(){
            return (this.cajas?this.cajas.reduce((n,{peso_total}) => n+peso_total,0):0)
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    watch:{
        'cajaPedido.cajapedidodetalles':{
            handler(){
                console.log("caasdasd")
                this.cajaPedido.peso_total = (this.cajaPedido.cajapedidodetalles?this.cajaPedido.cajapedidodetalles.reduce((n,{peso}) => n+peso,0):0)
            },
            deep:true
        }
    },
    mounted(){
        this.buscarCajas()
    }
}
</script>