<template>
    <bm-modal v-if="modalClientes.isShowModal" :title="modalClientes.titleModal">
        <template v-slot:modal-content>
        <v-form ref="formCliente" v-model="validCliente" @submit.prevent="handleFormCliente">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Razon social*"
                        v-model.trim="clienteDetalle.razon_social"
                        :rules="razonSocialRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.razon_social = clienteDetalle.razon_social.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="RFC"
                        v-model.trim="clienteDetalle.rfc"
                        :rules="rfcRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.rfc = clienteDetalle.rfc.toUpperCase()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Telefono*"
                        v-model.trim="clienteDetalle.telefono"
                        :rules="telefonoRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.telefono = clienteDetalle.telefono.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Correo electronico*"
                        v-model.trim="clienteDetalle.correo_electronico"
                        :rules="correoElectronicoRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.correo_electronico = clienteDetalle.correo_electronico.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        :items="tipoClientes"
                        item-text="desc_tipocliente"
                        item-value="tipocliente_id"
                        v-model="clienteDetalle.tipocliente_id"
                        :rules="tipoClienteRules"
                        label="Tipo de cliente*"
                        outlined
                        dense
                        hide-details="auto"
                    >
                    </v-autocomplete> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="estados"
                        item-text="desc_estado"
                        item-value="estado_id"
                        v-model="clienteDetalle.estado_id"
                        :rules="estadoRules"
                        label="Estado*"
                        outlined
                        hide-details="auto"
                        dense
                        @change="$emit('obtener-municipios',clienteDetalle.estado_id)"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="municipios"
                        item-text="desc_municipio"
                        item-value="municipio_id"
                        v-model="clienteDetalle.municipio_id"
                        :rules="municipioRules"
                        label="Municipio*"
                        outlined
                        dense
                        hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Colonia*"
                        v-model.trim="clienteDetalle.colonia"
                        :rules="coloniaRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.colonia = clienteDetalle.colonia.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Calle"
                        v-model.trim="clienteDetalle.calle"
                        :rules="calleRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.calle = clienteDetalle.calle.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Numero exterior"
                        v-model.trim="clienteDetalle.num_ext"
                        :rules="numExtRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.num_ext = clienteDetalle.num_ext.toUpperCase()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Numero interior"
                        v-model.trim="clienteDetalle.num_int"
                        :rules="numIntRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="clienteDetalle.num_int = clienteDetalle.num_int.toUpperCase()"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Codigo postal"
                        v-model.number="clienteDetalle.codigo_postal"
                        :rules="codigoPostalRules"
                        outlined
                        hide-details="auto"
                        dense
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        label="Referencia"
                        v-model.trim="clienteDetalle.referencia"
                        :rules="referenciaRules"
                        outlined
                        hide-details="auto"
                        @input="clienteDetalle.referencia = clienteDetalle.referencia.toUpperCase()"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        label="Observaciones"
                        v-model.trim="clienteDetalle.observaciones"
                        :rules="observacionesRules"
                        outlined
                        hide-details="auto"
                        @input="clienteDetalle.observaciones = clienteDetalle.observaciones.toUpperCase()"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row class="text-right">
                <v-col>
                    <v-btn
                        color="error"
                        type="button"
                        @click="$emit('cancelar')"
                        class="mr-2"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        type="submit"
                    >
                    Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        </template>
    </bm-modal>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
export default {
    name:'BmClientesDetalles',
    components:{BmModal},
    data(){
        return{
            clienteDetalle:{},
            validCliente:null,
            razonSocialRules:[
                   v => !!v || 'La razón social es requerida',
            ],
            rfcRules:[],
            correoElectronicoRules:[
                v => !!v || 'El correo electronico es requerido',
                v => /.+@.+/.test(v) || 'Correo electronico no valido',
            ],
            telefonoRules:[
                v => !!v || 'El telefono es requerido',
            ],
            estadoRules:[
                v => !!v || 'El estado es requerido',
            ],
            municipioRules:[
                v => !!v || 'El municipio es requerido',
            ],
            coloniaRules:[
                v => !!v || 'La colonia es requerido',
            ],
            calleRules:[],
            tipoClienteRules:[
                v => !!v || 'El tipo de cliente es requerido',
            ],
            numIntRules:[],
            numExtRules:[],
            referenciaRules:[],
            codigoPostalRules:[],
            observacionesRules:[]
        }
    },
    methods:{
        async handleFormCliente(){
            if(!this.$refs.formCliente.validate()){
                return
            }

            if(this.modalClientes.isNuevo){
                if(this.prospecto){
                    this.$emit('nuevo-cliente',{cliente:this.clienteDetalle,prospecto:this.prospecto})
                }else{
                    this.$emit('nuevo-cliente',this.clienteDetalle)
                }
            }else{
                this.$emit('editar-cliente',this.clienteDetalle)
            }
        }
    },
    watch:{
        cliente(){
            this.clienteDetalle = {...this.cliente}
        }
    },
    props:{
        modalClientes:{
            type:Object,
            default: () => ({
                isShowModal:false,
                isNuevo:false,
                titleModal:''
            })
        },
        cliente:{
            type:Object,
            default: () => {}
        },
        tipoClientes:{
            type: Array,
            default: () => []
        },
        estados:{
            type: Array,
            default: () => []
        },
        municipios:{
            type: Array,
            default: () => []
        },
        prospecto:{
            type: Object,
            default : null
        }
    },
}
</script>