import ApiBYM from './config/api/ApiBYM'
import httpErrorMessage from '../utils/httpErrorMessage'
import httpstatus from '../utils/httpstatus'

export default {
    
    async ObtenerPedidos(payload) {
        try {
            
            let response = await ApiBYM.get('/v1/pedidos',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async ObtenerPedidosControl(payload) {
        try {
            
            let response = await ApiBYM.get('/v1/pedidos/control',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async ObtenerPedido(payload) {
        try {
            
            let response = await ApiBYM.get('/v1/pedidos/detalle',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async ObtenerPedidoDetalles(payload) {
        try {
            
            let response = await ApiBYM.get('/v1/pedidodetalles',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async ObtenerPedidoDetallesCajas(payload) {
        try {
            
            let response = await ApiBYM.get('/v1/pedidodetalles/cajas',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async CrearPedido(payload) {
        try {
            
            let response = await ApiBYM.post('/v1/pedidos',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async EditarPedido(payload) {
        try {
            
            let response = await ApiBYM.put('/v1/pedidos',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },

    async CrearPedidoEstatus(payload) {
        try {
            
            let response = await ApiBYM.post('/v1/pedidoestatus',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
}