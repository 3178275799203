<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-card elevation="0">
      <v-card-text>
        <!-- <v-row class="text-right mb-2">
          <v-col>
              <v-btn
                color="success"
                type="button"
                @click="modalClientes.isShowModal = true, modalClientes.titleModal = 'AGREGAR UN NUEVO CLIENTE ',modalClientes.isNuevo = true, cliente = {}"
              >
                Agregar Cliente
              </v-btn>
          </v-col>
        </v-row> -->
        <v-data-table
            :headers="headers"
            :items="products"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
          
          <template v-slot:[`item.imagen`]="{ item }">
             <v-avatar
              size="64px"
              >
                <v-img
                  :src="item.images[0].src" :alt="item.images[0].name"
                ></v-img>
              </v-avatar>
          </template>

          <template v-slot:[`item.price`]="{ item }">{{ item.price | precio}}</template>

        </v-data-table>
      </v-card-text>
      </v-card>
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
// import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue'
import ProductsService from '@/services/ecommerce/products.service'
export default {
    name:'BmProducts',
    mixins:[AlertMixin],
    components:{BmLoader},
    data: () => ({
        search: '',
        headers: [
          { text: 'Imagen', value: 'imagen' },
          { text: 'Nombre', value: 'name' },
          { text: 'Inventario', value: 'stock_status' },
          { text: 'Precio', value: 'price' },
          { text: 'Fecha', value: 'date_created_gmt' },
          // { text: 'Municipio', value: 'municipio.desc_municipio'},
          // { text: 'Acciones', value: 'acciones' },
        ],
        load:false,
        loader:{isLoad:false,text:''},
        activo: false,
        products:[]
    }),
    methods:{
        async getProducts() {
            try{
                this.loader = {isLoad:true,text:'Obteniedo productos, por favor espere...'}
                this.products = await ProductsService.GetProducts()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
      
    },
    async mounted(){
      await this.getProducts()
    }
}
</script>