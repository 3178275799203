<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>

        <v-card elevation="0" class="mb-4">
            <v-card-title>
                <h4>Usuarios</h4>
            </v-card-title>
            <v-card-text>
              <v-row class="text-right mb-2">
                <v-col>
                    <v-btn
                      color="success"
                      type="button"
                      @click="modalUsuarios.isShowModal = true, modalUsuarios.titleModal = 'AGREGAR UN NUEVO USUARIO ',modalUsuarios.isNuevo = true, usuario = {}"
                    >
                      Agregar usuario
                    </v-btn>
                </v-col>
              </v-row>
              <v-data-table
              :headers="headers"
              :items="usuarios"
              :search="search"
          >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
            <template v-slot:[`item.nombre_completo`]="{ item }">{{ item.nombre }} {{ item.apellido_paterno }} {{item.apellido_materno}}</template>
            
            <template v-slot:[`item.activo_estatus`]="{ item }">
              <v-chip
                class="ma-2"
                :color="item.activo == 1 ? 'green_status' : 'red_status'"
                text-color="white"
              >
                {{item.activo == 1 ? 'Activo' : 'Inactivo'}}
              </v-chip>
            </template>
            
            <template v-slot:[`item.acciones`]="{ item }">
              <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="usuario = {...item}, usuario.rol_id = usuario.rol.rol_id, modalUsuarios.isShowModal = true,modalUsuarios.titleModal = 'EDITAR USUARIO', modalUsuarios.isNuevo = false, activo = usuario.activo == 1 ? true : false">
                    <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="eliminarUsuario(item)">
                    <v-list-item-title><v-icon left> mdi-table-row-remove </v-icon>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
            </v-card-text>
        </v-card>
        <bm-modal v-if="modalUsuarios.isShowModal" :title="modalUsuarios.titleModal">
          <template v-slot:modal-content>
            <v-form ref="formUsuario" v-model="validUser" @submit.prevent="handleFormUsuario">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre*"
                    v-model.trim="usuario.nombre"
                    :rules="nombreRules"
                    outlined
                    hide-details="auto"
                    @input="usuario.nombre = usuario.nombre.toUpperCase()"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Apellido Paterno*"
                    v-model.trim="usuario.apellido_paterno"
                    :rules="apellidoPaternoRules"
                    outlined
                    hide-details="auto"
                    @input="usuario.apellido_paterno = usuario.apellido_paterno.toUpperCase()"
                    dense
                  ></v-text-field>
                  
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Apellido Materno*"
                    v-model.trim="usuario.apellido_materno"
                    :rules="apellidoMaternoRules"
                    outlined
                    hide-details="auto"
                    @input="usuario.apellido_materno = usuario.apellido_materno.toUpperCase()"
                    dense
                  ></v-text-field>
                  
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Correo electronico*"
                    v-model.trim="usuario.correo_electronico"
                    :rules="correoElectronicoRules"
                    outlined
                    hide-details="auto"
                    @input="usuario.correo_electronico = usuario.correo_electronico.toUpperCase()"
                    dense
                  ></v-text-field>                    
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Telefono*"
                    v-model.trim="usuario.telefono"
                    :rules="telefonoRules"
                    outlined
                    hide-details="auto"
                    dense
                  ></v-text-field>                    
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="roles"
                    item-text="desc_rol"
                    item-value="rol_id"
                    v-model="usuario.rol_id"
                    :rules="rolRules"
                    label="Rol*"
                    outlined
                    hide-details="auto"
                    dense
                  ></v-autocomplete>                  
                </v-col>
              </v-row>
              <v-row v-if="!modalUsuarios.isNuevo">
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="usuario.newPassword"
                    label="Cambiar contraseña"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6">
                  <v-switch
                    v-model="activo"
                    @change=" activo ? usuario.activo = 1 : usuario.activo = 0"
                    :label="usuario.activo == 1 ? 'Activo' : 'Inactivo'"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="modalUsuarios.isNuevo || usuario.newPassword">
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Contraseña*"
                    v-model.trim="usuario.password"
                    :rules="passwordRules"
                    outlined
                    hide-details="auto"
                    dense
                  ></v-text-field>                    
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Confirmar la contraseña*"
                    v-model.trim="usuario.c_password"
                    :rules="cpasswordRules"
                    outlined
                    hide-details="auto"
                    dense
                  ></v-text-field>                    
                </v-col>
              </v-row>
              <v-row class="text-right">
                
                <v-col>
                  <v-btn
                    color="error"
                    type="button"
                    @click="modalUsuarios.isShowModal = false"
                    class="mr-2"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="success"
                    type="submit"
                  >
                    Guardar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </bm-modal>
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmModal from '@/components/modal/BmModal.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
export default {
    name:'BmUsuarios',
    mixins:[AlertMixin],
    components:{BmModal,BmLoader},
    data: () => ({
        search: '',
        headers: [
          // {
          //   text: 'Usuario',
          //   align: 'start',
          //   filterable: false,
          //   value: 'nombre',
          // },
          { text: 'Usuario', value: 'nombre_completo' },
          { text: 'Correo Electronico', value: 'correo_electronico' },
          { text: 'Rol', value: 'rol.desc_rol' },
          { text: 'Estatus', value: 'activo_estatus', align: 'center', },
          { text: 'Acciones', value: 'acciones' },
        ],
        load:false,
        modalUsuarios:{
          isShowModal:false,
          isNuevo:false,
          titleModal:''
        },
        validUser:null,
        loader:{isLoad:false,text:''},
        usuario:{},
        activo: false,
        roles:[],
        correoElectronicoRules : [                   
            v => !!v || 'El correo electronico es requerido',
            v => /.+@.+/.test(v) || 'Correo electronico no valido',
        ],
        nombreRules : [                   
            v => !!v || 'El nombre es requerido',
        ],
        apellidoPaternoRules : [                   
            v => !!v || 'El apellido paterno es requerido',
        ],
        apellidoMaternoRules : [                   
            v => !!v || 'El apellido materno es requerido',
        ],
        rolRules : [                   
            v => !!v || 'El rol es requerido',
        ],
        telefonoRules : [                   
            v => !!v || 'El telefono es requerido',
        ],
        passwordRules : [                   
            v => !!v || 'La contraseña es requerida',
        ],
        cpasswordRules : [                   
            v => !!v || 'La confirmacion de la contraseña es requerida'],
        
    }),
    methods:{

      async getData(){
        this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
        try{
          await this.getUsuarios()
          await this.getRoles()
        }catch(error){
          this.alertError(null,error.message)
        }finally{
          this.loader = {isLoad:false,text:''}
        }
      },
      async getUsuarios() {
        try{
          await this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.fetchUsuarios)
        }catch(error){
          throw new Error(error.message)
        }
      },
      async getRoles(){
        let data = {idCatalogo:10,id:null}
        try{
          this.roles = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
        }catch(error){
          throw new Error(error.message)
        }
      },
      async handleFormUsuario(){

        if(!this.$refs.formUsuario.validate()){
          return
        }

        if(this.modalUsuarios.isNuevo){
          this.nuevoUsuario()
        }else{
          this.editarUsuario()
        }
      },
      async nuevoUsuario(){
        this.modalUsuarios.isShowModal = false
        if(!await this.alertQuestion('¿Desea crear el usuario?',null,"Si, crear!","No, cancelar!")){
          this.modalUsuarios.isShowModal = true
          return
        }
        
        this.loader = {isLoad:true,text:'Creando usuario, por favor espere...'}
        try{
            let response = await this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.createUsuario,this.usuario)
            this.alertSuccess(null,response.message)
            this.usuario = {}
        }catch(error){
            this.alertError(null,error.message)
            this.modalUsuarios.isShowModal = true
        }finally{
            this.loader = {isLoad:false,text:''}
        }
      },
      async editarUsuario(){
        this.modalUsuarios.isShowModal = false
        if(!await this.alertQuestion('¿Desea editar el usuario?',null,"Si, editar!","No, cancelar!")){
          this.modalUsuarios.isShowModal = true
          return
        }
        
        this.loader = {isLoad:true,text:'Editando usuario, por favor espere...'}
        try{
          let response = await this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.updateUsuario,this.usuario)
          this.alertSuccess(null,response.message)
          this.usuario = {}
        }catch(error){
          this.alertError(null,error.message)
          this.modalUsuarios.isShowModal = true
        }finally{
          this.loader = {isLoad:false,text:''}
        }
      },
      async eliminarUsuario(usuario){
        if(!await this.alertQuestion('¿Desea eliminar el usuario '+usuario.correo_electronico+'?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
          return
        }
        
        this.loader = {isLoad:true,text:'Eliminando usuario, por favor espere...'}
        try{
          let response = await this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.deleteUsuario,usuario.usuario_id)
          this.alertSuccess(null,response.message)
          this.usuario = {}
        }catch(error){
          this.alertError(null,error.message)
        }finally{
          this.loader = {isLoad:false,text:''}
        }
      }
    },
    computed:{
      usuarios(){
        return this.$store.getters[mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.getUsuarios]
      }
    },
    async mounted(){
      await this.getData()
    }
}
</script>