import { EcommerceInstance } from "../ecommerce.instance";
// import httphelper from "../httphelper";
import httpStatus from '../../../utils/httpstatus';
// import axiosUrlcontext from "../axios.urlcontext";
/**
 * Se encarga de proporcionar los servicios para realizar las peticiones al servidor indicado
 * @author Marco Antonio Saraos Hernandez
 */
const ApiEcommerce = {

    /**
     * Realiza una petición de tipo get a la url indicada
     * @param {String} url url 
     * @author Alejandro Trujillo Delgaodo
     * @returns Object
     */
    async get(url, params = null) {

        //EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()
        // EcommerceInstance.defaults.params= { random: Math.random()}

        let result;
        await EcommerceInstance
            .get(url /*+ '?random=' + Math.random()*/, { params }, { validateStatus: function (status) { return status == httpStatus.SUCCESS }, })
            .then((response) => {
                console.log(response)
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                console.log(error)
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },

    /**
 * Realiza una petición de tipo get a la url indicada
 * @param {String} url url 
 * @author Alejandro Trujillo Delgaodo
 * @returns Object
 */
    async getBody(url, body = null) {

        //EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()
        EcommerceInstance.defaults.body = body

        let result;
        await EcommerceInstance
            .get(url, {}, { validateStatus: function (status) { return status == httpStatus.SUCCESS } })
            .then((response) => {
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },

    /**
     * Realiza una petición de tipo get a la url indicada
     * @param {String} url url 
     * @author Alejandro Trujillo Delgaodo
     * @returns Object
     */
    async post(url, data = null) {


        // this.instance.defaults.baseURL = this.base
        //EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()

        let result;

        await EcommerceInstance
            .post(url, data, { validateStatus: function (status) { return status == httpStatus.SUCCESS } })
            .then((response) => {
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },

    /**
     * Realiza una petición de tipo get a la url indicada
     * @param {String} url url 
     * @author Alejandro Trujillo Delgaodo
     * @returns Object
     */
    async put(url, data = null) {


        // this.instance.defaults.baseURL = this.base
        // EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()

        let result;

        await EcommerceInstance
            .put(url, data, { validateStatus: function (status) { return status == httpStatus.SUCCESS } })
            .then((response) => {
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },

    /**
 * Realiza una petición de tipo get a la url indicada
 * @param {String} url url 
 * @author Alejandro Trujillo Delgaodo
 * @returns Object
 */
    async delete(url, params = null) {

        // this.instance.defaults.baseURL = this.base
        //EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()

        let result;

        await EcommerceInstance
            .delete(url, { params }, { validateStatus: function (status) { return status == httpStatus.SUCCESS } })
            .then((response) => {
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },
    /**
 * Realiza una petición de tipo get a la url indicada
 * @param {String} url url 
 * @author Alejandro Trujillo Delgaodo
 * @returns Object
 */
    async patch(url, data = null) {

        // this.instance.defaults.baseURL = this.base
        //EcommerceInstance.defaults.headers = httphelper.getCoreHeadersEcommerce()

        let result;

        await EcommerceInstance
            .patch(url, data, { validateStatus: function (status) { return status == httpStatus.SUCCESS } })
            .then((response) => {
                if (response) {
                    return response = { "success": true, "status": response.status, "statusText": response.statusText, "data": response.data };
                } else {
                    return response = { "success": false, "status": 0, "statusText": "Sin datos", "data": null };
                }
            })
            .then((contests) => {
                result = contests;
            })
            .catch((error) => {
                if (error.response) {
                    result = { "success": false, "status": error.response.status, "statusText": error.response.statusText, "data": error.response.data }
                } else {
                    result = { "success": false, "status": 0, "statusText": "Error al realizar la petición", "data": null }
                }
            });
        return result;
    },
    /*
    axios.get(url[, config])
    axios.delete(url[, config])
    axios.head(url[, config])
    axios.options(url[, config])
    axios.post(url[, data[, config]])
    axios.put(url[, data[, config]])
    axios.patch(url[, data[, config]])
    
    */

}

export default ApiEcommerce;
