import dashboardService from '@/services/dashboard.service'

/*eslint-disable*/

const actions = {
    async getDashboard( {} , payload){
        try{           
            let response = await dashboardService.obternerDashboard(payload)  
            return response  
        }catch(error){
            throw new Error(error.message)
        }
    }
}

export default {
    namespaced: true,
    actions,
}