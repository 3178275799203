import Vue from 'vue';
import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import authService from '@/services/auth.service'

/*eslint-disable*/

const state = () => ({
    token: Vue.prototype.$session ? Vue.prototype.$session.get('token'):  null,
    usuario: Vue.prototype.$session ? Vue.prototype.$session.get('usuario'):  null,
})

const getters = {
    getUsuario : state => state.usuario,
    getToken : state => state.token,
    getIsAuthenticated(state) { 
        return state.usuario && state.token ? true : false
    }
}

const actions = {
    setAuth({ commit }, sesion){

        const { usuario = {} , token } = sesion

        if(Vue.prototype.$session && Vue.prototype.$session.exists()){
            Vue.prototype.$session.destroy();
        }

        if(usuario && token){
            Vue.prototype.$session.start()
            Vue.prototype.$session.set('token', token);
            Vue.prototype.$session.set('usuario', usuario);
        }
        
        commit(types.SET_USUARIO,usuario)
        commit(types.SET_TOKEN,token)

    },
    async logIn({ dispatch  } , userSession){

        try{
            let response = await authService.LogIn(userSession)

            if(response.data){
                
                let userSession = {
                    usuario:response.data.usuario,
                    token:response.data.token
                }
                dispatch(mapHelper.mapfn.setAuth,userSession);                
            }

        }catch(error){
            throw new Error(error.message)
        }        
    },
    logOut({ commit }){
        Vue.prototype.$session.destroy();
        commit(types.SET_USUARIO,null)
        commit(types.SET_TOKEN,null)
    },
}

const mutations = {

    [types.SET_USUARIO](state,usuario){
        state.usuario = usuario
    },
    [types.SET_TOKEN](state,token){
        state.token = token
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}