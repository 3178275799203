import ApiEcommerce from '../config/api/ApiEcommerce'
import httpErrorMessage from '../../utils/httpErrorMessage'
import httpstatus from '../../utils/httpstatus'

export default {
    
    async GetOrders(payload) {
        try {
            let response = await ApiEcommerce.get('/wc/v3/orders/', payload)
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async GetOrder(id) {
        try {
            let response = await ApiEcommerce.get('/wc/v3/orders/'+id)
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async UpdateOrder(payload) {
        try {
            let response = await ApiEcommerce.put('/wc/v3/orders/'+payload.id, payload)
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async GetNotes(payload) {
        try {
            let response = await ApiEcommerce.get('/wc/v3/orders/'+payload+'/notes')
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async UpdateOrderProducts({id,line_items}) {
        try {
            let response = await ApiEcommerce.put('/wc/v3/orders/'+id, line_items)
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    }
}