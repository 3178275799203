import ApiBYM from './config/api/ApiBYM'
import httpErrorMessage from '../utils/httpErrorMessage'
import httpstatus from '../utils/httpstatus'

export default {
    
    async ObtenerUsuarios() {
        try {
            
            let response = await ApiBYM.get('/v1/usuarios')

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async CrearUsuario(data) {
        try {
            
            let response = await ApiBYM.post('/v1/usuarios',data)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async EditarUsuario(data) {
        try {

            let response = await ApiBYM.put('/v1/usuarios',data)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async EliminarUsuario(data) {
        try {
            
            let params = {usuario_id:data}
            
            let response = await ApiBYM.delete('/v1/usuarios',params)
            
            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }
            
            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
    async CheckUser() {
        try {
            await ApiBYM.get('/v1/usuarios/check')
        } catch (error) {
            throw new Error(error.message)
        }
    },
}