import auth from "./auth"
import usuarios from "./usuarios"
import catalogos from "./catalogos"
import clientes from "./clientes"
import productos from "./productos"
import precioproductos from "./precioproductos"
import pedidos from "./pedidos"
import pedidopagos from "./pedidopagos"
import controlpedidos from "./controlpedidos"
import cajapedidos from "./cajapedidos"
import reportes from "./reportes"
import dashboard from "./dashboard"
import prospectos from "./prospectos"

export default {
    namespaced : true,
    modules: {
        auth,
        usuarios,
        catalogos,
        clientes,
        productos,
        precioproductos,
        pedidos,
        pedidopagos,
        controlpedidos,
        cajapedidos,
        reportes,
        dashboard,
        prospectos
    }   
}