<template>
  <v-overlay style="z-index: 10;">
    <div class="text-center">
      <v-dialog
        v-model="loader"
        width="300"
        hide-overlay
        persistent
        overlay-opacity
      >
        <v-card
        >
        <v-container id="container-loader" class="text-center">
          <span class="img_aside">
              <img :src="logo" alt="logo">
          </span>
        </v-container>
          <v-card-text class="text-center">
            {{text}}
            <v-progress-linear
              indeterminate
              color="green"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-overlay>
</template>
<script>

  export default {
    name:'BmLoader',
    props:{
        // isLoad:{
        //     type:Boolean,
        //     default:false
        // },
        text:{
            type:String,
            default:''
        },
    },
    data(){
        return{
            loader:true,
            logo: require('@/assets/logo-bym.svg'),
            absolute: true,
            overlay: true,
        }
    },
    // watch: {
    //     visible() {
    //         this.loader = this.isLoad
    //     }
    // }
  }
</script>

<style scoped>
img {
      width: 216px;
      height: 92px;
      text-align: center;
   }
/* .v-overlay {
  z-index: 777 !important;
}
.v-card{
  z-index: 800 !important;
} */
</style>