import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import clienteService from '@/services/cliente.service';

/*eslint-disable*/

const state = () => ({
    clientes: []
})

const getters = {
    getClientes : state => state.clientes
}

const actions = {
    async createCliente( { dispatch }, data){

        try{
            let response = await clienteService.CrearCliente(data)
            dispatch(mapHelper.mapfn.fetchClientes);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateCliente( { dispatch }, data){

        try{
            let response = await clienteService.EditarCliente(data)
            dispatch(mapHelper.mapfn.fetchClientes);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async deleteCliente( { dispatch }, data){

        try{
            let response = await clienteService.EliminarCliente(data)
            dispatch(mapHelper.mapfn.fetchClientes);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchClientes( { commit }){

        try{
            let response = await clienteService.ObtenerClientes()
            commit(types.SET_CLIENTES,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_CLIENTES](state,clientes){
        state.clientes = clientes
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}