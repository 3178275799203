<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-card elevation="0" class="mb-4">
            <v-card-title>
                <h4>Pedidos Finalizados</h4>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="4">
                         <v-menu
                            v-model="menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filtro.fecha_inicio"
                                label="Fecha inicio"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="filtro.fecha_inicio"
                                @input="menu1 = false"              
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="filtro.fecha_fin"
                                label="Fecha fin"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="filtro.fecha_fin"
                                @input="menu2 = false"              
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn
                                color="success"
                                type="button"
                                @click="fetchPedidos"
                                width="100%"
                            >
                            Aplicar filtro
                            </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field flat
                            label="Total Cartera"
                            :value="cartera.total_cartera | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Total pagado"
                            :value="cartera.total_pagado  | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                            label="Total resta"
                            :value="cartera.total_resta | precio"
                            outlined
                            readonly
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="pedidos"
                    :search="search"
                >
                    <template v-slot:top>
                        <v-text-field
                        v-model="search"
                        label="Buscador"
                        class="mx-4"
                        dense
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.tamano.desc_tamano }} - {{ item.tamano.medida }} </template>
                    <template v-slot:[`item.vendedor`]="{ item }">{{ item.usuario.nombre }} {{ item.usuario.apellido_paterno }}</template>
                    <template v-slot:[`item.total_pedido`]="{ item }">{{ item.total_pedido | precio}}</template>
                    <template v-slot:[`item.total_pagado`]="{ item }">{{ item.total_pagado | precio}}</template>
                    <template v-slot:[`item.total_resta`]="{ item }">{{ item.total_pedido-item.total_pagado | precio}}</template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-menu left bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="$router.push({ name: 'BmPedidoDetalles', params:{id:item._pedido_id}})">
                                    <v-list-item-title><v-icon left> mdi-eye </v-icon>Ver</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermiso(5)" @click="pedidoPago = {isShowModal:true,pedido:item}">
                                    <v-list-item-title><v-icon left> mdi-cash-multiple </v-icon>Ver Pagos</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermisoModulo(8)" @click="pedidoControl = {isShowModal:true,pedido:item}">
                                    <v-list-item-title><v-icon left> mdi-garage</v-icon>Ver Control</v-list-item-title>
                                </v-list-item>
                                <v-list-item  v-if="isPermiso(6)" @click="pedidoCaja = {isShowModal:true,pedido:item}">
                                    <v-list-item-title><v-icon left> mdi-package-variant-closed </v-icon>Ver Cajas</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                        <v-menu right bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-file-document</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-if="isPermiso(1)" @click="handleReporte(item.pedido_id,1)">
                                    <v-list-item-title><v-icon left> mdi-printer</v-icon>Generar reporte cotizacion</v-list-item-title>
                                </v-list-item>
                                <v-list-item  v-if="isPermiso(2)" @click="handleReporte(item.pedido_id,4)">
                                    <v-list-item-title><v-icon left> mdi-forklift</v-icon>Generar reporte produccion</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermiso(3)" @click="handleReporte(item.pedido_id,2)">
                                    <v-list-item-title><v-icon left> mdi-account-hard-hat-outline</v-icon>Generar reporte makila 1</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermiso(4)" @click="handleReporte(item.pedido_id,3)">
                                    <v-list-item-title><v-icon left> mdi-account-hard-hat-outline</v-icon>Generar reporte makila 2</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip
                            class="ma-2"
                            :color="item.activo ? 'green_status' : 'red_status'"
                            text-color="white"
                        >
                            {{item.activo ? 'Activo' : 'Inactivo'}}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <bm-pedido-estatus
            v-if="pedidoEstatus.isShowModal"
            :pedido_id="pedidoEstatus.pedido_id"
            :estatuspedido_idActual="pedidoEstatus.estatuspedido_id"
            @cancelar="pedidoEstatus.isShowModal = false,fetchPedidos()"
        >
        </bm-pedido-estatus>
        <bm-pedido-pagos
            v-if="pedidoPago.isShowModal"
            :pedido="pedidoPago.pedido"
            @salir="pedidoPago.isShowModal = false, fetchPedidos()"
        >
        </bm-pedido-pagos>
        <bm-caja-pedido
            v-if="pedidoCaja.isShowModal"
            :pedido="pedidoCaja.pedido"
            @salir="pedidoCaja.isShowModal = false"
        >
        </bm-caja-pedido>
        <bm-control-estatus
             v-if="pedidoControl.isShowModal"
            :pedido="pedidoControl.pedido"
            @salir="pedidoControl.isShowModal = false"
        >
        </bm-control-estatus>
    </div>
</template>
<script>
import BmControlEstatus from '@/views/control/components/BmControlEstatus.vue'
import BmLoader from '@/components/loader/BmLoader.vue' 
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmPedidoEstatus from '@/views/pedidos/components/BmPedidoEstatus.vue'
import BmPedidoPagos from '@/views/pedidos/components/BmPedidoPagos.vue'
import BmCajaPedido from '@/views/cajas/BmCajaPedido.vue'
import {isPermisoAccionValid,isPermisoValid} from '@/utils/permisoValidate'
export default {
    components:{BmLoader,BmPedidoEstatus,BmPedidoPagos,BmCajaPedido,BmControlEstatus},
    mixins:[AlertMixin],
    name:'BmPedidos',
    data(){
        return{
            loader:{isLoad:false,text:''}, 
            breadCrumbs:[
                {
                    text: 'Pedidos Finalizados',
                    disabled: false,
                    href: '/pedidos/finalizados',
                },
            ],
            search: '',
            headers: [
                { text: 'Id', value: 'pedido_id' },
                { text: 'Cliente', value: 'cliente.razon_social' },
                { text: 'Vendedor', value: 'vendedor' },
                { text: 'N. Pedido', value: 'numero_pedido', width: '5%'},
                { text: 'Fecha', value: 'fecha_pedido' },                
                { text: 'Prioridad', value: 'prioridad.desc_prioridad' },
                { text: 'Total', value: 'total_pedido' },
                { text: 'Pagado', value: 'total_pagado' },
                { text: 'Resta', value: 'total_resta' },
                { text: 'Cajas', value: 'total_cajas', width: '5%' },
                { text: 'Acciones', value: 'acciones' , width: '10%'},
                
            ],
            filtro:{
                estatuspedido_id:3,
                cliente_id:0,
                prioridad_id:0,
                tipoenvio_id:0,
                fecha_inicio:null,
                fecha_fin:null
            },
            menu1:false,
            menu2:false,
            pedidoEstatus:{
                isShowModal:false,
                pedido_id:0,
                estatuspedido_id:0
            },
            pedidoPago:{
                isShowModal:false,
                pedido:{},
            },
            pedidoCaja:{
                isShowModal:false,
                pedido:{},
            },
            pedidoControl:{
                isShowModal:false,
                pedido:{},
            },
        }
    },
    methods:{
        async fetchPedidos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.fetchPedidos,this.filtro)
            }catch(error){
                    this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async handleReporte(pedido_id,tipo_reporte){

            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                let data = await this.$store.dispatch(mapHelper.mapmod.BYM_REPORTES+mapHelper.mapfn.generarReporte,{pedido_id:pedido_id,tipo_reporte:tipo_reporte})
                const href = URL.createObjectURL(data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                //link.setAttribute('download','_blank'); //or any other extension
                //document.body.appendChild(link);
                //link.click();

                // clean up "a" element & remove ObjectURL
                window.open(link, '_blank');
                //document.body.removeChild(link);
                //URL.revokeObjectURL(href);
            }catch(error){
                    this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        },
        isPermisoModulo(modulo_id){
            return isPermisoValid(this.usuario.rol.rol_id,modulo_id)
        }
    },
    computed:{
        pedidos(){
            return this.$store.getters[mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidos]
        },
        cartera(){
            let total_cartera = (this.pedidos?this.pedidos.reduce((n,{total_pedido}) => n+total_pedido,0):0)
            let total_pagado =(this.pedidos?this.pedidos.reduce((n,{total_pagado}) => n+total_pagado,0):0)
            let total_resta = total_cartera - total_pagado
            return {total_cartera:total_cartera,total_pagado:total_pagado,total_resta:total_resta}
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.fetchPedidos()
    }
}
</script>