<template>
        <bm-modal v-if="showModalProductoDetalle" :title=" (isNuevo ? 'Agregar Producto ': 'Editar Producto ') + producto.producto_id+' - Stock '+producto.stock">          
            <template v-slot:modal-content>
                <v-form ref="formDetalleProducto" v-model="validDetalleProducto" @submit.prevent="handleFormDetalleProducto" class="mb-4">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                :items="colores"
                                item-text="desc_color"
                                item-value="color_id"
                                v-model="color"
                                :rules="colorRules"
                                label="Colores"
                                outlined
                                hide-details="auto"
                                dense
                            >
                            </v-autocomplete> 
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Cantidad"
                                v-model.number="cantidad"
                                :rules="cantidadRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="text-right">
                        <v-col>
                            <v-btn
                                color="error"
                                type="button"
                                @click="$emit('cancelar')"
                                class="mr-2"
                            >
                            Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Agregar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </bm-modal>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
export default {
    name:'BmPedidoProductoDetalles',
    components:{BmModal},
    props:{
        producto:{
            type:Object,
            default: () => {}
        },
        showModalProductoDetalle:{
            type:Boolean,
            default: false
        },
        colores:{
            type:Array,
            default: () => []
        },
        indexLista:{
            type:Number,
            default:-1
        },
        isNuevo:{
            type:Boolean,
            default:true
        }
        
        
    },
    data(){
        return{
            validDetalleProducto:null,
            color:null,
            colorRules:[
                v => !!v || 'El color es requerido',
            ],    
            cantidad:null,
            cantidadRules:[
                v => /^(?:\d*\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseInt(v)) && v > 0 && v <=  99999999999|| 'Ingrese un numero mayor o igual a 0 y disponible en stock',
            ],
        }
    },
    methods:{
        handleFormDetalleProducto(){
            if(!this.$refs.formDetalleProducto.validate()){
                return
            }

            if(this.isNuevo){
                let producto = {...this.producto}
    
                let productoDetalle = {
                    pedidodetalle_id:0,
                    producto_id:producto.producto_id,
                    porcentaje:producto.porcentaje,
                    peso_total:producto.peso * this.cantidad,
                    stock:producto.stock,
                    modelo_id:producto.modelo.modelo_id,
                    desc_modelo:producto.modelo.desc_modelo,
                    tamano_id:producto.tamano.tamano_id,
                    desc_tamano:producto.tamano.desc_tamano,
                    medida:producto.tamano.medida,
                    material_id:producto.material.material_id,
                    desc_material:producto.material.desc_material,
                    tipo_id:producto.tipo.tipo_id,
                    desc_tipo:producto.tipo.desc_tipo,
                    categoria_id:producto.categoria.categoria_id,
                    desc_categoria:producto.categoria.desc_categoria,
                    precioproducto_id:producto.precioproducto_id,
                    precio:producto.precio,
                    cantidad:this.cantidad,
                    color_id:this.color,
                    desc_color:(this.colores.find( x=> x.color_id == this.color )).desc_color,
                    eliminado:false
                }
                this.cantidad = 0
                this.color = ''
                this.$emit('agregar-producto',productoDetalle)

            }else{
                let producto = {...this.producto}
    
                let productoDetalle = {
                    pedidodetalle_id:producto.pedidodetalle_id,
                    producto_id:producto.producto_id,
                    porcentaje:producto.porcentaje,
                    peso_total:(producto.peso_total/producto.cantidad) * this.cantidad,
                    stock:producto.stock,
                    modelo_id:producto.modelo_id,
                    desc_modelo:producto.desc_modelo,
                    tamano_id:producto.tamano_id,
                    desc_tamano:producto.desc_tamano,
                    medida:producto.medida,
                    material_id:producto.material_id,
                    desc_material:producto.desc_material,
                    tipo_id:producto.tipo_id,
                    desc_tipo:producto.desc_tipo,
                    categoria_id:producto.categoria_id,
                    desc_categoria:producto.desc_categoria,
                    precioproducto_id:producto.precioproducto_id,
                    precio:producto.precio,
                    cantidad:this.cantidad,
                    color_id:this.color,
                    desc_color:(this.colores.find( x=> x.color_id == this.color )).desc_color,
                    eliminado:false
                }
                this.cantidad = 0
                this.color = ''

                this.$emit('editar-producto',{producto:{...this.producto},productoDetalle:productoDetalle})
            }
        }
    },
    watch:{
        producto:{
            handler:function() {
                const {cantidad,color_id} = this.producto
                if(cantidad || color_id){
                    this.cantidad = cantidad
                    this.color = color_id
                }
            },
            deep:true

        }
    }
}
</script>