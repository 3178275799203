import axios from 'axios';
import urlcontext from './axios.urlcontext';
import httpstatus from '@/utils/httpstatus'
import store from "@/store";
import mapHelper from "@/store/modules/common/map-helper";
import router from '@/router';

const BYMInstance = axios.create({baseURL: urlcontext.APP_BYM_BASE});


/* Interceptores */

// Request timeout
BYMInstance.defaults.timeout = 120000

BYMInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        if(error.response.status === httpstatus.UNAUTHORIZED && !originalConfig._retry) {       
            originalConfig._retry = true;

            let sesion = { 
                usuario : null,
                token :  null,
            };
             
            store.dispatch(mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.setAuth, sesion);
            router.push('login')


        //   try {
        //         await store.dispatch(mapHelper.mapmod.SIAHE_AUTH+mapHelper.mapfn.refreshToken)

        //         let newToken = store.getters[mapHelper.mapmod.SIAHE_AUTH+mapHelper.mapfn.getToken]
        //         //let newRefreshToken = store.getters[mapHelper.mapmod.SIAHE_AUTH+mapHelper.mapfn.getRefreshToken]
            
        //         originalConfig.headers['Authorization'] = 'Bearer '+newToken
        //         //originalConfig.headers['Refreshtoken'] = newRefreshToken

        //         return instance(originalConfig);
        //     }catch (_error) {
        //         /*Si falla la obtencion del refreshtoken, se cierra la sesion */
        //         store.dispatch(mapHelper.mapmod.SIAHE_AUTH+mapHelper.mapfn.setExpired)
        //         if (_error.response && _error.response.data) {
        //             return Promise.reject(_error.response.data);
        //         }
        //         
        //     }

        }

        // if (error.response.status !== httpstatus.SUCCESS) {
        //   return Promise.reject(error.response.data);
        // }
      }
      return Promise.reject(error);
    }
  );

// Add request interceptor
// BYMInstance.interceptors.request.use(
//     config => {
//       // What to do before sending the request

//     // console.log(config);
//       return config
//     },
//     err => {
//       // What to do about request errors
//     //   Vue.prototype.$message.error('request timeout')
//        return Promise.reject(err);
//     }
//   )


// // Response interceptor
// BYMInstance.interceptors.response.use(
//     response => {
//       // res is the result of the response
//     //   console.log('response');
//     //   console.log(response);
//     //   switch (response.data.status) {
//     //     case 401:
//     //       // Login failure
//     //       // Response to successful interception
//     //       if(router.currentRoute.name == 'IndexHome') {
//     //         window.location.reload();
//     //         }else {
//     //             router.push({name: 'Home'});
//     //         }
//     //       break
//     //     case 404:
          
//     //       break
//     //     case 500:
//     //       // error
          
//     //       break
//     //     default:
//     //       return response
//     //   }
//       return response
//     },
//     err => {
//       if (err.response.data.message) {
//         console.log(err.response.data.message)
//         return Promise.reject(err.response.data.message) // Returns the error information returned by the interface
//       } else {
//         // Error handling when the return status code is not 200
//         console.log(err.toString())
//         return Promise.resolve(err)
//       }
//     }
//   )


/* */

export {
    BYMInstance
}