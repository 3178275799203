<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-breadcrumbs
        class="pl-1"
        :items="breadCrumbs"
        large
        divider="/"
      ></v-breadcrumbs>
      <v-card elevation="0">
        <v-card-title>
          <h4>Catalogos</h4>
        </v-card-title>
      <v-card-text>
        <v-row class="text-right mb-2">
            <v-col>
                <v-autocomplete
                    :items="catalogos"
                    item-text="desc_catalogo"
                    item-value="catalogo_id"
                    v-model="catalogo_select"
                    label="Catalogos"
                    outlined
                    hide-details="auto"
                    @change="getCatalogo"
                    dense
                >
                </v-autocomplete>
            </v-col>
            <v-col v-if="catalogo_select && catalogo_select != 12">
                <v-btn
                    color="success"
                    type="button"
                    @click="isCatalogoDetalles = true,modalCatalogos.isShowModal = false,modalCatalogos.titleModal = 'AGREGAR UN NUEVO '+desc_catalogo, modalCatalogos.isNuevo = true, catalogo = {}"
                >
                    AGREGAR {{desc_catalogo}}
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="catalagosList"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
            <template v-slot:[`item.activo`]="{ item }">
                <v-chip
                    class="ma-2"
                    :color="item.activo ? 'green_status' : 'red_status'"
                    text-color="white"
                >
                {{item.activo ? 'Activo' : 'Inactivo'}}
                </v-chip>
            </template>
            <template v-slot:[`item.acciones`]="{ item }">
                <v-menu left bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        >
                        <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="catalogo = {...item}, modalCatalogos.isShowModal = false,modalCatalogos.titleModal = 'EDITAR '+desc_catalogo, isCatalogoDetalles = true, modalCatalogos.isNuevo = false">
                            <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
             </template>
          <!-- <template v-slot:[`item.nombre_completo`]="{ item }">{{ item.nombre }} {{ item.apellido_paterno }} {{item.apellido_materno}}</template>
          
          <template v-slot:[`item.activo_estatus`]="{ item }">
            <v-chip
              class="ma-2"
              :color="item.activo == 1 ? 'green_status' : 'red_status'"
              text-color="white"
            >
              {{item.activo == 1 ? 'Activo' : 'Inactivo'}}
            </v-chip>
          </template>
          
          <template v-slot:[`item.acciones`]="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="cliente = {...item},getMunicipios(cliente.estado_id), modalClientes.isShowModal = true,modalClientes.titleModal = 'EDITAR CLIENTE', modalClientes.isNuevo = false">
                  <v-list-item-title>Ver/Editar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template> -->
        </v-data-table>
      </v-card-text>
         <!-- class="grey lighten-5" -->
      </v-card>
      <bm-catalogo-detalle v-if="isCatalogoDetalles"
        :catalogo_id="catalogo_select"
        :catalogo="catalogo"
        :modalCatalogos="modalCatalogos"
        :categorias="categorias"
        :materiales="materiales"
        @cancelar="modalCatalogos.isShowModal = false, isCatalogoDetalles = false"
        @abrir="modalCatalogos.isShowModal = true"
        @getCategorias="getCategorias"
        @getMateriales="getMateriales"
        @nuevo-catalogo="nuevoCatalogo"
        @editar-catalogo="editarCatalogo"
        @mostrar-modal="mostrarModal"
      >
      </bm-catalogo-detalle>
      <!-- <bm-clientes-detalles 
        :modalClientes="modalClientes"
        :cliente="cliente"
        :tipoClientes="tipoClientes"
        :estados="estados"
        :municipios="municipios"
        @nuevo-cliente="nuevoCliente"
        @editar-cliente="editarCliente"
        @obtener-municipios="getMunicipios"
        @cancelar="modalClientes.isShowModal = false"
      >
      </bm-clientes-detalles> -->
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue'
import BmCatalogoDetalle from '@/views/catalogos/components/BmCatalogoDetalles.vue'
export default {
    name:'BmCatalogos',
    mixins:[AlertMixin],
    components:{BmCatalogoDetalle,BmLoader},
    data: () => ({
        search: '',
        headers: [

        ],
        load:false,
        catalogo_select:null,
        modalCatalogos:{
          isShowModal:false,
          isNuevo:false,
          titleModal:''
        },
        loader:{isLoad:false,text:''},
        catalogo:{},
        activo: false,
        municipios:[],
        breadCrumbs:[
          {
            text: 'Catalogos',
            disabled: false,
            href: '/catalogos',
          },
        ],
        catalagosList:[],
        desc_catalogo:'',
        categorias:[],
        materiales:[],
        isCatalogoDetalles:false
        
    }),
    methods:{
        async getData(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.getCatalogos()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getCatalogos() {
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.fetchCatalogos)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getCatalogo(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:1,
                    idCatalogo:this.catalogo_select
                }
                this.catalagosList = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        mostrarModal(){
            this.modalCatalogos.isShowModal = true
        },
        async getCategorias(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:1
                }
                this.categorias = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.modalCatalogos.isShowModal = true
            }
        },
        async getMateriales(categoria_id){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:2,
                    id:categoria_id
                }
                this.materiales = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.modalCatalogos.isShowModal = true
            }
        },
        async nuevoCatalogo(catalogo){
            this.modalCatalogos.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el registro?',null,"Si, crear!","No, cancelar!")){
                this.modalCatalogos.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando registro, por favor espere...'}
            try{
                let data = {
                    catalogo:catalogo,
                    idCatalogo:this.catalogo_select
                }

                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.createCatalogo,data)
                await this.getCatalogo()
                this.alertSuccess(null,response.message)
                this.usuario = {}
                this.isCatalogoDetalles = false
            }catch(error){
                this.alertError(null,error.message)
                this.modalCatalogos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async editarCatalogo(catalogo){
            this.modalCatalogos.isShowModal = false
            if(!await this.alertQuestion('¿Desea editar el registro?',null,"Si, editar!","No, cancelar!")){
                this.modalCatalogos.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Editando registro, por favor espere...'}
            try{
                 let data = {
                    catalogo:catalogo,
                    idCatalogo:this.catalogo_select
                }
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.updateCatalogo,data)
                await this.getCatalogo()
                this.alertSuccess(null,response.message)
                this.catalogo = {}
                this.isCatalogoDetalles = false
            }catch(error){
                this.alertError(null,error.message)
                this.modalCatalogos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        // async eliminarCliente(cliente){
        //     if(!await this.alertQuestion('¿Desea eliminar el cliente '+cliente.razon_social+'?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
        //         return
        //     }      
        //     this.loader = {isLoad:true,text:'Eliminando cliente, por favor espere...'}
        //     try{
        //         let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.deleteCliente,cliente.cliente_id)
        //         this.alertSuccess(null,response.message)
        //         this.usuario = {}
        //     }catch(error){
        //         this.alertError(null,error.message)
        //     }finally{
        //         this.loader = {isLoad:false,text:''}
        //     }
        // }
    },
    computed:{
      catalogos(){
        return this.$store.getters[mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogos]
      }
    },
    watch:{
        catalogo_select(){
            switch(this.catalogo_select){
                case 1: //categorias
                    this.headers = [
                        { text: 'Id', value: 'categoria_id' },
                        { text: 'Categoria', value: 'desc_categoria' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    this.desc_catalogo = 'CATEGORIA'
                    break;
                case 2://materiales
                    this.headers = [
                        { text: 'Id', value: 'material_id' },
                        { text: 'Material', value: 'desc_material' },
                        { text: 'Categoria', value: 'categoria.desc_categoria' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    this.desc_catalogo = 'MATERIAL'
                    break;
                case 3: //modelos
                    this.headers = [
                        { text: 'Id', value: 'modelo_id' },
                        { text: 'Modelo', value: 'desc_modelo' },
                        // { text: 'Categoria', value: 'categoria.desc_categoria' },
                        { text: 'Material', value: 'material.desc_material' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    this.desc_catalogo = 'MODELO'
                    break;
                case 4: //tamaños
                    this.headers = [
                        { text: 'Id', value: 'tamano_id' },
                        { text: 'Tamaño', value: 'desc_tamano' },
                        { text: 'Medida', value: 'medida' },
                        { text: 'Categoria', value: 'categoria.desc_categoria' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    this.desc_catalogo = 'TAMAÑO'
                    break;
                case 5: 
                    this.headers = [
                        { text: 'Id', value: 'tipo_id' },
                        { text: 'Tipo', value: 'desc_tipo' },
                        { text: 'Categoria', value: 'categoria.desc_categoria' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    this.desc_catalogo = 'TIPO'
                    break;
                case 6: 
                    this.headers = [
                        { text: 'Color', value: 'desc_color' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    break;
                case 7: 
                    this.headers = [
                        { text: 'Categoria', value: 'desc_categoria' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    break;
                case 12: 
                    this.headers = [
                        { text: 'Tipo Envio', value: 'desc_tipoenvio' },
                        { text: 'Precio Caja', value: 'precio_caja' },
                        { text: 'Precio Kilo', value: 'precio_kilo' },
                        { text: 'Estatus', value: 'activo' },
                        { text: 'Acciones', value: 'acciones' ,width: '5%'},
                    ]
                    break;
            }
        }
    },
    async mounted(){
      await this.getData()
    }
}
</script>