<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="isShowModal" :title="'Comentarios ' + prospecto.razon_social">          
            <template v-slot:modal-content>
                <v-row class="text-right mb-2" v-if="!isNuevo">
                    <v-col>
                        <v-btn
                            color="success"
                            type="button"
                            @click="isNuevo = true"
                        >
                            Nuevo comentario
                        </v-btn>
                    </v-col>
                </v-row>
                <div v-if="isNuevo">
                    <v-card  elevation="1" class="mb-3 mx-auto">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-form ref="formProspectoComentario" v-model="validProspectoComentario" @submit.prevent="handleFormProspectoComentario">
                                        <v-row>
                                            <v-col cols="6" md="6">
                                                <v-menu
                                                    v-model="menu1"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="comentario.fecha_seguimiento"
                                                        label="Fecha seguimiento"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        outlined
                                                        disabled
                                                        hide-details="auto"
                                                        dense
                                                    ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="comentario.fecha_seguimiento"
                                                        @input="menu1 = false"              
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col cols="6" md="6">
                                                <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="comentario.fecha_llamada"
                                                        label="Fecha llamada"
                                                        readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                hide-details="auto"
                                                dense
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="comentario.fecha_llamada"
                                                @input="menu2 = false"              
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <v-textarea
                                            label="Comentario*"
                                            v-model.trim="comentario.descripcion"
                                            :rules="descripcionRules"
                                            outlined
                                            hide-details="auto"
                                            @input="comentario.descripcion = comentario.descripcion.toUpperCase()"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                                <v-row class="text-right">
                                    <v-col>
                                        <v-btn
                                            color="error"
                                            type="button"
                                            @click="isNuevo = false"
                                            class="mr-2"
                                        >
                                        Cancelar
                                        </v-btn>
                                        <v-btn
                                            color="success"
                                            type="submit"
                                        >
                                        Guardar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </div>
                <v-card elevation="1" class="mb-3 mx-auto" v-for="item,index in prospectosComentarios" :key="index">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <strong><span> Comentario #{{prospectosComentarios.length-index}}</span></strong><br>
                                <span>{{item.fecha_creacion | fechaDescripcion}}</span>
                            </v-col>
                            <v-col>
                                <strong>Fecha seguimiento</strong><br>
                                <span>{{item.fecha_seguimiento || 'No aplica'}}</span>
                            </v-col>
                            <v-col>
                                <strong>Fecha llamada</strong><br>
                                <span>{{item.fecha_llamada || 'No aplica'}}</span>
                            </v-col>    
                            <v-col>
                                <strong>Creado por</strong><br>
                                <span>{{item.usuario.nombre +' '+item.usuario.apellido_paterno || 'No aplica'}}</span>
                            </v-col>    


                        </v-row>
                        <v-row>
                            <v-col>
                                {{item.descripcion}}
                            </v-col>
                        </v-row>
                        <v-row v-if="isPermiso(15)">
                            <v-col>
                                <v-btn
                                        color="error"
                                        type="button"
                                        @click="eliminarProspectoComentario(item)"
                                        class="mr-2"
                                        x-small
                                        outlined
                                    >
                                    Eliminar
                                </v-btn>
                            </v-col>    
                        </v-row>
                    </v-card-text>
                </v-card>


                <v-row class="text-right">          
                    <v-col>
                        <v-btn
                            color="error"
                            type="button"
                            @click="$emit('salir')"
                            class="mr-2"
                        >
                        Cerrar
                        </v-btn>
                        <!-- <v-btn
                            color="primary"
                            type="button"
                            @click="handleGuardarCaja"
                            class="mr-2"
                            v-if="isPermiso(14)"
                        >
                        Guardar
                        </v-btn> -->
                    </v-col>
                </v-row>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import AlertMixin from '@/mixins/AlertMixin.vue'
import BmModal from '@/components/modal/BmModalFull.vue'
import mapHelper from '@/store/modules/common/map-helper'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'ControlPedido',
    components:{BmLoader,BmModal},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            isShowModal:false,
            comentario:{
                prospecto_id : this.prospecto.prospecto_id
            },
            validProspectoComentario:null,
            menu1:false,
            menu2:false,
            descripcionRules:[
                v => !!v || 'La descripción es requerida',
            ],
            isNuevo:false
        }
    },
    props:{
        prospecto:{
            type: Object,
            default: () => {}
        },
        filtro:{
            type: Object,
            default: () => {}
        }
    },
    methods:{
        async getProspectoComentarios(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.fetchProspectosComentarios,{prospecto_id:this.prospecto.prospecto_id})          
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },
        async handleFormProspectoComentario(){
            
            if(!this.$refs.formProspectoComentario.validate()){
                return
            }

            if(!await this.alertQuestion('¿Desea crear el comentario?',null,"Si, crear!","No, cancelar!")){
                return
            }
            
            this.loader = {isLoad:true,text:'Creando comentario, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.createProspectoComentarios,{comentario:this.comentario,filtro:this.filtro})
                this.alertSuccess(null,response.message)
                this.comentario = {
                    prospecto_id : this.prospecto.prospecto_id,
                    fecha_seguimiento : '',
                    fecha_llamada : ''

                },
                this.isNuevo = false
            }catch(error){
                this.alertError(null,error.message)
                this.modalProspectos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async eliminarProspectoComentario(comentario){
            
            if(!await this.alertQuestion('¿Desea eliminar el comentario?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
                return
            }      
            this.loader = {isLoad:true,text:'Eliminando comentario, por favor espere...'}
            let data = {...comentario, eliminado: true}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.updateProspectoComentarios,{comentario:data,filtro:this.filtro})
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        },
    },
    computed:{
      prospectosComentarios(){
        return this.$store.getters[mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.getProspectosComentarios]
      },
      usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    watch:{
        isNuevo(){
            if(this.isNuevo){
                var fechaHoraActual = new Date();
                this.comentario.fecha_llamada = fechaHoraActual.toISOString().split('T')[0]
                this.comentario.fecha_seguimiento = fechaHoraActual.toISOString().split('T')[0]
            }
        }
    },
    mounted(){
        this.getProspectoComentarios()
    }
}
</script>