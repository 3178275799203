import Vue from 'vue'
import VueRouter from 'vue-router'
import MainContainer from '@/layout/MainContainer.vue'
import Modules from '@/router/modules'
import BmLogin from '@/components/login/BmLogin.vue'
import {isPermisoValid} from '@/utils/permisoValidate'
Vue.use(VueRouter)

const routes = [
    {
        path:'',
        component: MainContainer,
        children: [
            ...Modules,
        ]
    },
    {
        path:'/login',
        component: BmLogin,
        name: 'Login'
    }
]

const router = new VueRouter({
    mode:'history',
    routes
});

export default router

router.beforeEach((to, from, next) => {
    let usuario = Vue.prototype.$session.get("usuario")
    let isAuthenticated = /*store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getIsAuthenticated]*/ Vue.prototype.$session ? (Vue.prototype.$session.get("token") ? true : false) : false
    if (to.name !== 'Login' && !isAuthenticated){
      next({ name: 'Login' })
    }else if(to.name === 'Login' && isAuthenticated){
      next({ name: 'BmDashboard' })
    }else if(to.meta.modulo_id && usuario && !isPermisoValid(usuario.rol.rol_id,to.meta.modulo_id)){
      if(usuario.rol.rol_id != 3){
        next({ name: 'BmDashboard' })
      }else{
        next({ name: 'BmControlPedido' })
      }
    }else{
      next()
    }
})
  
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};