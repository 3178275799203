<template>
  <v-app>
     <router-view></router-view>
  </v-app>
</template>

<script>
  import mapHelper from '@/store/modules/common/map-helper'
  export default {
    name: 'App',
    data: () => ({
      //
    }),
    created(){
      let sesion = { 
          usuario : this.$session.exists() ? this.$session.get("usuario") : null,
          token : this.$session.exists() ? this.$session.get("token") : null,
      };
      this.$store.dispatch(mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.setAuth, sesion);
      this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.checkUser); 
    }
  };
</script>
