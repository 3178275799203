<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      class="asidebar_container"
      app
    >
        <v-list-item>
          <v-list-item-content>
            <span class="img_aside">
              <img :src="logo" alt="logo">
            </span>
            <span class="user_desc">
              {{usuario.nombre}} {{usuario.apellido_paterno}} {{usuario.apellido_materno}}
            </span>
            <span class="user_rol">
              {{usuario.rol ? usuario.rol.desc_rol: 'No asignado'}}
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="$router.push({ path: item.route })"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
          left
          bottom
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="n in 1"
            :key="n"
            @click="() => {handleCerrarSesion()}"
          >
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main style="background:white">
        <v-container>
            <router-view></router-view>
        </v-container>	
    </v-main>
    <v-footer app style="height: 30px;">
        <p class="caption">©2023 Todos los derechos reservados. Creado por Soft-enix - version 2.0.1 2024-04-02</p>
    </v-footer>
  </v-app>
</template>
<script>
import mapHelper from '@/store/modules/common/map-helper'
import {isPermisoValid} from '@/utils/permisoValidate'
export default {
    name:'MainContainer',
    data: () => ({
        logo: require('@/assets/logo-bym.svg'),
        title: process.env.VUE_APP_TITLE,
        drawer:null,
        modulos: [
          { modulo_id:1,title: 'Dashboard', icon: 'mdi-chart-box-outline', route: '/' },
          { modulo_id:2,title: 'Usuarios', icon: 'mdi-account-outline', route: '/usuarios'},
          { modulo_id:3,title: 'Clientes', icon: 'mdi-account-cash-outline', route: '/clientes' },
          { modulo_id:11,title: 'Prospectos', icon: 'mdi-account-card-outline', route: '/prospectos' },     
          { modulo_id:4,title: 'Catalogos', icon: 'mdi-list-box-outline', route: '/catalogos' },
          { modulo_id:5,title: 'Productos', icon: 'mdi-view-list-outline', route: '/productos' },      
          { modulo_id:6,title: 'Cotizaciones', icon: 'mdi-archive-edit-outline', route: '/pedidos/cotizacion' },
          { modulo_id:7,title: 'Ordenes de producción', icon: 'mdi-clipboard-list-outline', route: '/pedidos/produccion' },
          { modulo_id:8,title: 'Control', icon: 'mdi-list-box-outline', route: '/control' },
          { modulo_id:9,title: 'Finalizados', icon: 'mdi-archive-check-outline', route: '/pedidos/finalizados' },
          // { modulo_id:10,title: 'E-Commerce', icon: 'mdi-cart-outline', route: '/ecommerce' },       
        ],
        right: null,
        items:[]
    }),
    methods:{
      handleCerrarSesion(){
        this.$store.dispatch(mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.logOut)
        this.$router.push({ name: 'Login' })
      },
      cargarModulos(rol_id){
          this.items = this.modulos.filter(function(item){
            return isPermisoValid(rol_id,item.modulo_id)
          })
      }
    },
    computed:{
      usuario(){
        return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
      },
    },
    mounted(){
      this.cargarModulos(this.usuario.rol.rol_id)
    },
}
</script>