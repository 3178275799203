<template>
  <v-container>
    <bm-loader v-if="loadData || load" text="Cargando, por favor espere..." />
    <div v-if="!loadData">
      <v-row>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Total pedidos pendientes:
                {{ datos1[0] ? datos1[0].num_pedidos : 0 }}</span
              >
            </v-card-title>
            <v-card-subtitle>
              <span>Producción</span>
            </v-card-subtitle>
            <v-card-text class="text-h5 font-weight-bold">
              {{ datos1[0] ? datos1[0].total : 0 | precio }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Total pedidos finalizados:
                {{ datos2[0] ? datos2[0].num_pedidos : 0 }}</span
              >
            </v-card-title>
            <v-card-subtitle>
              <span>{{ getMesDescripcion(this.mes) }},{{ anio }}</span>
            </v-card-subtitle>
            <v-card-text class="text-h5 font-weight-bold">
              {{ datos2[0] ? datos2[0].total : 0 | precio }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Total productos vendidos</span
              >
            </v-card-title>
            <v-card-subtitle>
              <span>{{ getMesDescripcion(this.mes) }},{{ anio }}</span>
            </v-card-subtitle>
            <v-card-text class="text-h5 font-weight-bold">
              {{ datos3[0] ? datos3[0].total : 0 }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Total ventas por mes al año</span
              >
              <v-spacer></v-spacer>
              <v-select
                :items="anios"
                v-model="anio_total"
                label="Año"
                density="compact"
                @change="handleTotalVentas(anio_total)"
              ></v-select>
            </v-card-title>
            <v-card-text>
              <apexchart
                width="100%"
                type="bar"
                :options="chartOptions1"
                :series="series1"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Comparacion venta por vendedor</span
              >
              <v-spacer></v-spacer>
              <v-select
                :items="anios"
                v-model="anio_vendedor"
                label="Año"
                density="compact"
                @change="handleAnioVendedor(anio_vendedor)"
              ></v-select>
            </v-card-title>
            <v-card-text>
              <apexchart
                width="100%"
                type="bar"
                :options="chartOptions3"
                :series="series3"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Comparacion ventas por años</span
              >
              <v-spacer></v-spacer>
              <v-select
                :items="anios"
                v-model="anio_comp_1"
                label="Año 1"
                density="compact"
                @change="handleComparacionAnio(anio_comp_1, anio_comp_2)"
              ></v-select>
              <v-spacer></v-spacer>
              <v-select
                :items="anios"
                v-model="anio_comp_2"
                label="Año 2"
                density="compact"
                @change="handleComparacionAnio(anio_comp_1, anio_comp_2)"
              ></v-select>
            </v-card-title>
            <v-card-text>
              <apexchart
                width="100%"
                type="bar"
                :options="chartOptions2"
                :series="series2"
              ></apexchart>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card shaped elevation="0">
            <v-card-title>
              <span class="text-h6 font-weight-light"
                >Top 10 productos mas vendidos</span
              >
              <v-spacer></v-spacer>
              <v-select
                :items="anios"
                v-model="anio_top"
                label="Año"
                density="compact"
                @change="handleTopProductos(anio_top)"
              ></v-select>
            </v-card-title>
            <v-card-text>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Producto</th>
                      <th class="text-left">Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in datos4" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ item.desc_producto }}</td>
                      <td>{{ item.total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- <v-row>
      {{datos1.total ?datos1.total : 0 | precio }}
      <v-col class="text-center">
        <span>Ventas por mes al año</span>
        <apexchart
          width="600"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col>
        <span>Ventas mensuales vendedor</span>
        <apexchart
          width="600"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
      <v-col>
        <span>Top 10 productos mas vendidos</span>
        <apexchart
          width="600"
          type="bar"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </v-col>
    </v-row> -->
  </v-container>
</template>
<script>
/* eslint-disable */
import BmLoader from "@/components/loader/BmLoader.vue";
import AlertMixin from "@/mixins/AlertMixin.vue";
import mapHelper from "@/store/modules/common/map-helper";
import numeral from "numeral";
export default {
  mixins: [AlertMixin],
  components: { BmLoader },
  name: "BmDashboard",
  data: () => ({
    chartOptions1: {
      chart: {
        id: "chart-comparacion-mensual",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      xaxis: {
        categories: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        labels: {
          formatter: function (value) {
            return numeral(value).format("($0,0.00)");
          },
        },
      },
      dataLabels: {
        formatter: function (value) {
          return numeral(value).format("($0,0.00)");
        },
        enabled: true,
        style: {
          colors: ["#333"],
        },
        offsetX: 50,
      },
    },
    series1: [
      {
        name: "Venta Mes",
        data: [],
      },
    ],

    chartOptions2: {
      chart: {
        id: "chart-comparacion-anual-mes",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      xaxis: {
        categories: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        labels: {
          formatter: function (value) {
            return numeral(value).format("($0,0.00)");
          },
        },
      },
      dataLabels: {
        formatter: function (value) {
          return numeral(value).format("($0,0.00)");
        },
        enabled: true,
        style: {
          colors: ["#333"],
        },
        offsetX: 50,
      },
    },

    series2: [
      {
        name: "2021",
        data: [12.0, 22.0, 22.0, 33.0],
      },
      {
        name: "2022",
        data: [100.0, 22.0, 333.0, 66.0],
      },
    ],

    chartOptions3: {
      chart: {
        id: "chart-vendedores-mes",
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
        dataLabels: {
          position: "top",
        },
      },
      xaxis: {
        categories: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return numeral(value).format("($0,0.00)");
          },
        },
      },
      dataLabels: {
        formatter: function (value) {
          return numeral(value).format("($0,0.00)");
        },
        enabled: true,
        style: {
          colors: ["#333"],
        },
        offsetY: 50,
      },
    },
    series3: [
      {
        name: "Vendedor",
        data: [],
      },
    ],
    // series2: [44, 55, 13, 33],

    mes: 0,
    anio: 0,

    datos1: [],
    datos2: [],
    datos3: [],
    datos4: [],
    datos5: [],
    datos6: [],
    loadData: true,
    load: false,

    anios: [],
    anio_top: 0,
    anio_total: 0,
    anio_comp_2: 0,
    anio_comp_1: 0,
    anio_vendedor: 0,
  }),
  methods: {
    async getDashboard(payload) {
      //** 1. VENTAS POR MES AL AÑO, {anio} */
      //** 3. TOTAL PEDIDO EN PRODUCCION */
      //** 5. TOTAL PRODUCTOS FINALIZADOS EN EL MES */
      let response = await this.$store.dispatch(
        mapHelper.mapmod.BYM_DASHBOARD + mapHelper.mapfn.getDashboard,
        payload
      );
      return response.data;
    },
    getMes() {
      const d = new Date();
      let month = d.getMonth();
      return month;
    },
    getAnio() {
      const d = new Date();
      let year = d.getFullYear();
      return year;
    },
    getMesDescripcion(numeroMes) {
      let descripcion = "";
      switch (numeroMes) {
        case 1:
          descripcion = "Enero";
          break;
        case 2:
          descripcion = "Febrero";
          break;
        case 3:
          descripcion = "Marzo";
          break;
        case 4:
          descripcion = "Abril";
          break;
        case 5:
          descripcion = "Mayo";
          break;
        case 6:
          descripcion = "Junio";
          break;
        case 7:
          descripcion = "Julio";
          break;
        case 8:
          descripcion = "Agosto";
          break;
        case 9:
          descripcion = "Septiembre";
          break;
        case 10:
          descripcion = "Octubre";
          break;
        case 11:
          descripcion = "Noviembre";
          break;
        case 12:
          descripcion = "Diciembre";
          break;
      }
      return descripcion;
    },
    // async getComparacionAnios(anio1, anio2) {
    //   let datos_1 = await this.getDashboard({
    //     tipo: 1,
    //     anio: anio1,
    //   });

    //   this.series2[0].data = await [
    //     ...datos_1.map(function (item) {
    //       return item.total;
    //     }),
    //   ];

    //   let datos_2 = await this.getDashboard({
    //     tipo: 1,
    //     anio: anio2,
    //   });

    //   this.series2[1].data = await [
    //     ...datos_2.map(function (item) {
    //       return item.total;
    //     }),
    //   ];
    // },
    async handleComparacionAnio(anio1, anio2) {
      try {
        this.load = true;
        let datos_1 = await this.getDashboard({
          tipo: 1,
          anio: anio1,
        });

        for (let i = 1; i <= 12; i++) {
          if (!datos_1.find((x) => x.mes === i)) {
            datos_1.push({ mes: i, total: 0 });
          }
        }

        const newData1 = (this.series2[0].data = [
          ...datos_1.map(function (item) {
            return item.total;
          }),
        ]);

        let datos_2 = await this.getDashboard({
          tipo: 1,
          anio: anio2,
        });

        for (let j = 1; j <= 12; j++) {
          if (!datos_2.find((x) => x.mes === j)) {
            datos_2.push({ mes: j, total: 0 });
          }
        }

        const newData2 = (this.series2[1].data = await [
          ...datos_2.map(function (item) {
            return item.total;
          }),
        ]);

        this.series2 = [
          {
            name: "" + anio1,
            data: newData1,
          },
          {
            name: "" + anio2,
            data: newData2,
          },
        ];
      } finally {
        this.load = false;
      }
    },
    generarAnios(anioActual) {
      for (let i = 2019; i <= anioActual; i++) {
        this.anios.push(i);
      }
    },
    async handleTopProductos(anio) {
      try {
        this.load = true;
        this.datos4 = await this.getDashboard({
          tipo: 2,
          anio: anio,
        });
      } finally {
        this.load = false;
      }
    },
    async handleTotalVentas(anio) {
      try {
        this.load = true;
        this.datos5 = await this.getDashboard({
          tipo: 1,
          anio: anio,
        });

        for (let j = 1; j <= 12; j++) {
          if (!this.datos5.find((x) => x.mes === j)) {
            this.datos5.push({ mes: j, total: 0 });
          }
        }

        const newData = (this.series1[0].data = [
          ...this.datos5.map(function (item) {
            return item.total;
          }),
        ]);

        this.series1 = [
          {
            data: newData,
          },
        ];

        // const newDataCa = (this.chartOptions1.xaxis.categories = [
        //   ...this.datos5.map((item) => {
        //     return this.getMesDescripcion(item.mes);
        //   }),
        // ]);

        // this.chartOptions1.xaxis = {
        //   categories: newDataCa,
        // };

        //       const newDataCa = (this.chartOptions3.xaxis.categories = [
        //   ...this.datos6.map(function (item) {
        //     return item.vendedor;
        //   }),
        // ]);

        // this.chartOptions3.xaxis = {
        //   categories: newDataCa,
        // };
      } finally {
        this.load = false;
      }
    },
    async handleAnioVendedor(anio) {
      this.datos6 = await this.getDashboard({
        tipo: 6,
        anio: anio,
      });

      const newData = (this.series3[0].data = await [
        ...this.datos6.map(function (item) {
          return item.total;
        }),
      ]);

      this.series3 = [
        {
          data: newData,
        },
      ];

      const newDataCa = (this.chartOptions3.xaxis.categories = [
        ...this.datos6.map(function (item) {
          return item.vendedor;
        }),
      ]);

      this.chartOptions3.xaxis = {
        categories: newDataCa,
      };
    },
  },
  async mounted() {
    try {
      this.loadData = true;

      this.mes = this.getMes() + 1;
      this.anio = this.getAnio();
      this.anio_top = this.getAnio();
      this.anio_total = this.getAnio();
      this.anio_comp_1 = this.getAnio();
      this.anio_comp_2 = this.anio_comp_1 - 1;
      this.anio_vendedor = this.getAnio();

      this.datos1 = await this.getDashboard({ tipo: 3 });
      this.datos2 = await this.getDashboard({
        tipo: 4,
        mes: this.mes,
        anio: this.anio,
      });
      this.datos3 = await this.getDashboard({
        tipo: 5,
        mes: this.mes,
        anio: this.anio,
      });
      this.datos4 = await this.getDashboard({
        tipo: 2,
        anio: this.anio,
      });

      // this.datos5 = await this.getDashboard({
      //   tipo: 1,
      //   anio: this.anio,
      // });
      // for (let i = 1; i <= 12; i++) {
      //     if (!this.datos5.find((x) => x.mes === i)) {
      //       this.datos5.push({ mes: i, total: 0 });
      //     }
      //   }

      // this.series1[0].data = await [
      //   ...this.datos5.map(function (item) {
      //     return item.total;
      //   }),
      // ];

      this.handleTotalVentas(this.anio);

      this.datos6 = await this.getDashboard({
        tipo: 6,
        anio: this.anio,
      });

      this.series3[0].data = await [
        ...this.datos6.map(function (item) {
          return item.total;
        }),
      ];

      this.chartOptions3.xaxis.categories = [
        ...this.datos6.map(function (item) {
          return item.vendedor;
        }),
      ];

      await this.handleComparacionAnio(this.anio_comp_1, this.anio_comp_2);

      this.generarAnios(this.anio);
    } finally {
      this.loadData = false;
    }
  },
};
</script>
<style scoped>
.v-input {
  max-width: 100px;
}
</style>