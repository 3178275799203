import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import controlpedidoService from '@/services/controlpedido.service'
/*eslint-disable*/

const state = () => ({
    controlPedidos: []
})

const getters = {
    getControlPedidos : state => state.controlPedidos
}

const actions = {
    async createControlPedido( {dispatch} , payload){

        try{
            let response = await controlpedidoService.CrearControlPedido(payload)
            dispatch(mapHelper.mapfn.fetchControlPedidos,{pedido_id:payload.pedido_id}); 
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchControlPedidos( { commit }, payload){

        try{
            let response = await controlpedidoService.ObtenerControlPedidos(payload)
            commit(types.SET_CONTROL_PEDIDOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async cleanControlPedidos( { commit }){

        try{
            commit(types.SET_CONTROL_PEDIDOS,[])
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_CONTROL_PEDIDOS](state,controlPedidos){
        state.controlPedidos = controlPedidos
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}