export default {
    //AUTH
    getToken: 'getToken',
    getUsuario: 'getUsuario',
    getIsAuthenticated: 'getIsAuthenticated',
    setAuth: 'setAuth',
    logOut:'logOut',
    logIn: 'logIn',

    //USUARIOS
    getUsuarios: 'getUsuarios',
    createUsuario: 'createUsuario',
    updateUsuario:'updateUsuario',
    fetchUsuarios: 'fetchUsuarios',
    deleteUsuario: 'deleteUsuario',
    checkUser: 'checkUser',

    //CATALOGOS
    getCatalogo: 'getCatalogo',
    getCatalogos: 'getCatalogos',
    createCatalogo: 'createCatalogo',
    updateCatalogo:'updateCatalogo',
    fetchCatalogos: 'fetchCatalogos',
    deleteCatalogo: 'deleteCatalogo',

    //CLIENTES
    getClientes: 'getClientes',
    createCliente: 'createCliente',
    updateCliente:'updateCliente',
    fetchClientes: 'fetchClientes',
    deleteCliente: 'deleteCliente',

    //PRODUCTOS
    getProductos: 'getProductos',
    createProducto: 'createProducto',
    updateProducto:'updateProducto',
    fetchProductos: 'fetchProductos',
    deleteProducto: 'deleteProducto',
    cleanProductos: 'cleanProductos',

    //PRECIOS
    getPrecios: 'getPrecios',
    createPrecio: 'createPrecio',
    updatePrecio:'updatePrecio',
    fetchPrecios: 'fetchPrecios',
    deletePrecio: 'deletePrecio',
    cleanPrecio: 'cleanPrecio',

    //PEDIDOS
    getPedidos: 'getPedidos',
    createPedido: 'createPedido',
    updatePedido:'updatePedido',
    fetchPedidos: 'fetchPedidos',
    delelePedido: 'delelePedido',
    cleanPedidos: 'cleanPedidos',
    getPedido: 'getPedido',
    getPedidoDetalles: 'getPedidoDetalles',
    getPedidoDetallesCajas : 'getPedidoDetallesCajas',
    createPedidoEstatus: 'createPedidoEstatus',
    fetchPedidosControl: 'fetchPedidosControl',

    //PEDIDO PAGOS
    getPedidoPagos: 'getPedidoPagos',
    createPedidoPago: 'createPedidoPago',
    updatePedidoPago:'updatePedidoPago',
    fetchPedidoPagos: 'fetchPedidoPagos',
    descargarArchivo: 'descargarArchivo',
    cancelarPago:'cancelarPago',


    //CONTROL PEDIDOS
    getControlPedidos: 'getControlPedidos',
    createControlPedido: 'createControlPedido',
    fetchControlPedidos: 'fetchControlPedidos',
    cleanControlPedidos: 'cleanControlPedidos',

    //CAJA PEDIDOS
    getCajaPedidos: 'getCajaPedidos',
    createCajaPedido: 'createCajaPedido',
    updateCajaPedido:'updateCajaPedido',
    fetchCajaPedidos: 'fetchCajaPedidos',
    cleanCajaPedidos: 'cleanCajaPedidos',
    getPedidoCajaDetalles: 'getPedidoCajaDetalles',

    generarReporte: 'generarReporte',

    getDashboard: 'getDashboard',

    //PROSPECTOS
    getProspectos : 'getProspectos',
    createProspecto : 'createProspecto',
    updateProspecto : 'updateProspecto',
    deleteProspecto : 'deleteProspecto',
    fetchProspectos : 'fetchProspectos',

    //PROSPECTOS
    getProspectosComentarios : 'getProspectosComentarios',
    createProspectoComentarios : 'createProspectoComentarios',
    updateProspectoComentarios : 'updateProspectoComentarios',
    deleteProspectoComentarios : 'deleteProspectoComentarios',
    fetchProspectosComentarios : 'fetchProspectosComentarios'

}