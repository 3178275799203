import types from '@/store/modules/common/mutation-types-core'
import cajapedidoService from '@/services/cajapedido.service'
import mapHelper from '@/store/modules/common/map-helper'
/*eslint-disable*/

const state = () => ({
    cajaPedidos: []
})

const getters = {
    getCajaPedidos : state => state.cajaPedidos
}

const actions = {
    async createCajaPedido( { dispatch } , payload){

        try{
            let response = await cajapedidoService.CrearCajaPedido(payload)
            dispatch(mapHelper.mapfn.fetchCajaPedidos,{pedido_id:payload.pedido_id}); 
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateCajaPedido( { dispatch }, payload){

        try{
            let response = await cajapedidoService.EditarCajaPedido(payload)
            dispatch(mapHelper.mapfn.fetchCajaPedidos,{pedido_id:payload.pedido_id}); 
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchCajaPedidos( { commit }, payload){

        try{
            let response = await cajapedidoService.ObtenerCajaPedidos(payload)
            commit(types.SET_CAJA_PEDIDOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async getPedidoCajaDetalles( {  }, payload){

        try{
            let response = await cajapedidoService.ObtenerCajaPedidoDetalles(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async cleanCajaPedidos( { commit }){

        try{
            commit(types.SET_CAJA_PEDIDOS,[])
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_CAJA_PEDIDOS](state,cajapedidos){
        state.cajaPedidos = cajapedidos
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}