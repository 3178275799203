<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="modalProductos.isShowModal" title="Agregar Producto">
            <template v-slot:modal-content>
                <v-form ref="formProductos" v-model="validProducto" @submit.prevent="handelFormProductos">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete                          
                                    :items="productsFilter"
                                    :item-text=" item => item.name + ' (#'+item.id+') - Inventario '+ item.stock_quantity"
                                    item-value="id"
                                    v-model="productoId"
                                    :rules="productoRules"
                                    label="Producto"
                                    outlined
                                    hide-details="auto"
                                    dense
                                    @change="handleProductoChange"
                                >
                                </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Cantidad"
                                v-model.trim="productoAgregar.cantidad"
                                :rules="cantidadRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>     
                    <v-row class="text-right">
                        <v-col>
                            <v-btn
                                color="error"
                                type="button"
                                @click="$emit('cancelar')"
                                class="mr-2"
                            >
                            Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Guardar
                            </v-btn>
                        </v-col>
                    </v-row> 
                </v-form>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import ProductsService from '@/services/ecommerce/products.service'
import BmModal from '@/components/modal/BmModal.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
export default {
    components:{BmModal,BmLoader},
    name:'BmOrderProducts',
    data(){
        return{
            products:[],
            productoAgregar:{},
            validProducto:null,
            productoRules:[
                 v => !!v || 'El producto es requerido',
            ],
            cantidadRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v >= 1 && v <= (this.productoAgregar.producto.stock_quantity || 0) || 'Ingrese una cantidad valida',
            ],
            loader:{isLoad:false,text:''},
            productoId:null
        }
    },
    methods:{
        async getProducts() {
            try{
                this.loader = {isLoad:true,text:'Obteniedo productos, por favor espere...'}
                this.products = await ProductsService.GetProducts()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        handelFormProductos(){
            if(!this.validProducto){ return }
            let productoNuevo =   {
                "id": 0,
                "name": this.productoAgregar.producto.name,
                "product_id": this.productoAgregar.producto.id,
                "variation_id": 0,
                "quantity": this.productoAgregar.cantidad,
                "tax_class": "",
                "subtotal": (this.productoAgregar.producto.price * this.productoAgregar.cantidad).toString(),
                "subtotal_tax": "0.00",
                "total": (this.productoAgregar.producto.price * this.productoAgregar.cantidad).toString(),
                "total_tax": "0.00",
                "taxes": [],
                "meta_data": this.productoAgregar.producto.meta_data,
                "sku": "",
                "price": this.productoAgregar.producto.price,
                "image": {
                    "id": this.productoAgregar.producto.images[0].id,
                    "src": this.productoAgregar.producto.images[0].src
                },
                "parent_name": null
            }
            this.$emit('agregar-producto',productoNuevo)
            
        },
        handleProductoChange(){
            this.productoAgregar.cantidad = 0
            let producto = this.productsFilter.find((x) => x.id == this.productoId)
            this.productoAgregar.producto = {...producto}
        }
    },
    computed:{
        productsFilter() {
             let listaFiltrada = this.products.filter(productoInventario => {
                return !this.listaProductos.some(productoPedido => productoPedido.product_id === productoInventario.id);
            });

            return listaFiltrada
        }
    },
    props:{
        listaProductos: {
            type: Array,
            default : () => []
        },
        modalProductos:{
            type:Object,
            default: () => ({
                isShowModal:false
            })
        },
    },
    mounted(){
        this.getProducts()
    }
}
</script>
