import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import prospectoService from '@/services/prospecto.service'

/*eslint-disable*/

const state = () => ({
    prospectos: [],
    prospectosComentarios: []
})

const getters = {
    getProspectos : state => state.prospectos,
    getProspectosComentarios : state => state.prospectosComentarios,
}

const actions = {
    async createProspecto( { dispatch }, {prospecto,filtro}){

        try{
            let response = await prospectoService.CrearProspecto(prospecto)
            dispatch(mapHelper.mapfn.fetchProspectos,filtro);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateProspecto( { dispatch }, {prospecto,filtro}){

        try{
            let response = await prospectoService.EditarProspecto(prospecto)
            dispatch(mapHelper.mapfn.fetchProspectos,filtro);      
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async deleteProspecto( { dispatch }, {prospecto_id,filtro}){

        try{
            let response = await prospectoService.EliminarProspecto(prospecto_id)
            dispatch(mapHelper.mapfn.fetchProspectos,filtro);    
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchProspectos( { commit },data){

        try{
            let response = await prospectoService.ObtenerProspectos(data)
            commit(types.SET_PROSPECTOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async createProspectoComentarios( { dispatch }, {comentario,filtro}){

        try{
            let response = await prospectoService.CrearProspectoComentarios(comentario)
            dispatch(mapHelper.mapfn.fetchProspectosComentarios,comentario);         
            dispatch(mapHelper.mapfn.fetchProspectos,filtro);   
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateProspectoComentarios( { dispatch }, {comentario,filtro}){

        try{
            let response = await prospectoService.EditarProspectoComentarios(comentario)
            dispatch(mapHelper.mapfn.fetchProspectosComentarios,comentario);
            dispatch(mapHelper.mapfn.fetchProspectos,filtro);          
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    // async deleteProspectoComentarios( { dispatch }, data){

    //     try{
    //         let response = await prospectoService.(data)
    //         dispatch(mapHelper.mapfn.fetchClientes);       
    //         return response  
    //     }catch(error){
    //         throw new Error(error.message)
    //     }

    // },
    async fetchProspectosComentarios( { commit }, data){

        try{
            let response = await prospectoService.ObtenerProspectosComentarios(data)
            commit(types.SET_PROSPECTOS_COMENTARIOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_PROSPECTOS](state,prospectos){
        state.prospectos = prospectos
    },
    [types.SET_PROSPECTOS_COMENTARIOS](state,prospectosComentarios){
        state.prospectosComentarios = prospectosComentarios
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}