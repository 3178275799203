<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-breadcrumbs
            class="pl-1"
            :items="breadCrumbs"
            large
            divider="/"
        ></v-breadcrumbs>
        <v-card elevation="0" class="mb-4">
            <v-card-title>
                <h4>Pedidos Cotización</h4>
            </v-card-title>
            <v-card-text>
                <v-row class="text-right mb-2">
                    <v-col>
                        <v-btn
                            color="success"
                            type="button"
                            @click="$router.push({ name: 'BmPedidoNuevo' })"
                        >
                            <!-- @click="isProductoDetalle = true, modalProductos.titleModal = 'AGREGAR UN NUEVO PRODUCTO',modalProductos.isNuevo = true, producto = {}" -->
                            Nuevo pedido
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="pedidos"
                    :search="search"
                >
                    <template v-slot:top>
                        <v-text-field
                        v-model="search"
                        label="Buscador"
                        class="mx-4"
                        dense
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.tamano.desc_tamano }} - {{ item.tamano.medida }} </template>
                    <template v-slot:[`item.total_pedido`]="{ item }">{{ item.total_pedido | precio}}</template>
                    <template v-slot:[`item.total_pagado`]="{ item }">{{ item.total_pagado | precio}}</template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-menu left bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="$router.push({ name: 'BmPedidoDetalles', params:{id:item._pedido_id}})">
                                    <v-list-item-title><v-icon left> mdi-eye </v-icon>Ver</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermiso(5)" @click="pedidoPago = {isShowModal:true,pedido:item}">
                                    <v-list-item-title><v-icon left> mdi-cash-multiple </v-icon>Ver Pagos</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="item.estatuspedido_id == 1 " @click="$router.push({ name: 'BmPedidoEditar', params:{id:item._pedido_id}})">
                                    <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Editar</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if=" isPermiso(9) && (item.estatuspedido_id == 1 || item.estatuspedido_id == 2) " @click="pedidoEstatus = {isShowModal:true,pedido_id:item.pedido_id,estatuspedido_id:item.estatuspedido_id}">
                                    <v-list-item-title><v-icon left> mdi-sync </v-icon>Cambiar estatus</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu right bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-file-document</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item v-if="isPermiso(1)" @click="handleReporte(item.pedido_id)">
                                    <v-list-item-title><v-icon left> mdi-printer</v-icon>Generar reporte cotizacion</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip
                            class="ma-2"
                            :color="item.activo ? 'green_status' : 'red_status'"
                            text-color="white"
                        >
                            {{item.activo ? 'Activo' : 'Inactivo'}}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <bm-pedido-estatus
            v-if="pedidoEstatus.isShowModal"
            :pedido_id="pedidoEstatus.pedido_id"
            :estatuspedido_idActual="pedidoEstatus.estatuspedido_id"
            @cancelar="pedidoEstatus.isShowModal = false,fetchPedidos()"
        >
        </bm-pedido-estatus>
        <bm-pedido-pagos
            v-if="pedidoPago.isShowModal"
            :pedido="pedidoPago.pedido"
            @salir="pedidoPago.isShowModal = false, fetchPedidos()"
        >
        </bm-pedido-pagos>
        <bm-caja-pedido
            v-if="pedidoCaja.isShowModal"
            :pedido="pedidoCaja.pedido"
            @salir="pedidoCaja.isShowModal = false"
        >
        </bm-caja-pedido>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmPedidoEstatus from '@/views/pedidos/components/BmPedidoEstatus.vue'
import BmPedidoPagos from '@/views/pedidos/components/BmPedidoPagos.vue'
import BmCajaPedido from '@/views/cajas/BmCajaPedido.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    components:{BmLoader,BmPedidoEstatus,BmPedidoPagos,BmCajaPedido},
    mixins:[AlertMixin],
    name:'BmPedidos',
    data(){
        return{
            loader:{isLoad:false,text:''}, 
            breadCrumbs:[
                {
                    text: 'Pedidos Cotizacion',
                    disabled: true,
                    href: '/pedidos/cotizacion',
                },
            ],
            search: '',
            headers: [
                { text: 'Id', value: 'pedido_id' },
                { text: 'Cliente', value: 'cliente.razon_social' },
                { text: 'Prioridad', value: 'prioridad.desc_prioridad' },
                { text: 'Estatus', value: 'desc_estatuspedido' },
                { text: 'Total', value: 'total_pedido' },
                { text: 'Pagado', value: 'total_pagado' },
                { text: 'Acciones', value: 'acciones' , width: '10%'},
            ],
            filtro:{
                estatuspedido_id:1,
                cliente_id:0,
                prioridad_id:0,
                tipoenvio_id:0
            },
            pedidoEstatus:{
                isShowModal:false,
                pedido_id:0,
                estatuspedido_id:0
            },
            pedidoPago:{
                isShowModal:false,
                pedido:{},
            },
            pedidoCaja:{
                isShowModal:false,
                pedido:{},
            }
        }
    },
    methods:{
        async fetchPedidos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.fetchPedidos,this.filtro)
            }catch(error){
                    this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async handleReporte(pedido_id){

            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                let data = await this.$store.dispatch(mapHelper.mapmod.BYM_REPORTES+mapHelper.mapfn.generarReporte,{pedido_id:pedido_id,tipo_reporte:1})
                const href = URL.createObjectURL(data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                //link.setAttribute('download','_blank'); //or any other extension
                //document.body.appendChild(link);
                //link.click();

                // clean up "a" element & remove ObjectURL
                window.open(link, '_blank');
                //document.body.removeChild(link);
                //URL.revokeObjectURL(href);
            }catch(error){
                    this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
    },
    computed:{
        pedidos(){
            return this.$store.getters[mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidos]
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.fetchPedidos()
    }
}
</script>