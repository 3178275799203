import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import usuarioService from '@/services/usuario.service'

/*eslint-disable*/

const state = () => ({
    usuarios: []
})

const getters = {
    getUsuarios : state => state.usuarios
}

const actions = {
    async createUsuario( { dispatch }, data){

        try{
            let response = await usuarioService.CrearUsuario(data)
            dispatch(mapHelper.mapfn.fetchUsuarios);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updateUsuario( { dispatch }, data){

        try{
            let response = await usuarioService.EditarUsuario(data)
            dispatch(mapHelper.mapfn.fetchUsuarios);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async deleteUsuario( { dispatch }, data){

        try{
            let response = await usuarioService.EliminarUsuario(data)
            dispatch(mapHelper.mapfn.fetchUsuarios);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchUsuarios( { commit }){

        try{
            let response = await usuarioService.ObtenerUsuarios()
            commit(types.SET_USUARIOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async checkUser({}){

        try{
            await usuarioService.CheckUser()
        }catch(error){
            throw new Error(error.message)
        }        
    },
    // logOut({ commit }){
    //     Vue.prototype.$session.destroy();
    //     commit(types.SET_USUARIO,null)
    //     commit(types.SET_TOKEN,null)
    // },
}

const mutations = {

    [types.SET_USUARIOS](state,usuarios){
        state.usuarios = usuarios
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}