module.exports = {
    status: [
        // { id: 1, descripcion: "auto-draft", descripcion_traduccion: "Borrador",color:"red_status" },
        { id: 2, descripcion: "pending", descripcion_traduccion: "Pendiente",color:"grey_status" },
        { id: 3, descripcion: "processing", descripcion_traduccion: "Procesando",color:"green_status" },
        { id: 4, descripcion: "on-hold", descripcion_traduccion: "En Espera",color:"yellow_status" },
        { id: 5, descripcion: "completed", descripcion_traduccion: "Completado",color:"blue_status" },
        { id: 6, descripcion: "cancelled", descripcion_traduccion: "Cancelado",color:"grey_status" },
        { id: 7, descripcion: "refunded", descripcion_traduccion: "Reembolsado",color:"" },
        { id: 8, descripcion: "failed", descripcion_traduccion: "Fallido",color:"red_status" },
        { id: 9, descripcion: "checkout-draft", descripcion_traduccion: "Pendiente de Pago",color:"grey_status" }]
}