import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import precioproductoService from '@/services/precioproducto.service'

/*eslint-disable*/

const state = () => ({
    precios: []
})

const getters = {
    getPrecios : state => state.precios
}

const actions = {
    async createPrecio( { dispatch }, data){
        try{
            let response = await precioproductoService.CrearPrecio(data)
            dispatch(mapHelper.mapfn.fetchPrecios,data.producto_id);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchPrecios( { commit }, payload){

        try{
            let response = await precioproductoService.ObtenerPrecios(payload)
            commit(types.SET_PRECIOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_PRECIOS](state,precios){
        state.precios = precios
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}