import axios from 'axios';
import urlcontext from './axios.urlcontext';
// import httpstatus from '@/utils/httpstatus'
// import urlcontext from "./axios.urlcontext";
// import store from "@/store";
// import mapHelper from "@/store/modules/common/map-helper";
// import router from '@/router';

const EcommerceInstance = axios.create({
  baseURL: urlcontext.APP_ECOMMERCE_BASE, 
  auth: {
    username: urlcontext.APP_ECOMMERCE_CLIENT_KEY,
    password: urlcontext.APP_ECOMMERCE_CLIENT_SECRET_KEY
  },
});


/* Interceptores */

// Request timeout
EcommerceInstance.defaults.timeout = 12000

// EcommerceInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalConfig = error.config;
//     if (error.response) {
//       if (error.response.status === httpstatus.UNAUTHORIZED && !originalConfig._retry) {
//         originalConfig._retry = true;


//         // originalConfig.auth = {
//         //   username: urlcontext.APP_ECOMMERCE_CLIENT_KEY,
//         //   password: urlcontext.APP_ECOMMERCE_CLIENT_SECRET_KEY
//         // }

//         // originalConfig.headers = {}

//         //const base64Credentials = btoa(`${urlcontext.APP_ECOMMERCE_CLIENT_KEY}:${urlcontext.APP_ECOMMERCE_CLIENT_SECRET_KEY}`);
//         //onfigura Axios para incluir las credenciales en el encabezado de todas las solicitudes
//         //EcommerceInstance.defaults.headers.common['Authorization'] = `Basic ${base64Credentials}`;


//         return EcommerceInstance(originalConfig);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// Add request interceptor
// BYMInstance.interceptors.request.use(
//     config => {
//       // What to do before sending the request

//     // console.log(config);
//       return config
//     },
//     err => {
//       // What to do about request errors
//     //   Vue.prototype.$message.error('request timeout')
//        return Promise.reject(err);
//     }
//   )


// // Response interceptor
// BYMInstance.interceptors.response.use(
//     response => {
//       // res is the result of the response
//     //   console.log('response');
//     //   console.log(response);
//     //   switch (response.data.status) {
//     //     case 401:
//     //       // Login failure
//     //       // Response to successful interception
//     //       if(router.currentRoute.name == 'IndexHome') {
//     //         window.location.reload();
//     //         }else {
//     //             router.push({name: 'Home'});
//     //         }
//     //       break
//     //     case 404:

//     //       break
//     //     case 500:
//     //       // error

//     //       break
//     //     default:
//     //       return response
//     //   }
//       return response
//     },
//     err => {
//       if (err.response.data.message) {
//         console.log(err.response.data.message)
//         return Promise.reject(err.response.data.message) // Returns the error information returned by the interface
//       } else {
//         // Error handling when the return status code is not 200
//         console.log(err.toString())
//         return Promise.resolve(err)
//       }
//     }
//   )


/* */

export {
  EcommerceInstance
}