module.exports = {
    permisos: [
        {
            rol_id: 1, //ADMINISTRADOR
            modulos: [
                { modulo_id: 1, permiso: true }, //Dashboard
                { modulo_id: 2, permiso: true }, //Usuarios
                { modulo_id: 3, permiso: true }, //Clientes
                { modulo_id: 4, permiso: true }, //Catalogos
                { modulo_id: 5, permiso: true }, //Productos
                { modulo_id: 6, permiso: true }, //Cotizaciones
                { modulo_id: 7, permiso: true }, //Ordenes de producción
                { modulo_id: 8, permiso: true }, //Control
                { modulo_id: 9, permiso: true }, //Finalizados
                { modulo_id: 10, permiso: true }, //E-Commerce
                { modulo_id: 11, permiso: true }, //Prospectos
            ],
            acciones: [
                { accion_id: 1, permiso: true }, //GENERAR REPORTE COTIZACION
                { accion_id: 2, permiso: true }, //GENERAR REPORTE PRODUCCION
                { accion_id: 3, permiso: true }, //GENERAR REPORTE MAKILA 1
                { accion_id: 4, permiso: true }, //GENERAR REPORTE MAKILA 2
                { accion_id: 5, permiso: true }, //VER PAGOS
                { accion_id: 6, permiso: true }, //VER CAJAS
                { accion_id: 7, permiso: true }, //CAMBIAR CONTROL ESTATUS
                { accion_id: 8, permiso: true }, //VER PRECIOS
                { accion_id: 9, permiso: true }, //CAMBIAR ESTATUS PEDIDO 
                { accion_id: 10, permiso: false }, //SOLO CAMBIAR ESTATUS CANCELADO
                { accion_id: 11, permiso: true }, //CAMBIAR PRECIOS
                { accion_id: 12, permiso: true }, //AUMENTAR STOCK
                { accion_id: 13, permiso: true }, //DISMINUIR STOCK
                { accion_id: 14, permiso: true }, //CREAR, ELIMINAR, MODIFICAR CAJAS
                { accion_id: 15, permiso: true }, //ELIMINAR COMENTARIO PROSPECTO
                { accion_id: 16, permiso: true }, //ELIMINAR PROSPECTO
                { accion_id: 17, permiso: true }, //VER FILTRO USUARIO
            ]
        },
        {
            rol_id: 2, //VENDEDOR
            modulos: [
                { modulo_id: 1, permiso: true }, //Dashboard
                { modulo_id: 2, permiso: false }, //Usuarios
                { modulo_id: 3, permiso: true }, //Clientes
                { modulo_id: 4, permiso: false }, //Catalogos
                { modulo_id: 5, permiso: true }, //Productos
                { modulo_id: 6, permiso: true }, //Cotizaciones
                { modulo_id: 7, permiso: true }, //Ordenes de producción
                { modulo_id: 8, permiso: true }, //Control
                { modulo_id: 9, permiso: true }, //Finalizados
                { modulo_id: 10, permiso: false }, //E-Commerce
                { modulo_id: 11, permiso: true }, //Prospectos
            ],
            acciones: [
                { accion_id: 1, permiso: true }, //GENERAR REPORTE COTIZACION
                { accion_id: 2, permiso: false }, //GENERAR REPORTE PRODUCCION
                { accion_id: 3, permiso: false }, //GENERAR REPORTE MAKILA 1
                { accion_id: 4, permiso: false }, //GENERAR REPORTE MAKILA 2
                { accion_id: 5, permiso: true }, //VER PAGOS
                { accion_id: 6, permiso: true }, //VER CAJAS
                { accion_id: 7, permiso: false }, //CAMBIAR CONTROL ESTATUS
                { accion_id: 8, permiso: true }, //VER PRECIOS
                { accion_id: 9, permiso: true }, //CAMBIAR ESTATUS PEDIDO
                { accion_id: 10, permiso: true }, // SOLO CAMBIAR ESTATUS CANCELADO
                { accion_id: 11, permiso: false }, //CAMBIAR PRECIOS
                { accion_id: 12, permiso: false }, //AUMENTAR STOCK
                { accion_id: 13, permiso: false }, //DISMINUIR STOCK
                { accion_id: 14, permiso: false }, //CREAR, ELIMINAR, MODIFICAR CAJAS
                { accion_id: 15, permiso: false }, //ELIMINAR COMENTARIO PROSPECTO
                { accion_id: 16, permiso: false }, //ELIMINAR PROSPECTO
                { accion_id: 17, permiso: false }, //VER FILTRO USUARIO

            ]
        },
        {
            rol_id: 3, //ALMACEN
            modulos: [
                { modulo_id: 1, permiso: false }, //Dashboard
                { modulo_id: 2, permiso: false }, //Usuarios
                { modulo_id: 3, permiso: false }, //Clientes
                { modulo_id: 4, permiso: false }, //Catalogos
                { modulo_id: 5, permiso: true }, //Productos
                { modulo_id: 6, permiso: false }, //Cotizaciones
                { modulo_id: 7, permiso: false }, //Ordenes de producción
                { modulo_id: 8, permiso: true }, //Control
                { modulo_id: 9, permiso: false }, //Finalizados
                { modulo_id: 10, permiso: false }, //E-Commerce
                { modulo_id: 11, permiso: false }, //Prospectos
            ],
            acciones: [
                { accion_id: 1, permiso: false }, //GENERAR REPORTE COTIZACION
                { accion_id: 2, permiso: true }, //GENERAR REPORTE PRODUCCION
                { accion_id: 3, permiso: true }, //GENERAR REPORTE MAKILA 1
                { accion_id: 4, permiso: true }, //GENERAR REPORTE MAKILA 2
                { accion_id: 5, permiso: false }, //VER PAGOS
                { accion_id: 6, permiso: true }, //VER CAJAS
                { accion_id: 7, permiso: true }, //CAMBIAR CONTROL ESTATUS
                { accion_id: 8, permiso: false }, //VER PRECIOS
                { accion_id: 9, permiso: false }, //CAMBIAR ESTATUS PEDIDO
                { accion_id: 10, permiso: false }, // SOLO CAMBIAR ESTATUS CANCELADO
                { accion_id: 11, permiso: false }, //CAMBIAR PRECIOS
                { accion_id: 12, permiso: true }, //AUMENTAR STOCK
                { accion_id: 13, permiso: false }, //DISMINUIR STOCK
                { accion_id: 14, permiso: true }, //CREAR, ELIMINAR, MODIFICAR CAJAS
                { accion_id: 15, permiso: false }, //ELIMINAR COMENTARIO PROSPECTO
                { accion_id: 16, permiso: false }, //ELIMINAR PROSPECTO
                { accion_id: 17, permiso: false }, //VER FILTRO USUARIO

            ]
        },
    ]
}