<template>
  <div>
    <bm-loader v-if="loader.isLoad" :text="loader.text" />
    <v-card elevation="0">
      <v-card-text>
        <!-- <v-row class="text-right mb-2">
          <v-col>
              <v-btn
                color="success"
                type="button"
                @click="modalClientes.isShowModal = true, modalClientes.titleModal = 'AGREGAR UN NUEVO CLIENTE ',modalClientes.isNuevo = true, cliente = {}"
              >
                Agregar Cliente
              </v-btn>
          </v-col>
        </v-row> -->
        <v-data-table :headers="headers" :items="orders" :search="search">
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Buscador"
              class="mx-4"
              dense
            ></v-text-field>
          </template>
          <template v-slot:[`item.id`]="{ item }">#{{ item.id }}</template>
          <template v-slot:[`item.date_created`]="{ item }">{{ item.date_created | fechaDescripcion }}</template>
           <template v-slot:[`item.total`]="{ item }">{{ item.total | precio }}</template>
          <template v-slot:[`item.status`]="{ item }">
              <v-chip
                class="ma-2"
                :color="estatus.find((x)=> x.descripcion == item.status ).color"
                text-color="black"
                dark
              >
                {{estatus.find((x)=> x.descripcion == item.status ).descripcion_traduccion || 'S/E'}}
              </v-chip>
            </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="
                    orderDetail = {isShowModal:true,order:{...item}}
                  "
                >
                  <v-list-item-title
                    ><v-icon left> mdi-table-edit </v-icon
                    >Ver/Editar</v-list-item-title
                  >
                </v-list-item>
                <!-- <v-list-item @click="eliminarCliente(item)">
                  <v-list-item-title
                    ><v-icon left> mdi-table-row-remove </v-icon
                    >Eliminar</v-list-item-title
                  >
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <bm-order-detail
          v-if="orderDetail.isShowModal"
          :order="orderDetail.order"
          @salir="orderDetail.isShowModal = false"
          @get-orders="getOrders"
          @get-order="getOrder"
          @get-data-orders="getDataOrders"
          @add-product="addProduct"
      >
    </bm-order-detail>
  </div>
</template>
<script>
import AlertMixin from "@/mixins/AlertMixin.vue";
// import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from "@/components/loader/BmLoader.vue";
import OrdersServicefrom from "@/services/ecommerce/orders.service";
import BmOrderDetail from './components/BmOrderDetail.vue';
import EnumEcommerce from "@/utils/ecommerce/enums";
export default {
  name: "BmOrders",
  mixins: [AlertMixin],
  components: { BmLoader,BmOrderDetail},
  data: () => ({
    search: "",
    headers: [
      { text: "Pedido", value: "id" },
      { text: "Fecha", value: "date_created" },
      { text: "Estatus", value: "status" },
      { text: "Total", value: "total" },
      { text: "Acciones", value: "acciones" },
    ],
    load: false,
    loader: { isLoad: false, text: "" },
    activo: false,
    orders: [],
    orderDetail:{
        isShowModal:false,
        order:{},
    },
    estatus: EnumEcommerce.status,
  }),
  methods: {
    async getOrders() {
      try {
        this.loader = {
          isLoad: true,
          text: "Obteniedo pedidos, por favor espere...",
        };
        this.orders = await OrdersServicefrom.GetOrders();
      } catch (error) {
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
      }
    },
    async getOrder(id) {
      try {
        this.loader = {
          isLoad: true,
          text: "Obteniedo pedido #" + id + ", por favor espere...",
        };
        let order = await OrdersServicefrom.GetOrders({id:id});

        this.orderDetail = {isShowModal:false,order:{}}

        this.orderDetail = {isShowModal:true,order:order}

      } catch (error) {
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
      }
    },
    async getDataOrders(id){
        try {
        this.loader = {
          isLoad: true,
          text: "Obteniedo pedido #" + id + ", por favor espere...",
        };

        let order = await OrdersServicefrom.GetOrder(id);
        this.orderDetail = {isShowModal:true,order:order}

        this.orders = await OrdersServicefrom.GetOrders();

      } catch (error) {
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
      }
    },
    addProduct(product){
      this.orderDetail.order.line_items.push(product)
    }
  },
  async mounted() {
    await this.getOrders();
  },
};
</script>