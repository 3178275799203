<template>
   <v-app>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-container fluid fill-height id="bm-login">
         <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
               <v-form ref="formLogin" v-model="validLogin" @submit.prevent="handleIniciarSesion">
                  <v-container>
                     <span class="img_container">
                        <img :src="logo" alt="logo">
                     </span>
                     <h2>Iniciar sesión</h2>
                  </v-container>
                  <v-container>
                     <v-alert
                        outlined
                        type="error"
                        text
                        v-show="alert.isShow"
                     >
                     {{alert.message}}
                     </v-alert>
                     <v-text-field
                        label="Correo electrónico"
                        v-model.trim="usuario.correo_electronico"
                        :rules="usuarioRules"
                        outlined
                        dense
                     ></v-text-field>
                     <v-text-field
                        label="Contraseña"
                        v-model.trim="usuario.password"
                        :rules="passwordRules"
                        outlined
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="showPassword = !showPassword"
                        dense
                     ></v-text-field>
                     <v-btn
                        color="success"
                        type="submit"
                     >
                        Acceder
                     </v-btn>
                  </v-container>
               </v-form>
               <!-- <v-card class="elevation-12">
                  <v-toolbar dark class="login">
                     <v-toolbar-title>Login form</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                     <v-form>
                        <v-text-field    
                           name="login"
                           label="Login"
                           type="text"
                        ></v-text-field>
                        <v-text-field
                           id="password"
                           name="password"
                           label="Password"
                           type="password"
                        ></v-text-field>
                     </v-form>
                  </v-card-text>
                  <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn color="primary" to="/">Login</v-btn>
                  </v-card-actions>
               </v-card>-->
            </v-flex>
         </v-layout>
      </v-container>
      <!-- https://store.vuetifyjs.com/products/materio-vuetify-vuejs-admin-template/preview -->
   </v-app>
</template>

<script>
   import mapHelper from '@/store/modules/common/map-helper'
   import BmLoader from '@/components/loader/BmLoader.vue'
   export default {
      name: 'BmLogin',
      components:{BmLoader},
      data: () => ({
         logo: require('../../assets/logo-bym.svg'),
         usuario:{correo_electronico:'',password:''},
         validLogin: false,
         usuarioRules : [                   
            v => !!v || 'El correo electronico es requerido',
            v => /.+@.+/.test(v) || 'Correo electronico no valido',
         ],
         passwordRules: [
            v => !!v || 'La contraseña es requerida',
         ],
         alert:{
            isShow:false,
            message:''
         },
         showPassword:false,
         loader:{isLoad:false,text:''}
      }),
      props: {
         source: String,
      },
      methods:{
         async handleIniciarSesion() {
         
            try {
               if(!this.$refs.formLogin.validate()){
                  return
               }

               this.loader = {isLoad:true,text:'Validando las credenciales, por favor espere...'}
               this.alert = {isShow:false,message:''}
               
               await this.$store.dispatch(mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.logIn,this.usuario)
               this.$router.push({ name: 'BmDashboard' })
            } catch (error) {
               this.alert = {isShow:true,message: error.message}
            }finally{
               this.loader = {isLoad:false,text:''}
            }

         }
      }
   };
</script>

<style scoped>
   img {
      width: 216px;
      height: 92px;
      text-align: center;
   }
   .v-application .success {
      background-color: #2C6632 !important;
      border-color: #2C6632 !important;
      width: 100%;
      height: 55px;
      text-transform: none;
   }  
   .v-application .primary--text{
      color:  #2C6632 !important;
      caret-color: #2C6632;
   }
   .v-text-field--outlined {
      border-color:  #2C6632 !important;
   }
   h2 {
      text-align: center;
      margin-top: 16px;
   }
   .img_container {
      display: block;
      width: 216px;
      margin: 0 auto;
   }
   .v-application .transparent {  
     border-color: red !important;
   }  
</style>
