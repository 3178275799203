<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="modalProductos.isShowModal" :title="modalProductos.titleModal">
            <template v-slot:modal-content>              
                <v-form ref="formProducto" v-model="validProducto" @submit.prevent="handleFormProducto">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                :items="categorias"
                                item-text="desc_categoria"
                                item-value="categoria_id"
                                v-model="productoDetalle.categoria_id"
                                :rules="categoriasRules"
                                label="Categoria*"
                                outlined
                                hide-details="auto"
                                @change="getMateriales"
                                dense
                            >
                                
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-autocomplete
                                :items="materiales"
                                item-text="desc_material"
                                item-value="material_id"
                                v-model="productoDetalle.material_id"
                                :rules="meterialesRules"
                                label="Material*"
                                outlined
                                hide-details="auto"
                                @change="getTipos"
                                dense
                            >
                                
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-autocomplete
                                :items="tipos"
                                item-text="desc_tipo"
                                item-value="tipo_id"
                                v-model="productoDetalle.tipo_id"
                                :rules="tiposRules"
                                label="Tipo*"
                                outlined
                                hide-details="auto"
                                @change="getTamanos"
                                dense
                            >             
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12"  md="6">
                            <v-autocomplete
                                :items="tamanos"
                                :item-text=" item => item.desc_tamano +' - '+ item.medida"
                                item-value="tamano_id"
                                v-model="productoDetalle.tamano_id"
                                :rules="tamanosRules"
                                label="Tamaño*"
                                outlined
                                hide-details="auto"
                                @change="getModelos"
                                dense
                            >
                                
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" >
                            <v-autocomplete
                                :items="modelos"
                                item-text="desc_modelo"
                                item-value="modelo_id"
                                v-model="productoDetalle.modelo_id"
                                :rules="modelosRules"
                                label="Modelo*"
                                outlined
                                hide-details="auto"
                                dense
                               
                            >
                                
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Stock"
                                v-model.trim="productoDetalle.stock"
                                :rules="stockRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Peso"
                                v-model.trim="productoDetalle.peso"
                                :rules="pesoRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Porcentaje"
                                v-model.number="productoDetalle.porcentaje"
                                :rules="porcentajeRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>         
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                label="Observaciones"
                                v-model.trim="productoDetalle.observaciones"
                                :rules="observacionesRules"
                                outlined
                                hide-details="auto"
                                @input="productoDetalle.observaciones = productoDetalle.observaciones.toUpperCase()"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row v-if="!modalProductos.isNuevo">
                        <v-col>
                            <v-switch
                                v-model="productoDetalle.activo"
                                label="Activo"
                            ></v-switch>
                        </v-col>
                    </v-row>
                    <v-row class="text-right">
                        <v-col>
                            <v-btn
                                color="error"
                                type="button"
                                @click="$emit('cancelar')"
                                class="mr-2"
                            >
                            Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Guardar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
import BmModal from '@/components/modal/BmModal.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue' 
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'BmProductoDetalles',
    components:{BmModal,BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            productoDetalle:{},
            validProducto:null,

            categoriasRules:[
                 v => !!v || 'La categoria es requerida',
            ],
            meterialesRules:[
                 v => !!v || 'El material es requerido',
            ],
            tamanosRules:[
                 v => !!v || 'El tamaño es requerido',
            ],
            modelosRules:[
                 v => !!v || 'El modelo es requerido',
            ],
            tiposRules:[
                 v => !!v || 'El tipo es requerido',
            ],
            porcentajeRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v >= 0 && v <= 100 || 'Ingrese un numero mayor o igual a 0',
            ],
            stockRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999 || 'Ingrese un numero mayor o igual a 0',
                v => (this.isPermiso(13) ? true : v >= this.stock_data ) || 'No tiene permiso para disminuir stock',
                v => (this.isPermiso(12) ? true :  v <= this.stock_data) || 'No tiene permiso para aumentar stock',
            ],
            pesoRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999 || 'Ingrese un numero mayor a o igual 0',
            ],
            observacionesRules:[],
            categorias:[],
            materiales:[],
            modelos:[],
            tamanos:[],      
            tipos:[],
            stock_data:this.producto.stock
        }
    },
    methods:{
        async handleFormProducto(){
            if(!this.$refs.formProducto.validate()){
                return
            }

            if(this.modalProductos.isNuevo){
                this.$emit('nuevo-producto',this.productoDetalle)
            }else{
                this.$emit('editar-producto',this.productoDetalle)
            }
        },
        async getCategorias(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:3,
                    idCatalogo:1
                }
                this.categorias = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)

            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.$emit('abrir')
            }
        },
        async getMateriales(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:3,
                    idCatalogo:2,
                    id:this.productoDetalle.categoria_id
                }

                this.productoDetalle.material_id = 0
                this.productoDetalle.tipo_id = 0
                this.productoDetalle.tamano_id = 0
                this.productoDetalle.modelo_id = 0

                this.$refs.formProducto.resetValidation()

                this.tipos = []
                this.tamanos = []
                this.modelos = []

                this.materiales = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getTipos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:3,
                    idCatalogo:5,
                    id:this.productoDetalle.categoria_id
                }
                
                this.productoDetalle.tipo_id = 0
                this.productoDetalle.tamano_id = 0
                this.productoDetalle.modelo_id = 0

                this.$refs.formProducto.resetValidation()

                this.tamanos = []
                this.modelos = []

                this.tipos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getTamanos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:3,
                    idCatalogo:4,
                    id:this.productoDetalle.categoria_id
                }

                this.productoDetalle.tamano_id = 0
                this.productoDetalle.modelo_id = 0

                this.$refs.formProducto.resetValidation()

                this.modelos = []

                this.tamanos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getModelos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:3,
                    idCatalogo:3,
                    id2:this.productoDetalle.material_id
                }

                this.productoDetalle.modelo_id = 0

                this.$refs.formProducto.resetValidation()


                this.modelos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getCatalogosData(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                
                let data1 = {
                    tipo:3,
                    idCatalogo:1
                }
                this.categorias = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data1)

                let data2 = {
                    tipo:3,
                    idCatalogo:2,
                    id:this.productoDetalle.categoria_id
                }
                this.materiales = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data2)

                let data3 = {
                    tipo:3,
                    idCatalogo:3,
                    id:this.productoDetalle.categoria_id
                }
                this.modelos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data3)

                let data4 = {
                    tipo:3,
                    idCatalogo:4,
                    id:this.productoDetalle.categoria_id
                }
                this.tamanos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data4)

                let data5 = {
                    tipo:3,
                    idCatalogo:5,
                    id:this.productoDetalle.categoria_id
                }
                this.tipos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data5)            
                
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.$emit('abrir')
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
    },
    // watch:{
    //     producto(){
    //         this.productoDetalle = {...this.producto}
    //         if(!this.modalProductos.isNuevo){
    //             this.productoDetalle.categoria_id = this.productoDetalle.categoria.categoria_id
    //             this.productoDetalle.material_id = this.productoDetalle.material.material_id
    //             this.productoDetalle.modelo_id = this.productoDetalle.modelo.modelo_id
    //             this.productoDetalle.tamano_id = this.productoDetalle.tamano.tamano_id
    //             this.productoDetalle.tipo_id = this.productoDetalle.tipo.tipo_id
                
    //         }
    //     },
    //     modalProductos:{
    //         deep:true,
    //         handler(){
    //             if(this.modalProductos.isNuevo){
    //                 this.getCategorias()
    //             }else{
    //                 this.getCatalogosData()
    //             }
    //         }
    //     }
    // },
    props:{
        modalProductos:{
            type:Object,
            default: () => ({
                isShowModal:false,
                isNuevo:false,
                titleModal:'',
            })
        },
        producto:{
            type:Object,
            default: () => {}
        },
    },
    computed:{
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.productoDetalle = {...this.producto}
        if(!this.modalProductos.isNuevo){
            this.productoDetalle.categoria_id = this.productoDetalle.categoria.categoria_id
            this.productoDetalle.material_id = this.productoDetalle.material.material_id
            this.productoDetalle.modelo_id = this.productoDetalle.modelo.modelo_id
            this.productoDetalle.tamano_id = this.productoDetalle.tamano.tamano_id
            this.productoDetalle.tipo_id = this.productoDetalle.tipo.tipo_id
            
        }

        if(this.modalProductos.isNuevo){
            this.getCategorias()
        }else{
            this.getCatalogosData()
        }
    }
}
</script>