<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-card elevation="0">
      <v-card-text>
        <!-- <v-row class="text-right mb-2">
          <v-col>
              <v-btn
                color="success"
                type="button"
                @click="modalClientes.isShowModal = true, modalClientes.titleModal = 'AGREGAR UN NUEVO CLIENTE ',modalClientes.isNuevo = true, cliente = {}"
              >
                Agregar Cliente
              </v-btn>
          </v-col>
        </v-row> -->
        <v-data-table
            :headers="headers"
            :items="customers"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
        </v-data-table>
      </v-card-text>
      </v-card>
    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
// import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue'
import CustomersService from '@/services/ecommerce/customers.service'
export default {
    name:'BmCustomers',
    mixins:[AlertMixin],
    components:{BmLoader},
    data: () => ({
        search: '',
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Nombre de usuario', value: 'username' },
          { text: 'País / Región', value: 'country' },
          { text: 'Región', value: 'state' },
          { text: 'Ciudad', value: 'city' },
          { text: 'Código postal', value: 'postcode' },
        ],
        load:false,
        loader:{isLoad:false,text:''},
        activo: false,
        customers:[]
    }),
    methods:{
        async getCustomers() {
            try{
                this.loader = {isLoad:true,text:'Obteniedo productos, por favor espere...'}
                this.customers = await CustomersService.GetCustomers()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
      
    },
    async mounted(){
      await this.getCustomers()
    }
}
</script>