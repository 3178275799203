<template>
    <bm-modal v-if="modalCatalogos.isShowModal" :title="modalCatalogos.titleModal">
        <template v-slot:modal-content>
        <v-form ref="formCatalogo" v-model="validCatalogo" @submit.prevent="handleFormCatalogo">
            <div v-if="catalogo_id == 1">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion categoria*"
                            v-model="catalogoDetalle.desc_categoria"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_categoria = catalogoDetalle.desc_categoria.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 2">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion material*"
                            v-model.trim="catalogoDetalle.desc_material"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_material = catalogoDetalle.desc_material.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="categorias"
                            item-text="desc_categoria"
                            item-value="categoria_id"
                            v-model="catalogoDetalle.categoria_id"
                            :rules="categoriaRules"
                            label="Categoria*"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-autocomplete> 
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 3">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion modelo*"
                            v-model.trim="catalogoDetalle.desc_modelo"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_modelo = catalogoDetalle.desc_modelo.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="categorias"
                            item-text="desc_categoria"
                            item-value="categoria_id"
                            v-model="catalogoDetalle.categoria_id"
                            :rules="categoriaRules"
                            label="Categoria*"
                            outlined
                            hide-details="auto"
                            @change="$emit('getMateriales',catalogoDetalle.categoria_id)"
                            dense
                        ></v-autocomplete> 
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="materiales"
                            item-text="desc_material"
                            item-value="material_id"
                            v-model="catalogoDetalle.material_id"
                            :rules="materialRules"
                            label="Material*"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-autocomplete> 
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 4">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion tamaño*"
                            v-model.trim="catalogoDetalle.desc_tamano"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_tamano = catalogoDetalle.desc_tamano.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Medida*"
                            v-model.trim="catalogoDetalle.medida"
                            :rules="medidaRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.medida = catalogoDetalle.medida.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="categorias"
                            item-text="desc_categoria"
                            item-value="categoria_id"
                            v-model="catalogoDetalle.categoria_id"
                            :rules="categoriaRules"
                            label="Categoria*"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-autocomplete> 
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 5">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion tipo*"
                            v-model.trim="catalogoDetalle.desc_tipo"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_tipo = catalogoDetalle.desc_tipo.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :items="categorias"
                            item-text="desc_categoria"
                            item-value="categoria_id"
                            v-model="catalogoDetalle.categoria_id"
                            :rules="categoriaRules"
                            label="Categoria*"
                            outlined
                            hide-details="auto"
                            dense
                        ></v-autocomplete> 
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 6">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion color*"
                            v-model.trim="catalogoDetalle.desc_color"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_color = catalogoDetalle.desc_color.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <div v-if="catalogo_id == 12">
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Descripcion envio*"
                            v-model.trim="catalogoDetalle.desc_tipoenvio"
                            :rules="descripcionRules"
                            outlined
                            hide-details="auto"
                            @input="catalogoDetalle.desc_tipoenvio = catalogoDetalle.desc_tipoenvio.toUpperCase()"
                            dense
                        ></v-text-field>
                    </v-col>          
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Importe envio"
                            v-model.number="catalogoDetalle.precio_caja"
                            :rules="precioRules"
                            outlined
                            type="Number"
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Importe kilo"
                            v-model.number="catalogoDetalle.precio_kilo"
                            :rules="precioRules"
                            outlined
                            type="Number"
                            hide-details="auto"
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </div>
            <v-row v-if="!modalCatalogos.isNuevo">
                <v-col>
                    <v-switch
                        v-model="catalogoDetalle.activo"
                        label="Activo"
                    ></v-switch>
                </v-col>
            </v-row>
            <!-- <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Razon social*"
                        v-model.trim="clienteDetalle.razon_social"
                        :rules="razonSocialRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="RFC"
                        v-model.trim="clienteDetalle.rfc"
                        :rules="rfcRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                        label="Telefono*"
                        v-model.trim="clienteDetalle.telefono"
                        :rules="telefonoRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Correo electronico*"
                        v-model.trim="clienteDetalle.correo_electronico"
                        :rules="correoElectronicoRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        :items="tipoClientes"
                        item-text="desc_tipocliente"
                        item-value="tipocliente_id"
                        v-model="clienteDetalle.tipocliente_id"
                        :rules="tipoClienteRules"
                        label="Tipo de cliente*"
                        outlined
                        hide-details="auto"
                    >
                    </v-autocomplete> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="estados"
                        item-text="desc_estado"
                        item-value="estado_id"
                        v-model="clienteDetalle.estado_id"
                        :rules="estadoRules"
                        label="Estado*"
                        outlined
                        hide-details="auto"
                        @change="$emit('obtener-municipios',clienteDetalle.estado_id)"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="municipios"
                        item-text="desc_municipio"
                        item-value="municipio_id"
                        v-model="clienteDetalle.municipio_id"
                        :rules="municipioRules"
                        label="Municipio*"
                        outlined
                        hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Colonia*"
                        v-model.trim="clienteDetalle.colonia"
                        :rules="coloniaRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Calle"
                        v-model.trim="clienteDetalle.calle"
                        :rules="calleRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Numero exterior"
                        v-model.trim="clienteDetalle.num_ext"
                        :rules="numExtRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Numero interior"
                        v-model.trim="clienteDetalle.num_int"
                        :rules="numIntRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        label="Codigo postal"
                        v-model.trim="clienteDetalle.codigo_postal"
                        :rules="codigoPostalRules"
                        outlined
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        label="Referencia"
                        v-model.trim="clienteDetalle.referencia"
                        :rules="referenciaRules"
                        outlined
                        hide-details="auto"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea
                        label="Observaciones"
                        v-model.trim="clienteDetalle.observaciones"
                        :rules="observacionesRules"
                        outlined
                        hide-details="auto"
                    ></v-textarea>
                </v-col>
            </v-row>
            <v-row class="text-right">
                <v-col>
                    <v-btn
                        color="error"
                        type="button"
                        @click="$emit('cancelar')"
                        class="mr-2"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        type="submit"
                    >
                    Guardar
                    </v-btn>
                </v-col>
            </v-row> -->
                <v-row class="text-right">
                    <v-col>
                        <v-btn
                            color="error"
                            type="button"
                            @click="$emit('cancelar')"
                            class="mr-2"
                        >
                        Cancelar
                        </v-btn>
                        <v-btn
                            color="success"
                            type="submit"
                        >
                        Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </bm-modal>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
export default {
    name:'BmCatalogoDetalles',
    components:{BmModal},
    data(){
        return{
            validCatalogo:null,
            catalogoDetalle:null,
            descripcionRules:[
                v => !!v || 'La descripcion es requerida.',
            ],
            categoriaRules:[
                v => !!v || 'La categoria es requerida.',
            ],
            materialRules:[
                v => !!v || 'El material es requerido.',
            ],
            medidaRules:[
                v => !!v || 'La medida es requerida.',
            ],
            precioRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseInt(v)) && v >= 0 && v <=  99999999999|| 'Ingrese un numero mayor o igual a 0',
            ],
        }
    },
    methods:{
        handleFormCatalogo(){
            if(!this.$refs.formCatalogo.validate()){
                return
            }

            if(this.modalCatalogos.isNuevo){
                this.$emit('nuevo-catalogo',this.catalogoDetalle)
            }else{
                this.$emit('editar-catalogo',this.catalogoDetalle)
            }
        }
    },
    watch:{
        catalogo(){

        }
    },
    async mounted(){
        this.catalogoDetalle = {...this.catalogo}
        
        switch(this.catalogo_id ){
            case 1 : 
                this.$emit('abrir'); break;
            case 2 : 
                await this.$emit('getCategorias')
                if(!this.modalCatalogos.isNuevo){
                    this.catalogoDetalle.categoria_id = this.catalogoDetalle.categoria.categoria_id
                }
                break;
            case 3 : 
                await this.$emit('getCategorias') 
                if(!this.modalCatalogos.isNuevo){
                    await this.$emit('getMateriales',this.catalogoDetalle.material.categoria_id)
                    this.catalogoDetalle.categoria_id = parseInt(this.catalogoDetalle.material.categoria_id)
                    this.catalogoDetalle.material_id = this.catalogoDetalle.material.material_id
                }
                break;
            case 4 : 
                await this.$emit('getCategorias')
                if(!this.modalCatalogos.isNuevo){
                    this.catalogoDetalle.categoria_id = this.catalogoDetalle.categoria.categoria_id
                }
                break;
            case 5 : 
                await this.$emit('getCategorias')
                if(!this.modalCatalogos.isNuevo){
                    this.catalogoDetalle.categoria_id = this.catalogoDetalle.categoria.categoria_id
                }
                break;
            case 6 : 
                await this.$emit('mostrar-modal')
                break;
            case 12 : 
                await this.$emit('mostrar-modal')
                break;
            default: break;

        }

    },
    props:{
        modalCatalogos:{
            type:Object,
            default: () => ({
                isShowModal:false,
                isNuevo:false,
                titleModal:''
            })
        },
        catalogo:{
            type:Object,
            default: () => {}
        },
        catalogo_id:{
            type:Number,
            default:0
        },
        categorias:{
            type:Array,
            default: () => []
        },
        materiales:{
            type:Array,
            default: () => []
        }
    }
}
</script>