import numeral from 'numeral'

const precioFilter = function(value){
    if(!value){
        return '$0.00'
    }
    return numeral(value).format('($0,0.00)')
}

const pesoFilter = function(value){
    if(!value){
        return '0.00kg'
    }
    let numer = numeral(value).format('(0,0.00)')
    return numer+'kg'
}

const fechaDescripcion = function(value){
    
    const fechaHora = new Date(value); // Asegúrate de que la fecha se interprete como UTC

    const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'America/Mexico_City' };
    const fechaHoraFormateada = fechaHora.toLocaleDateString('es-ES', opciones);

    return fechaHoraFormateada;
}

const fechaDescripcionV2 = function(value){
    
    const fechaHora = new Date(`${value}T00:00:00Z`); // Asegúrate de que la fecha se interprete como UTC
    fechaHora.setUTCHours(0); // Establece las horas en 0 para evitar posibles ajustes de zona horaria

    const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'America/Mexico_City' };
    const fechaHoraFormateada = fechaHora.toLocaleDateString('es-ES', opciones);

    return fechaHoraFormateada;
}

const fechaConvert = function(value){

    if(!value) { return 'No aplica'}
    // Crear un objeto de fecha a partir de la cadena ISO
    const fechaHora = new Date(`${value}T00:00:00-06:00`); // Ciudad de México está en UTC-6
    //fechaHora.setUTCHours(0); // Establece las horas en 0 para evitar posibles ajustes de zona horaria

    // Obtener el nombre del mes en español
    const opciones = { year: 'numeric', month: 'long', day: 'numeric' , timeZone: 'America/Mexico_City'};
    const fechaHoraFormateada = fechaHora.toLocaleDateString('es-ES', opciones);

    return fechaHoraFormateada
}

export {precioFilter,pesoFilter,fechaDescripcion,fechaDescripcionV2,fechaConvert}