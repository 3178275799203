import BmDashboard from '@/views/dashboard/BmDashboard.vue'
import BmUsuarios from '@/views/usuarios/BmUsuarios.vue'
import BmClientes from '@/views/clientes/BmClientes.vue'
import BmProductos from '@/views/productos/BmProductos.vue'
import BmCatalogos from '@/views/catalogos/BmCatalogos.vue'
import BmPedidosCotizacion from '@/views/pedidos/BmPedidosCotizacion.vue'
import BmPedidos from '@/views/pedidos/BmPedidos.vue'
import BmPedidosProduccion from '@/views/pedidos/BmPedidosProduccion.vue'
import BmPedidoNuevo from '@/views/pedidos/BmPedidoNuevo.vue'
import BmPedidoDetalles from '@/views/pedidos/BmPedidoDetalles.vue'
import BmPedidoEditar from '@/views/pedidos/BmPedidoEditar.vue'
import BmControlPedido from '@/views/control/BmControlPedido.vue'
import BmEcommerce from '@/views/ecommerce/BmEcommerce.vue'
import BmProspecto from '@/views/prospectos/BmProspectos.vue'

export default [
    {
        path: '/',
        name: 'BmDashboard',
        component: BmDashboard,
        meta:{modulo_id:1}
    },
    {
        path: '/usuarios',
        name: 'BmUsuarios',
        component: BmUsuarios,
        meta:{modulo_id:2}
    },
    {
        path: '/clientes',
        name: 'BmClientes',
        component: BmClientes,
        meta:{modulo_id:3}
    },
    {
        path: '/productos',
        name: 'BmProductos',
        component: BmProductos,
        meta:{modulo_id:5}
    },
    {
        path: '/catalogos',
        name: 'BmCatalogos',
        component: BmCatalogos,
        meta:{modulo_id:4}
    },
    {
        path: '/pedidos/cotizacion',
        name: 'BmPedidosCotizacion',
        component: BmPedidosCotizacion,
        meta:{modulo_id:6}
    },
    {
        path: '/pedidos/nuevo',
        name: 'BmPedidoNuevo',
        component: BmPedidoNuevo,
        meta:{modulo_id:6}
    },
    {
        path: '/pedidos/detalles/:id',
        name: 'BmPedidoDetalles',
        component: BmPedidoDetalles,
        meta:{modulo_id:6}
    },
    {
        path: '/pedidos/editar/:id',
        name: 'BmPedidoEditar',
        component: BmPedidoEditar,
        meta:{modulo_id:6}
    },
    {
        path: '/control',
        name: 'BmControlPedido',
        component: BmControlPedido,
        meta:{modulo_id:8}
    },
    {
        path: '/pedidos/produccion',
        name: 'BmPedidosProduccion',
        component: BmPedidosProduccion,
        meta:{modulo_id:7}
    },
    {
        path: '/pedidos/finalizados',
        name: 'BmPedidos',
        component: BmPedidos,
        meta:{modulo_id:9}
    },
    {
        path: '/ecommerce',
        name: 'BmEcommerce',
        component: BmEcommerce,
        meta:{modulo_id:10}
    },
    {
        path: '/prospectos',
        name: 'BmProspectos',
        component: BmProspecto,
        meta:{modulo_id:11}
    },
]