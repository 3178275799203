<script>
import Swal from 'sweetalert2'
export default {
    methods:{
        alertSuccess(title,message){
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: title,
                text: message,
                showConfirmButton: true,
                //timer: 1500
            })
        },
        alertError(title,message){
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: title,
                text: message,
                confirmButtonColor: '#2C6632',
                showConfirmButton: true,
                // timer: 1500
            })
        },
        alertQuestion(title = '¿Desea eliminar el registro?',text = '',confirmText = "Si, eliminar!", cancelText = "No, cancelar!"){
            return Swal.fire({
                title: title,
                text: text,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#2C6632',
                cancelButtonColor: '#FF3C3C',
                confirmButtonText: confirmText,
                cancelButtonText: cancelText,

            }).then((result) => {
                return result.isConfirmed
            })
        }
    }
}
</script>