import types from '@/store/modules/common/mutation-types-core'
// import mapHelper from '@/store/modules/common/map-helper'
import catalogoService from '@/services/catalogo.service'

/*eslint-disable*/

const state = () => ({
    catalogos:[]
})

const getters = {
    getCatalogos : state => state.catalogos
}

const actions = {

    async getCatalogo({},data){

        const {idCatalogo,tipo,id,id2,id3,id4} = data
        // {
        //     1:categorias,
        //     2:materiales,
        //     3:modelos,
        //     4:tamanos,
        //     5:tipos,
        //     6:colores,
        //     7:tipoclientes,
        //     8:estados,
        //     9:municipios,
        //     10:roles
        // }
        let ruta = ''
        let params = {}
        switch(idCatalogo){
            case 1: ruta = 'categorias'; params = {tipo:tipo}; break;
            case 2: ruta = 'materiales'; params = {tipo:tipo,categoria_id:id}; break;
            case 3: ruta = 'modelos'; params = {tipo:tipo,categoria_id:id,material_id:id2,tipo_id:id3,tamano_id:id4}; break;
            case 4: ruta = 'tamanos'; params = {tipo:tipo,categoria_id:id,material_id:id2,tipo_id:id3}; break;
            case 5: ruta = 'tipos'; params = {tipo:tipo,categoria_id:id,material_id:id2}; break;
            case 6: ruta = 'colores'; params = {tipo:tipo}; break;
            case 7: ruta = 'tipoclientes'; break;
            case 8: ruta = 'estados'; break;
            case 9: ruta = 'municipios'; params = {estado_id:id}; break;
            case 10: ruta = 'roles'; break;
            case 11: ruta = 'prioridades';params = {tipo:tipo}; break;
            case 12: ruta = 'tiposenvio';params = {tipo:tipo}; break;
            case 13: ruta = 'estatuspedidos';params = {tipo:tipo}; break;
            case 14: ruta = 'estatuscontrol';params = {tipo:tipo}; break;
            case 15: ruta = 'metodopagos';params = {tipo:tipo}; break;
            case 16: ruta = 'canal';params = {tipo:tipo}; break;

            default: ruta = ''; break;
        }

        try{
            let response = await catalogoService.ObtenerCatalogo(ruta,params)
            return response.data
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async createCatalogo({},data){

        const {idCatalogo,catalogo} = data
        // {
        //     1:categorias,
        //     2:materiales,
        //     3:modelos,
        //     4:tamanos,
        //     5:tipos,
        //     6:colores,
        //     7:tipoclientes,
        //     8:estados,
        //     9:municipios,
        //     10:roles
        // }
        let ruta = ''
        switch(idCatalogo){
            case 1: ruta = 'categorias'; break;
            case 2: ruta = 'materiales'; break;
            case 3: ruta = 'modelos'; break;
            case 4: ruta = 'tamanos'; break;
            case 5: ruta = 'tipos'; break;
            case 6: ruta = 'colores'; break;
            case 7: ruta = 'tipoclientes'; break;
            case 8: ruta = 'estados'; break;
            case 9: ruta = 'municipios'; break;
            case 10: ruta = 'roles'; break;
            default: ruta = ''; break;

        }

        try{
            let response = await catalogoService.CrearCatalogo(ruta,catalogo)
            return response
        }catch(error){
            throw new Error(error.message)
        }   
    },
    async updateCatalogo({},data){

        const {idCatalogo,catalogo} = data
        // {
        //     1:categorias,
        //     2:materiales,
        //     3:modelos,
        //     4:tamanos,
        //     5:tipos,
        //     6:colores,
        //     7:tipoclientes,
        //     8:estados,
        //     9:municipios,
        //     10:roles
        // }
        let ruta = ''
        switch(idCatalogo){
            case 1: ruta = 'categorias'; break;
            case 2: ruta = 'materiales'; break;
            case 3: ruta = 'modelos'; break;
            case 4: ruta = 'tamanos'; break;
            case 5: ruta = 'tipos'; break;
            case 6: ruta = 'colores'; break;
            case 7: ruta = 'tipoclientes'; break;
            case 8: ruta = 'estados'; break;
            case 9: ruta = 'municipios'; break;
            case 10: ruta = 'roles'; break;
            case 12: ruta = 'tiposenvio'; break;
            default: ruta = ''; break;

        }

        try{
            let response = await catalogoService.EditarCatalogo(ruta,catalogo)
            return response
        }catch(error){
            throw new Error(error.message)
        }   
    },
    async fetchCatalogos({commit}){

        try{
            let response = await catalogoService.ObtenerCatalogos()
            commit(types.SET_CATALOGOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },

}

const mutations = {

    [types.SET_CATALOGOS](state,catalogos){
        state.catalogos = catalogos
    },

}

export default {
    namespaced: true,
    actions,
    state,
    actions,
    mutations,
    getters
}