import ApiEcommerce from '../config/api/ApiEcommerce'
import httpErrorMessage from '../../utils/httpErrorMessage'
import httpstatus from '../../utils/httpstatus'

export default {

    async GetProducts(payload) {
        try {
            let response = await ApiEcommerce.get('/wc/v3/products', payload)
            if (response.status != httpstatus.SUCCESS && response.data) {
                throw new Error( response.data.message || httpErrorMessage.DEFAULT_ERROR)
            } else if (response.status == 0) {
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
}