<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="showModal" title="Nuevo estatus">          
            <template v-slot:modal-content>
                <v-form ref="formPedidoEstatus" v-model="validPedidoEstatus" @submit.prevent="handleFormPedidoEstatus" class="mb-4">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                :items="estatuspedidos_filtro"
                                item-text="desc_estatuspedido"
                                item-value="estatuspedido_id"
                                v-model="estatuspedido_id"
                                :rules="estatusPedidoRules"
                                label="Estatus"
                                outlined
                                hide-details="auto"
                                dense
                            >
                            </v-autocomplete> 
                        </v-col>
                    </v-row>
                    <v-row class="text-right">
                        <v-col>
                            <v-btn
                                color="error"
                                type="button"
                                @click="$emit('cancelar')"
                                class="mr-2"
                            >
                            Cancelar
                            </v-btn>
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Crear
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue' 
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'BmPedidoEstatus',
    components:{BmModal,BmLoader},
    mixins:[AlertMixin],
    props:{
        pedido_id:{
            type:Number,
            default: () => {}
        },
        estatuspedido_idActual:{
            type:Number,
            default:0
        }
    },
    data(){
        return{
            estatusPedido:[],
            validPedidoEstatus:null,
            estatusPedidoRules:[
                v => !!v || 'El estatus es requerido',
            ],
            estatuspedido_id:0,
            showModal:false,
            loader:{isLoad:false,text:''},
        }
    },
    methods:{
        async handleFormPedidoEstatus(){
            if(!this.$refs.formPedidoEstatus.validate()){
                return
            }
            this.showModal = false
            
            if(!await this.alertQuestion('¿Desea crear el nuevo estatus?',null,"Si, crear!","No, cancelar!")){
                return
            }
            this.nuevoEstatus()
        },
         async nuevoEstatus(){
            this.loader = {isLoad:true,text:'Creando estatus, por favor espere...'}

            try{
                let payload = {
                    pedido_id:this.pedido_id,
                    estatuspedido_id:this.estatuspedido_id
                }
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.createPedidoEstatus,payload)
                this.alertSuccess(null,response.message)
                this.$emit('cancelar')
            }catch(error){
                this.alertError(null,error.message)
                this.showModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getEstatusPedido(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:13
                }
                this.estatusPedido = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.showModal = true
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
    },
    computed:{
        estatuspedidos_filtro(){
            if(this.isPermiso(10)){
                let id = this.estatuspedido_idActual
                return this.estatusPedido.filter(function(item){return item.estatuspedido_id !=id && item.estatuspedido_id == 4 })
            }else{
                let id = this.estatuspedido_idActual
                return this.estatusPedido.filter(function(item){return item.estatuspedido_id !=id})
            }
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.getEstatusPedido()
    }

}
</script>