/* eslint-disable */ 
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueSession from 'vue-session'
import "@/assets/styles/style.scss"
import {precioFilter,pesoFilter, fechaDescripcion, fechaConvert, fechaDescripcionV2} from '@/utils/filters'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

let optionsSession = {
  persist: true
}
Vue.use(VueSession, optionsSession)
Vue.filter('precio',precioFilter)
Vue.filter('peso',pesoFilter)
Vue.filter('fechaDescripcion',fechaDescripcion)
Vue.filter('fechaDescripcionV2',fechaDescripcionV2)
Vue.filter('fechaConvert',fechaConvert)

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
