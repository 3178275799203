import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import pedidoPagoService from '@/services/pedidopago.service'
/*eslint-disable*/

const state = () => ({
    pedidopagos: []
})

const getters = {
    getPedidoPagos : state => state.pedidopagos
}

const actions = {
    async createPedidoPago( {dispatch} , payload){

        try{
            let response = await pedidoPagoService.CrearPedidoPago(payload)
            dispatch(mapHelper.mapfn.fetchPedidoPagos,payload.pedido_id)
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async updatePedidoPago( { dispatch}, payload){

        try{
            let response = await pedidoPagoService.EditarPedidoPago(payload) 
            dispatch(mapHelper.mapfn.fetchPedidoPagos,payload.pedido_id);       
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchPedidoPagos( { commit }, payload){

        try{
            let response = await pedidoPagoService.ObtenerPedidoPagos(payload)
            commit(types.SET_PEDIDO_PAGOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async descargarArchivo( { }, payload){

        try{
            let response = await pedidoPagoService.DescargarArchivo(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async cleanPedidoPagos( { commit }){

        try{
            commit(types.SET_PRODUCTOS,[])
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_PEDIDO_PAGOS](state,pedidopagos){
        state.pedidopagos = pedidopagos
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}