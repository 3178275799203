<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-breadcrumbs
            class="pl-1"
            :items="breadCrumbs"
            large
            divider="/"
        ></v-breadcrumbs>
        <div v-if="pedido.pedido_id">
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Cliente</h4>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Razón social"
                                :value="pedido.cliente.razon_social"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="RFC"
                                :value="pedido.cliente.rfc"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Dirección"
                                :value="pedido.cliente.calle+' '+pedido.cliente.colonia+' '+pedido.cliente.num_ext"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Productos Pedido ({{totalProductosPedido}})</h4>
                </v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="headersProductos"
                        :items="pedido.pedidodetalles"
                    >
                        <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.desc_tamano }} - {{ item.medida }} </template>
                        <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                        <template v-slot:[`item.importe`]="{ item }">{{ item.precio * item.cantidad | precio}}</template>
                        <template v-slot:[`item.peso_total`]="{ item }">{{ item.peso_total | peso}}</template>
                    </v-data-table>
                </v-card-text>
            </v-card>
            <v-card elevation="0" class="mb-4">
                <v-card-title>
                    <h4>Detalles</h4>
                </v-card-title>
                <v-card-text>
                    
                    <v-row>
                        <v-col>
                            <!-- <v-switch
                                :value="pedido.factura"
                                readonly
                            >
                                <template v-slot:label>Factura</template>
                            </v-switch> -->
                                                    
                            <v-text-field
                                label="Estatus"
                                :value="pedido.desc_estatuspedido"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Prioridad"
                                :value="pedido.prioridad.desc_prioridad"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Total cajas"
                                :value="getNumeroCajas()"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Peso total"
                                :value="pesoTotal | peso"
                                outlined
                                hide-details="auto"
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>   
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Total productos"
                                :value="totalProductos | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>           
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Tipo Envio"
                                :value="pedido.tipoenvio.desc_tipoenvio"
                                hide-details="auto"
                                outlined
                                readonly
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Importe envio"
                                :value="pedido.envio | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Cargo MM"
                                :value="pedido.cargo_extra | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>   
                        <v-col cols="12" md="3">
                            <v-text-field
                                label="Descuento"
                                :value="pedido.descuento | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>       
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Fecha entrega"
                                :value="pedido.fecha_entrega"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                label="Fecha pedido"
                                :value="pedido.fecha_pedido"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Subtotal"
                                :value="subTotal | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="IVA"
                                :value="pedido.iva | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Importe total"
                                :value="importeTotal | precio"
                                outlined
                                readonly
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea
                                label="Observaciones"
                                :value="pedido.observaciones"
                                outlined
                                hide-details="auto"
                                readonly
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
export default {
    name:'BmPedidoDetalles',
    components:{BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            breadCrumbs:[
                // {
                //     text: 'Pedidos',
                //     disabled: false,
                //     href: '/pedidos',
                // },
                {
                    text: 'Detalles',
                    disabled: true,
                    href: '',
                },
            ],
            prioridades:[],
            tiposenvio:[],
            colores:[],
            pedido:{
                cliente_id:'',
                prioridad_id:'',
                tipoenvio_id:'',
                observaciones:'',
                envio:0,
                cargo_extra:0,
                peso:0,
                iva:0,
                factura:false,
                pedidodetalles:[],
            },
            cliente:{},
            headersFiltro: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'categoria.desc_categoria' },
                { text: 'Material', value: 'material.desc_material' },
                { text: 'Modelo', value: 'modelo.desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'tipo.desc_tipo' },
                { text: 'Stock', value: 'stock' , width: '5%'},
                { text: 'Peso', value: 'peso', width: '5%' },
                { text: 'Precio', value: 'precio', width: '10%' },
            ],
            headersProductos: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Modelo', value: 'desc_categoria' },
                { text: 'Material', value: 'desc_material' },
                { text: 'Modelo', value: 'desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'desc_tipo' },
                { text: 'Color', value: 'desc_color'},
                { text: 'Peso', value: 'peso_total', width: '5%' },
                { text: 'Cantidad', value: 'cantidad', width: '5%' },
                { text: 'Precio', value: 'precio', width: '10%' },
                { text: 'Importe', value: 'importe', width: '10%' },
            ],
            // productoSeleccionado:{},
            // showModalProductoDetalle:false,
            // validFormPedido:null,
            // valor_iva:0.16,
            caja:25,
            // isModificableImporteEnvio:false,
            // precioRules:[
            //     v => /^(?:\d*\d+)$/.test(v) || 'Ingrese un numero valido',
            //     v => !isNaN(parseInt(v)) && v >= 0 && v <=  99999999999|| 'Ingrese un numero mayor o igual a 0',
            // ],
            // prioridadRules:[
            //     v => !!v || 'La prioridad es requerida',
            // ], 
            // tipoEnvioRules:[
            //     v => !!v || 'El tipo de envio es requerido',
            // ],
            totalProductosPedido:0,
            pesoTotal:0,
            importeTotal:0,
            subTotal:0,
            pedido_id:0,
            prevRoute: null
        }
    },
    methods:{

        async getConfiguration(){
            try{
                this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
                await this.getPedido()
                await this.getPedidoDetalles()
                // await this.getTiposEnvio()
                // await this.getColores()
                // await this.getPedidoDetalles()
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        getCantidadProducto(producto_id){
            return (this.pedido.pedidodetalles?this.pedido.pedidodetalles.filter(x => x.producto_id == producto_id).reduce((n,{cantidad}) => n+cantidad,0):0)
        },
        getCantidadStockProducto(producto_id){
            return (this.pedido.pedidodetalles?this.pedido.pedidodetalles.filter(x => x.producto_id == producto_id).reduce((n,{cantidad_stock}) => n+cantidad_stock,0):0)
        },
        calcularEnvio(){
            let resultado = 0
            const {precio_kilo,precio_caja} = this.tiposenvio.find(x => x.tipoenvio_id == 3)

            let total_cajas = this.getNumeroCajas() * precio_caja
            let total_kilos = Math.ceil(this.pesoTotal) * precio_kilo

            resultado = total_cajas + total_kilos

            return resultado
        },
        getNumeroCajas(){
            return Math.ceil(this.pesoTotal/this.caja)
        },
        calcularIVA(){
            let iva = 0 
            if(this.pedido.factura){
                iva = this.subTotal * this.valor_iva
            }
            this.pedido.iva = iva
        },
        async getPedido(){
            try{
                let data = {
                    pedido_id:this.pedido_id
                }
                this.pedido = ((await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedido,data)).data)[0]
                console.log(this.pedido)
            }catch(error){
                throw new Error(error.message)          
            }
        },
        async getPedidoDetalles(){
            try{
                let data = {
                    pedido_id:this.pedido_id
                }
                this.pedido.pedidodetalles = (await this.$store.dispatch(mapHelper.mapmod.BYM_PEDIDOS+mapHelper.mapfn.getPedidoDetalles,data)).data
                this.totalProductosPedido = (this.pedido.pedidodetalles?this.pedido.pedidodetalles.reduce((n,{cantidad}) => n+cantidad,0):0)
                this.pesoTotal = (this.pedido.pedidodetalles?this.pedido.pedidodetalles.reduce((n,{peso_total}) => n+peso_total,0):0)
                this.totalProductos = (this.pedido.pedidodetalles?this.pedido.pedidodetalles.reduce((n,{cantidad,precio}) => n+cantidad*precio,0):0)
                this.subTotal = (this.pedido.pedidodetalles?this.pedido.pedidodetalles.reduce((n,{cantidad,precio}) => n+cantidad*precio,0):0) + (this.pedido.envio ? this.pedido.envio : 0) + (this.pedido.cargo_extra ? this.pedido.cargo_extra : 0 )  - (this.pedido.descuento ? this.pedido.descuento : 0 )
                this.importeTotal = this.subTotal + this.pedido.iva
            }catch(error){
                throw new Error(error.message)          
            }
        }
    },
    // watch:{
    //     pedido:{
    //         /* eslint-disable */ 
    //         handler:function() {
    //             if(this.pedido.tipoenvio_id == 3){
    //                 this.pedido.envio = this.calcularEnvio()
    //             }
    //             this.calcularIVA()


    //         },
    //         deep:true
            
    //     }
    // },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    created(){
        this.pedido_id = this.$route.params.id;
        this.getConfiguration()
    }
}
</script>