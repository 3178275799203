export default {   
    /*Ruta para acceder al module coin */
    BYM_AUTH: 'bym/auth/',
    BYM_USUARIOS: 'bym/usuarios/',
    BYM_CATALOGOS: 'bym/catalogos/',
    BYM_CLIENTES: 'bym/clientes/',
    BYM_PRODUCTOS: 'bym/productos/',
    BYM_PRECIO_PRODUCTOS: 'bym/precioproductos/',
    BYM_PEDIDOS: 'bym/pedidos/',
    BYM_PEDIDO_PAGOS: 'bym/pedidopagos/',
    BYM_CONTROL_PEDIDOS: 'bym/controlpedidos/',
    BYM_CAJA_PEDIDOS: 'bym/cajapedidos/',
    BYM_REPORTES: 'bym/reportes/',
    BYM_DASHBOARD: 'bym/dashboard/',
    BYM_PROSPECTOS: 'bym/prospectos/',
}