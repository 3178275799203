import reporteService from '@/services/reporte.service'

/*eslint-disable*/

const actions = {
    async generarReporte( {} , payload){
        try{           
            let response = await reporteService.GenerarReporte(payload)  
            return response  
        }catch(error){
            throw new Error(error.message)
        }
    }
}

export default {
    namespaced: true,
    actions,
}