import store from "@/store";
import mapHelper from "@/store/modules/common/map-helper";
import urlcontext from "./axios.urlcontext";

export default {

    getToken() {
        // const token = auth.state.token;
        // let headers = { headers: { "token": `${token}`} };
        // return headers;
    },
    getCoreHeaders() {
        // const token = auth.state.token;
        // let headers = { headers: { "public_key": `${Config.PUBLIC_KEY}`, "token": `${token}`} };
        let token = null
        if (store) {
            token = store.getters[mapHelper.mapmod.BYM_AUTH + mapHelper.mapfn.getToken]
        }

        let headers = {
            "Accept": "application/json",
            "Authorization": "Bearer " + token
        }
        return headers;
    },
    getCoreHeadersEcommerce() {

        const base64Credentials = btoa(`${urlcontext.APP_ECOMMERCE_CLIENT_KEY}:${urlcontext.APP_ECOMMERCE_CLIENT_SECRET_KEY}`);
        let headers = {
            "Accept": "application/json",
            "Accept-Language":"es-419,es;q=0.9,es-ES;q=0.8,en;q=0.7,en-GB;q=0.6,en-US;q=0.5",
            "Authorization": `Basic ${base64Credentials}`
        }
        return headers;
    },




    getCoreHeadersDocument() {
        // const token = auth.state.token;
        // let headers = { headers: { "public_key": `${Config.PUBLIC_KEY}`, "token": `${token}`} };
        let token = null
        if (store) {
            token = store.getters[mapHelper.mapmod.BYM_AUTH + mapHelper.mapfn.getToken]
        }

        let headers = {
            "Accept": "application/json",
            "Authorization": "Bearer " + token,
            'Content-Type': 'multipart/form-data',
            "Access-Control-Allow-Headers": "X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method",
        }
        return headers;
    }
}

