<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="isShowModal" :title="'Control Estatus Pedido ' + pedido.pedido_id">          
            <template v-slot:modal-content>
                <v-row>
                    <v-col v-for="item,index in estatusControl" :key="index" cols="6" md="4">
                        <v-row>
                            <v-btn
                                v-if="isPermiso(7)"
                                :disabled="getDisabled(item)"
                                large
                                color="primary"
                                class="ma-2 white--text"
                                @click="handleNuevoEstatus(item.estatuscontrol_id)"
                                width="90%"
                            >
                                {{item.desc_estatuscontrol}}
                                <v-icon
                                    top
                                    dark
                                >
                                    {{controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == item.estatuscontrol_id)?'mdi-checkbox-outline':'mdi-checkbox-blank-outline'}}
                                </v-icon>
                            </v-btn>
                            <v-btn
                                v-else
                                :disabled="true"
                                large
                                color="primary"
                                class="ma-2 white--text"
                                width="90%"
                            >
                                {{item.desc_estatuscontrol}}
                                <v-icon
                                    top
                                    dark
                                >
                                    {{controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == item.estatuscontrol_id)?'mdi-checkbox-outline':'mdi-checkbox-blank-outline'}}
                                </v-icon>
                            </v-btn>

                        </v-row>
                        <v-row class="d-flex justify-center">
                            <strong>
                                {{ controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == item.estatuscontrol_id) ? (controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == item.estatuscontrol_id)).fecha_control : 'Sin Datos'}}
                            </strong>
                        </v-row>
                        <!-- <v-row>
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="fecha_control"
                                    label="Fecha control"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined
                                    :rules="fechaRules"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="fecha_control"
                                    @input="menu = false"              
                                ></v-date-picker>
                            </v-menu>
                        </v-row> -->
                        <v-row class="d-flex justify-center">
                            <strong>
                                Dias:{{getDiasDiferencias(item.orden)}} 
                            </strong>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="text-right">          
                    <v-col>
                        <v-btn
                            color="error"
                            type="button"
                            @click="$emit('salir')"
                            class="mr-2"
                        >
                        Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </bm-modal>
    </div>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
import mapHelper from '@/store/modules/common/map-helper'
import AlertMixin from '@/mixins/AlertMixin.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'BmControlEstatus',
    components:{BmModal,BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            isShowModal:false,
            estatusControl:[],
            menu: false,
            fechaRules:[
                v => !!v || 'La fecha es requerida',
            ],
            fecha_control:null
        }
    },
    props:{
        pedido:{
            type:Object,
            default: () => {}
        },
    },
    methods:{
        async handleNuevoEstatus(estatuscontrol_id){

            if(this.controlPedidos && this.controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == estatuscontrol_id)){
                return
            }

            this.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el estatus?',null,"Si, crear!","No, cancelar!")){
                this.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando pago, por favor espere...'}
            try{
                let fecha = new Date()             
                let payload = {
                    pedido_id: this.pedido.pedido_id,
                    fecha_control:fecha.toISOString().split('T')[0],
                    estatuscontrol_id:estatuscontrol_id
                }
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CONTROL_PEDIDOS+mapHelper.mapfn.createControlPedido,payload)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }

        },
        async buscarControlPedidos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_CONTROL_PEDIDOS+mapHelper.mapfn.fetchControlPedidos,{pedido_id:this.pedido.pedido_id})
                await this.getEstatusControl()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },
        async getEstatusControl(){
            try{
                let data = {
                    tipo:2,
                    idCatalogo:14
                }
                this.estatusControl = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)          
            }
        },
        getDiasDiferencias(orden){
            if(orden == 1){

                let fecha_pedido = new Date(this.pedido.fecha_pedido);
                let estatus = this.controlPedidos.find(x=>x.estatuscontrol.orden == orden)
                if(estatus){
                    let fecha_estatus = new Date(estatus.fecha_control);
                    let Difference_In_Time = fecha_estatus.getTime() - fecha_pedido.getTime();
                    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    return Difference_In_Days
                }else{
                    return 0
                }
            }else if(orden >= 2){
                let estatus_anterior = this.controlPedidos.find(x=>x.estatuscontrol.orden == orden-1)
                let estatus = this.controlPedidos.find(x=>x.estatuscontrol.orden == orden)
                if(estatus && estatus_anterior){
                    let fecha_pedido = new Date(estatus_anterior.fecha_control);
                    let fecha_estatus = new Date(estatus.fecha_control);
                    let Difference_In_Time = fecha_estatus.getTime() - fecha_pedido.getTime();
                    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
                    return Difference_In_Days
                }else{
                    return 0
                }
            }else{
                return 0
            }
        },
        getDisabled(item){

            if(item.orden == 1){
                return false
            }

            if(!this.controlPedidos){
                return true
            }

            try{
                if(this.controlPedidos.find(x=> x.estatuscontrol.estatuscontrol_id == item.estatuscontrol_id)){
                    return false
                }else if(( parseInt((this.controlPedidos.find(x => x.ultimo == true)).estatuscontrol.orden) +1) == item.orden){
                    return false
                }else{
                    return true
                }
            }catch{
                return true
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
           
        //estatuscontrol 
    },
    computed:{
        controlPedidos(){
            return this.$store.getters[mapHelper.mapmod.BYM_CONTROL_PEDIDOS+mapHelper.mapfn.getControlPedidos]
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.buscarControlPedidos()
    }
}
</script>