import ApiBYM from './config/api/ApiBYM'
import httpErrorMessage from '../utils/httpErrorMessage'
import httpstatus from '../utils/httpstatus'

export default {

    async GenerarReporte(payload) {
        try {
            
            let response = await ApiBYM.postDocumentDownload('/v1/reportes',payload)

            if(response.status != httpstatus.SUCCESS && response.data){
                var errors = null
                if(response.data.data){
                    errors = Object.keys(response.data.data).map(key => response.data.data[key]).join("");
                }
                throw new Error( (response.data.message) + "\n" + (errors ? errors : httpErrorMessage.DEFAULT_ERROR))
            }else if(response.status == 0){
                throw new Error(httpErrorMessage.CONECTION_ERROR)
            }

            return response.data

        } catch (error) {
            throw new Error(error.message)
        }
    },
   
}