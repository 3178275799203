export default {

    //*AUTH */
    SET_USUARIO : 'SET_USUARIO',
    SET_TOKEN : 'SET_TOKEN',

    /*USUARIOS*/
    SET_USUARIOS : 'SET_USUARIOS',

    /*CLIENTES */
    SET_CLIENTES : 'SET_CLIENTES',

    /*CLIENTES */
    SET_CATALOGOS : 'SET_CATALOGOS',

    /*PRODUCTOS */
    SET_PRODUCTOS : 'SET_PRODUCTOS',

    /*PRECIOS */
    SET_PRECIOS : 'SET_PRECIOS',

    /*PEDIDOS */
    SET_PEDIDOS : 'SET_PEDIDOS',

    /*PEDIDOS PAGOS*/
    SET_PEDIDO_PAGOS : 'SET_PEDIDO_PAGOS',

    /*CONTROL PEDIDOS*/
    SET_CONTROL_PEDIDOS : 'SET_CONTROL_PEDIDOS',

    /*CONTROL PEDIDOS*/
    SET_CAJA_PEDIDOS : 'SET_CAJA_PEDIDOS',

    /*PROSPECTOS*/
    SET_PROSPECTOS : 'SET_PROSPECTOS',
    SET_PROSPECTOS_COMENTARIOS : 'SET_PROSPECTOS_COMENTARIOS'


}