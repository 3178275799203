<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <bm-modal v-if="isShowModal" :title="'Precios Producto ' + producto.producto_id">          
            <template v-slot:modal-content>
                <v-form ref="formPrecio" v-model="validPrecio" @submit.prevent="handleFormPrecio" class="mb-4">
                    <v-row v-if="isPermiso(11)">
                        <v-col cols="12" md="4">
                            <v-autocomplete
                                :items="tipoClientes"
                                item-text="desc_tipocliente"
                                item-value="tipocliente_id"
                                v-model="precio.tipocliente_id"
                                :rules="tipoClienteRules"
                                label="Tipo de cliente*"
                                outlined
                                hide-details="auto"
                                dense
                            >
                            </v-autocomplete> 
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-text-field
                                label="Precio"
                                v-model.number="precio.precio"
                                :rules="precioRules"
                                type="Number"
                                outlined
                                hide-details="auto"
                                dense
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-btn
                                color="success"
                                type="submit"
                            >
                            Crear
                            </v-btn>
                        </v-col>         
                    </v-row>
                </v-form>
                <v-data-table
                    :headers="headers"
                    :items="precios"
                    :search="search"
                >
                <template v-slot:[`item.precio`]="{ item }">{{ item.precio | precio}}</template>
                </v-data-table>
                <v-row class="text-right">          
                    <v-col>
                        <v-btn
                            color="error"
                            type="button"
                            @click="$emit('salir')"
                            class="mr-2"
                        >
                        Cerrar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </bm-modal>

    </div>
</template>
<script>
import BmModal from '@/components/modal/BmModal.vue'
import BmLoader from '@/components/loader/BmLoader.vue'
import mapHelper from '@/store/modules/common/map-helper'
import AlertMixin from '@/mixins/AlertMixin.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'BmProductoPrecio',
    components:{BmModal,BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            search:null,
            validPrecio:null,
            headers: [
                {},
                { text: 'Id', value: 'precioproducto_id' },
                { text: 'Tipo Cliente', value: 'tipocliente.desc_tipocliente' },
                { text: 'Precio', value: 'precio' },
            ],
            tipoClientes:[],
            precio:{},
            precioRules:[
                v => /^(?:\d*\.\d{1,2}|\d+)$/.test(v) || 'Ingrese un numero valido',
                v => !isNaN(parseFloat(v)) && v >= 0 && v <= 9999999999 || 'Ingrese un numero mayor a o igual 0',
            ],
            tipoClienteRules:[
                v => !!v || 'El tipo de cliente es requerido',
            ],
            isShowModal:false
        }
    },
    props:{
        producto:{
            type:Object,
            default: () => {}
        },
    },
    methods:{
        async handleFormPrecio(){
            if(!this.$refs.formPrecio.validate()){
                return
            }

            this.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el precio?',null,"Si, crear!","No, cancelar!")){
                this.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando precio, por favor espere...'}
            try{
                this.precio.producto_id = this.producto.producto_id
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PRECIO_PRODUCTOS+mapHelper.mapfn.createPrecio,this.precio)
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }



        },
        async getTipoCliente(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {idCatalogo:7,id:null}
                this.tipoClientes = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async buscarPrecios(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.$store.dispatch(mapHelper.mapmod.BYM_PRECIO_PRODUCTOS+mapHelper.mapfn.fetchPrecios,this.producto.producto_id)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
                this.isShowModal = true
            }
        },         
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }   
    },
    computed:{
        precios(){
            return this.$store.getters[mapHelper.mapmod.BYM_PRECIO_PRODUCTOS+mapHelper.mapfn.getPrecios]
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.getTipoCliente()
        this.buscarPrecios()
    }
}
</script>