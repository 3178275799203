<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-card elevation="0" class="mb-4">
            <v-card-title>
                <h4>Filtro Producto</h4>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            :items="categorias"
                            item-text="desc_categoria"
                            item-value="categoria_id"
                            v-model="producto.categoria_id"
                            :rules="categoriasRules"
                            :label="'Categoria '+'('+categorias.length+')'"
                            outlined
                            hide-details="auto"
                            @change="getMateriales"
                            dense
                        >
                            
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            :items="materiales"
                            item-text="desc_material"
                            item-value="material_id"
                            v-model="producto.material_id"
                            :rules="meterialesRules"
                            :label="'Material '+'('+materiales.length+')'"
                            outlined
                            hide-details="auto"
                            @change="getTipos"
                            dense
                        >
                            
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            :items="tipos"
                            item-text="desc_tipo"
                            item-value="tipo_id"
                            v-model="producto.tipo_id"
                            :rules="tiposRules"
                            :label="'Tipo '+'('+tipos.length+')'"
                            outlined
                            hide-details="auto"
                             @change="getTamanos"
                             dense
                        >
                            
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            :items="tamanos"
                            :item-text=" item => item.desc_tamano +' - '+ item.medida"
                            item-value="tamano_id"
                            v-model="producto.tamano_id"
                            :rules="tamanosRules"
                            :label="'Tamaño '+'('+tamanos.length+')'"
                            outlined
                            hide-details="auto"
                            @change="getModelos"
                            dense
                        >
                            
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                     <v-col cols="12">
                        <v-autocomplete
                            :items="modelos"
                            item-text="desc_modelo"
                            item-value="modelo_id"
                            v-model="producto.modelo_id"
                            :rules="modelosRules"
                            :label="'Modelo '+'('+modelos.length+')'"
                            outlined
                            hide-details="auto"
                           dense
                        >
                            
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="text-right ">
                    <v-col>
                        <v-btn
                            color="success"
                            type="button"
                            outlined
                            @click="limpiarFiltro"
                        >
                            Limpiar filtro
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <slot name="productos"></slot>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import mapHelper from '@/store/modules/common/map-helper'
import AlertMixin from '@/mixins/AlertMixin.vue'
export default {
    name:'FiltroProducto',
    components:{BmLoader},
    mixins:[AlertMixin],
    data(){
        return{
            loader:{isLoad:false,text:''},
            categoriasRules:[],
            meterialesRules:[],
            tamanosRules:[],
            modelosRules:[],
            tiposRules:[],

            categorias:[],
            materiales:[],
            modelos:[],
            tamanos:[],      
            tipos:[],
            
            producto:{categoria_id:0,material_id:0,modelo_id:0,tamano_id:0,tipo_id:0},
        }
    },
    props:{
        tipo:{
            type:Number,
            default:0
        },
        tipo_filtro:{
            type:Number,
            default:1
        },
        tipo_cliente:{
            type:Number,
            default:0
        }
    },
    methods:{
        async getCategorias(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:1
                }
                this.categorias = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)

            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getMateriales(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:2,
                    id:this.producto.categoria_id
                }

                this.producto.material_id = 0
                this.producto.tipo_id = 0
                this.producto.tamano_id = 0
                this.producto.modelo_id = 0
                
                this.tipos = []
                this.tamanos = []
                this.modelos = []
               

                this.materiales = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getTipos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:5,
                    id:this.producto.categoria_id,
                    id2:this.producto.material_id,
                }

                this.producto.tipo_id = 0
                this.producto.tamano_id = 0
                this.producto.modelo_id = 0
                
                this.tamanos = []
                this.modelos = []

                this.tipos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getTamanos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:4,
                    id:this.producto.categoria_id,
                    id2:this.producto.material_id,
                    id3:this.producto.tipo_id,
                }

                this.producto.tamano_id = 0
                this.producto.modelo_id = 0
                
                this.modelos = []

                this.tamanos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async fetchProductos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                this.producto.tipo_filtro = this.tipo_filtro
                this.producto.tipo_cliente = this.tipo_cliente
                await this.$store.dispatch(mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.fetchProductos,this.producto)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async getModelos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {
                    tipo:2,
                    idCatalogo:3,
                    id:this.producto.categoria_id,
                    id2:this.producto.material_id,
                    id3:this.producto.tipo_id,
                    id4:this.producto.tamano_id
                }
                
                this.producto.modelo_id = 0
                
                this.modelos = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        limpiarFiltro(){
            this.producto.categoria_id = 0
            this.producto.material_id = 0
            this.producto.modelo_id = 0
            this.producto.tamano_id = 0
            this.producto.tipo_id = 0
            this.materiales = []
            this.modelos = []
            this.tamanos = []
            this.tipos = []
            this.cleanProductos()
        },
        cleanProductos(){
            this.$store.dispatch(mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.cleanProductos)
        }
    },
    
    mounted(){
        this.getCategorias()
    },
    beforeDestroy(){
        this.cleanProductos()
    },
    watch: {
        producto: {
            // This will let Vue know to look inside the array
            deep: true,

            // We have to move our method to a handler field
            handler(){
                this.fetchProductos()
            }
               
        }
    }
  
}
</script>