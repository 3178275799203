<template>
  <div>
    <bm-loader v-if="loader.isLoad" :text="loader.text" />
    <bm-modal v-if="isShowModal" :title="'Detalles de Pedido #' + order.id">
      <template v-slot:modal-content>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <h4>General</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Fecha creación"
                  :value="order.date_created | fechaDescripcion"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :items="estatus"
                  item-text="descripcion_traduccion"
                  item-value="descripcion"
                  v-model="orderEstatus"
                  :label="'Estatus'"
                  outlined
                  hide-details="auto"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Cliente"
                  :value="
                    order.billing.first_name + ' ' + order.billing.last_name
                  "
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Pago a través de "
                  :value="order.payment_method_title || 'Sin fecha de pago'"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              
              <v-col cols="12">
                <v-text-field
                  label="Fecha de pago"
                  :value="order.date_paid || 'No pagado'"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Nota del cliente"
                  :value="order.customer_note"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!-- <v-col>
            <v-row>
              <v-col>
                <h4>Notas</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-virtual-scroll :height="300" :itemHeight="100" :items="notes">
                <template v-slot:default="{ item }">
                  <v-alert border="right" color="blue-grey" dense dark>
                    {{ item.date_created | fechaDescripcion }} {{ item.note }}
                  </v-alert>
                </template>
              </v-virtual-scroll>
            </v-row>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <h4>Facturación</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre Completo"
                  :value="
                    order.billing.first_name + ' ' + order.billing.last_name
                  "
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Empresa"
                  :value="order.billing.company"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Dirección, línea 1"
                  :value="order.billing.address_1"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Dirección, línea 2"
                  :value="order.billing.address_2"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Ciudad"
                  :value="order.billing.city"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Código postal"
                  :value="order.billing.postcode"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="País"
                  :value="order.billing.country"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Región"
                  :value="order.billing.state"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Dirección de correo electrónico"
                  :value="order.billing.email"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Teléfono"
                  :value="order.billing.phone"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Método de pago"
                  :value="order.payment_method_title"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <h4>Envío</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre Completo"
                  :value="
                    order.shipping.first_name + ' ' + order.shipping.last_name
                  "
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Empresa"
                  :value="order.shipping.company"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Dirección, línea 1"
                  :value="order.shipping.address_1"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Dirección, línea 2"
                  :value="order.shipping.address_2"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Ciudad"
                  :value="order.shipping.city"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Código postal"
                  :value="order.shipping.postcode"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="País"
                  :value="order.shipping.country"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Región"
                  :value="order.shipping.state"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Teléfono"
                  :value="order.shipping.phone"
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              v-for="shipping_lines in order.shipping_lines"
              :key="shipping_lines.id"
            >
              <v-col>
                <v-text-field
                  label="Método de envío"
                  :value="
                    shipping_lines.method_title + ': $' + shipping_lines.total
                  "
                  hide-details="auto"
                  outlined
                  readonly
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>Productos</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
                color="success"
                class="mb-2"
                type="button"
                @click="modalProductos.isShowModal = true"
              >
                Agregar Producto
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="order.line_items"
              :search="search"
            >
              <!-- <template v-slot:top>
                        <v-text-field
                        v-model="search"
                        label="Buscador"
                        class="mx-4"
                        dense
                        ></v-text-field>
                    </template> -->

              <template v-slot:[`item.articulo`]="{ item }">
                <v-row>
                  <v-col cols="2">
                    <v-avatar size="64px">
                      <v-img :src="item.image.src"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="6">
                    <p>{{ item.name }}</p>
                    <p>ID de la variación: {{ item.variation_id }}</p>
                    <p v-for="data in item.meta_data.filter(function(x){return x.display_key == 'Color'})" :key="data.id">
                      {{ data.display_key }}:{{ data.display_value }}
                    </p>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.price`]="{ item }">{{
                (item.price) | precio
              }}</template>
              <template v-slot:[`item.total`]="{ item }">{{
                item.total | precio
              }}</template>
              <template v-slot:[`item.quantity`]="{ item }">
                <v-text-field
                    v-model.number="item.quantity"
                    outlined
                    type="Number"
                    hide-details="auto"
                    min="0"
                    small-chips
                    dense
                    @input="item.total = (item.price * item.quantity).toString(),item.subtotal = (item.price * item.quantity).toString()"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col>
            <v-text-field
              label="Subtotal de artículos:"
              :value="
                subtotal | precio
              "
              hide-details="auto"
              outlined
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col>
            <v-text-field
              label="Envío:"
              :value="
                (order.shipping_lines.length > 0 ? order.shipping_lines[0].total : 0) | precio
              "
              hide-details="auto"
              outlined
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col>
            <v-text-field
              label="Total del pedido:"
              :value="( order.total - subTotalInicial ) + subtotal | precio"
              hide-details="auto"
              outlined
              readonly
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col>
            <v-btn
              color="error"
              type="button"
              @click="$emit('salir')"
              class="mr-2"
            >
              Cerrar
            </v-btn>
            <v-btn color="success" type="button" @click="GuardarOrder">
              Guardar cambios
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </bm-modal>
    <bm-order-products
      :modalProductos="modalProductos"
      :listaProductos="order.line_items"
      @cancelar="modalProductos.isShowModal = false"
      @agregar-producto="agregarProducto"
    />
  </div>
</template>
<script>
import BmModal from "@/components/modal/BmModalFull.vue";
import BmLoader from "@/components/loader/BmLoader.vue";
import AlertMixin from "@/mixins/AlertMixin.vue";
import EnumEcommerce from "@/utils/ecommerce/enums";
import OrdersService from "@/services/ecommerce/orders.service";
import ProductsService from '@/services/ecommerce/products.service'
import BmOrderProducts from './BmOrderProducts.vue';
export default {
  components: { BmModal, BmLoader, BmOrderProducts },
  loader: { isLoad: false, text: "" },
  mixins: [AlertMixin],
  name: "BmOrderDetail",
  data() {
    return {
      loader: { isLoad: false, text: "" },
      isShowModal: true,
      headers: [
        { text: "Artículo", value: "articulo" },
        { text: "Precio", value: "price" },
        { text: "Cantidad", value: "quantity" },
        { text: "Total", value: "total" },
      ],
      search: "",
      isDetalles: false,
      estatus: EnumEcommerce.status,
      orderEstatus: this.order.status,
      notes: [],
      subTotalInicial:0,
      products:[],
      modalProductos:{
        isShowModal:false
      }
    };
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async GuardarOrder() {
      if (
        !(await this.alertQuestion(
          "¿Desea actualizar el pedido?",
          null,
          "Si, actualizar!",
          "No, cancelar!"
        ))
      ) {
        return;
      }
      this.isShowModal = false;
      this.loader = {
        isLoad: true,
        text: "Actualizando pedido, por favor espere...",
      };

      let {
        id,
        parent_id,
        currency,
        customer_id,
        customer_note,
        billing,
        shipping,
        payment_method,
        payment_method_title,
        transaction_id,
        meta_data,
        line_items,
        shipping_lines,
        fee_lines,
        coupon_lines,
        set_paid,
      } = { ...this.order };

      let order = {
        id,
        parent_id,
        currency,
        customer_id,
        customer_note,
        billing,
        shipping,
        payment_method,
        payment_method_title,
        transaction_id,
        meta_data,
        line_items,
        shipping_lines,
        fee_lines,
        coupon_lines,
        set_paid,
        status,
      };

      order.status = this.orderEstatus;
      order.line_items.map(function (item) {
        return !item.parent_name ? (item.parent_name = "") : item.parent_name;
      });
      try {
        await OrdersService.UpdateOrder(order);
        this.isShowModal = true;
        this.subTotalInicial = this.order.line_items.reduce(
                  (n, { price, quantity }) => n + price * quantity,
                  0
                ) || 0 
        this.alertSuccess(null, "Pedido actualizado correctamente");
        this.$emit("get-data-orders",order.id);
      } catch (error) {
        this.isShowModal = true;
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
      }
    },
    async getNotes(id) {
      try {
        this.isShowModal = false;
        this.loader = {
          isLoad: true,
          text: "Obteniedo notas, por favor espere...",
        };
        this.notes = await OrdersService.GetNotes(id);
      } catch (error) {
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
        this.isShowModal = true;
      }
    },
    async GuardarCantidad() {
      if (
        !(await this.alertQuestion(
          "¿Desea actualizar la cantidad?",
          null,
          "Si, actualizar!",
          "No, cancelar!"
        ))
      ) {
        return;
      }
      this.isShowModal = false;
      this.loader = {
        isLoad: true,
        text: "Actualizando cantidad, por favor espere...",
      };

      try {  
        let order = {...this.order}
        order.line_items.map(function (item) {
          return !item.parent_name ? (item.parent_name = "") : item.parent_name;
        });
        
        await OrdersService.UpdateOrderProducts(order);
      
        this.alertSuccess(null, "Cantidad actualizado correctamente");
        this.isShowModal = true;
      } catch (error) {
        this.isShowModal = true;
        this.alertError(null, error.message);
      } finally {
        this.loader = { isLoad: false, text: "" };
      }
    },
    async getProducts() {
      try{
          this.loader = {isLoad:true,text:'Obteniedo productos, por favor espere...'}
          this.products = await ProductsService.GetProducts()
      }catch(error){
          this.alertError(null,error.message)
      }finally{
          this.loader = {isLoad:false,text:''}
      }
    },
    agregarProducto(producto){
      if(!producto) { return }
      this.$emit('add-product',producto)
      this.modalProductos.isShowModal = false
    }
  },
  watch:{
    order(){

    }
  },
  computed:{
    subtotal(){
      return this.order.line_items.reduce(
                  (n, { price, quantity }) => n + price * quantity,
                  0
                ) || 0 
    }
  },
  mounted() {
    this.subTotalInicial = this.order.line_items.reduce(
                  (n, { price, quantity }) => n + price * quantity,
                  0
                ) || 0 
  },
  created(){
    this.getProducts()
  }
};
</script>