<template>
    <bm-modal v-if="modalProspectos.isShowModal" :title="modalProspectos.titleModal">
        <template v-slot:modal-content>
        <v-form ref="formProspecto" v-model="validProspecto" @submit.prevent="handleFormProspecto">
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Razon social*"
                        v-model.trim="prospectoCaptura.razon_social"
                        :rules="razonSocialRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="prospectoCaptura.razon_social = prospectoCaptura.razon_social.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" md="6">
                    <v-text-field
                        label="Telefono"
                        v-model.trim="prospectoCaptura.telefono"
                        :rules="telefonoRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="prospectoCaptura.telefono = prospectoCaptura.telefono.toUpperCase()"
                    ></v-text-field>
                </v-col>
                <v-col cols="6" md="6">
                    <v-text-field
                        label="Correo electronico"
                        v-model.trim="prospectoCaptura.correo_electronico"
                        :rules="correoElectronicoRules"
                        outlined
                        hide-details="auto"
                        dense
                        @input="prospectoCaptura.correo_electronico = prospectoCaptura.correo_electronico.toUpperCase()"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        :items="canales"
                        item-text="desc_canal"
                        item-value="canal_id"
                        v-model="prospectoCaptura.canal_id"
                        :rules="canalesRules"
                        label="Canal*"
                        outlined
                        dense
                        hide-details="auto"
                    >
                    </v-autocomplete> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="estados"
                        item-text="desc_estado"
                        item-value="estado_id"
                        v-model="prospectoCaptura.estado_id"
                        
                        label="Estado"
                        outlined
                        hide-details="auto"
                        dense
                        @change="$emit('obtener-municipios',(prospectoCaptura.estado_id))"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-autocomplete
                        :items="municipios"
                        item-text="desc_municipio"
                        item-value="municipio_id"
                        v-model="prospectoCaptura.municipio_id"
                       
                        label="Municipio"
                        outlined
                        dense
                        hide-details="auto"
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="prospectoCaptura.fecha_contacto"
                            label="Fecha contacto"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                                hide-details="auto"
                            :rules="fechaRules"
                            dense
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="prospectoCaptura.fecha_contacto"
                            @input="menu = false"              
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row class="text-right">
                <v-col>
                    <v-btn
                        color="error"
                        type="button"
                        @click="$emit('cancelar')"
                        class="mr-2"
                    >
                    Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        type="submit"
                    >
                    Guardar
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        </template>
    </bm-modal>
</template>
<script>
import mapHelper from '@/store/modules/common/map-helper'
import BmModal from '@/components/modal/BmModal.vue'
export default {
    name:'BmProspectoDetalles',
    components:{BmModal},
    data(){
        return{
            prospectoCaptura:{
                razon_social: null,
                telefono: null,
                municipio_id: null,
                estado_id: null,
                canal_id: null,
                correo_electronico: null,
                fecha_contacto: null
            },
            canales:[],
            menu: false,
            validProspecto:null,
            razonSocialRules:[
                   v => !!v || 'La razón social es requerida',
            ],
            fechaRules:[
                v => !!v || 'La fecha es requerida',
            ],
            correoElectronicoRules:[
                // v => !!v || 'El correo electronico es requerido',
                v => (!v || ( v && /.+@.+/.test(v) )) || 'Correo electronico no valido', 
            ],
            // telefonoRules:[
            //     v => !!v || 'El telefono es requerido',
            // ],
            estadoRules:[
                // v => !!v || 'El estado es requerido',
            ],
            municipioRules:[
                // v => !!v || 'El municipio es requerido',
            ],
            canalesRules:[
                v => !!v || 'El canal es requerido',
            ],
            numIntRules:[],
            numExtRules:[],
            referenciaRules:[],
            codigoPostalRules:[],
            observacionesRules:[]
        }
    },
    methods:{
        async handleFormProspecto(){
            if(!this.$refs.formProspecto.validate()){
                return
            }

            if(this.modalProspectos.isNuevo){
                this.$emit('nuevo-prospecto',this.prospectoCaptura)
            }else{
                this.$emit('editar-prospecto',this.prospectoCaptura)
            }
        },
        async getCanales(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {idCatalogo:16,tipo:2}
                this.canales = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
    },
    watch:{
        prospecto(){
            this.prospectoCaptura = {...this.prospecto}
        }
    },
    props:{
        modalProspectos:{
            type:Object,
            default: () => ({
                isShowModal:false,
                isNuevo:false,
                titleModal:''
            })
        },
        prospecto:{
            type:Object,
            default: () => {}
        },
        prospecto_id:{
            type:Number,
            default: 0
        },
        estados:{
            type: Array,
            default: () => []
        },
        municipios:{
            type: Array,
            default: () => []
        }
    },
    async created(){
        this.getCanales()
    }
}
</script>