import types from '@/store/modules/common/mutation-types-core'
import mapHelper from '@/store/modules/common/map-helper'
import pedidoService from '@/services/pedido.service'

/*eslint-disable*/

const state = () => ({
    pedidos: []
})

const getters = {
    getPedidos : state => state.pedidos
}

const actions = {
    async createPedido( {} , payload){

        try{
            payload.pedidodetalles = payload.pedidodetalles
            let response = await pedidoService.CrearPedido(payload)   
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async createPedidoEstatus( {} , payload){
        try{
            let response = await pedidoService.CrearPedidoEstatus(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }
    },
    async updatePedido( { }, payload){

        try{
            let response = await pedidoService.EditarPedido(payload) 
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async delelePedido( { dispatch }, paylaod){

        try{
            let response = await productoService.EliminarProducto(paylaod)
            let filtro = { categoria_id: 0, material_id: 0, modelo_id: 0, tamano_id: 0, tipo_id: 0 }
            dispatch(mapHelper.mapfn.fetchProductos,filtro);        
            return response  
        }catch(error){
            throw new Error(error.message)
        }

    },
    async fetchPedidos( { commit }, payload){

        try{
            let response = await pedidoService.ObtenerPedidos(payload)
            commit(types.SET_PEDIDOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async fetchPedidosControl( { commit }, payload){

        try{
            let response = await pedidoService.ObtenerPedidosControl(payload)
            commit(types.SET_PEDIDOS,response.data)
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async getPedido( {  }, payload){

        try{
            let response = await pedidoService.ObtenerPedido(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async getPedidoDetalles( {  }, payload){

        try{
            let response = await pedidoService.ObtenerPedidoDetalles(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async getPedidoDetallesCajas( {  }, payload){

        try{
            let response = await pedidoService.ObtenerPedidoDetallesCajas(payload)
            return response
        }catch(error){
            throw new Error(error.message)
        }        
    },
    async cleanPedidos( { commit }){

        try{
            commit(types.SET_PRODUCTOS,[])
        }catch(error){
            throw new Error(error.message)
        }        
    },
}

const mutations = {

    [types.SET_PEDIDOS](state,pedidos){
        state.pedidos = pedidos
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}