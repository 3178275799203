<template>
    <div>
        <bm-loader v-if="loader.isLoad" :text="loader.text"/>
        <v-breadcrumbs
            class="pl-1"
            :items="breadCrumbs"
            large
            divider="/"
        ></v-breadcrumbs>
        <bm-filtro-producto></bm-filtro-producto>
        <v-card elevation="0" class="mb-4">
            <v-card-title>
            <h4>Productos</h4>
            </v-card-title>
            <v-card-text>
                <v-row class="text-right mb-2">
                    <v-col>
                        <v-btn
                            color="success"
                            type="button"
                            @click="isProductoDetalle = true, modalProductos.titleModal = 'AGREGAR UN NUEVO PRODUCTO',modalProductos.isNuevo = true, producto = {}"
                        >
                            Agregar producto
                        </v-btn>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headers"
                    :items="productos"
                    :search="search"
                >
                    <template v-slot:top>
                        <v-text-field
                        v-model="search"
                        label="Buscador"
                        class="mx-4"
                        dense
                        ></v-text-field>
                    </template>
                    <template v-slot:[`item.tamano_completo`]="{ item }">{{ item.tamano.desc_tamano }} - {{ item.tamano.medida }} </template>
                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-menu left bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                >
                                <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="producto = {...item}, modalProductos.titleModal = 'EDITAR PRODUCTO',isProductoDetalle = true, modalProductos.isNuevo = false">
                                    <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-if="isPermiso(8)" @click="producto = {...item},isProductoPrecio = true">
                                    <v-list-item-title><v-icon left> mdi-currency-usd </v-icon>Ver Precios</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip
                            class="ma-2"
                            :color="item.activo ? 'green_status' : 'red_status'"
                            text-color="white"
                        >
                            {{item.activo ? 'Activo' : 'Inactivo'}}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <bm-producto-detalle v-if="isProductoDetalle"          
            :modalProductos="modalProductos"
            :producto="producto"
            @nuevo-producto="nuevoProducto"
            @editar-producto="editarProducto"
            @cancelar="modalProductos.isShowModal = false,isProductoDetalle = false"
            @abrir="modalProductos.isShowModal = true"
        >
        </bm-producto-detalle>
        <bm-producto-precio v-if="isProductoPrecio"
            :producto="producto"
            @salir="isProductoPrecio = false"
        >
        </bm-producto-precio>
    </div>
</template>
<script>
import BmLoader from '@/components/loader/BmLoader.vue' 
import BmFiltroProducto from '@/components/producto/BmFiltroProducto.vue'
import BmProductoDetalle from '@/views/productos/components/BmProductoDetalle.vue'
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmProductoPrecio from '@/views/productos/components/BmProductoPrecio.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    components:{BmLoader,BmFiltroProducto,BmProductoDetalle,BmProductoPrecio},
    mixins:[AlertMixin],
    name:'BmProductos',
    data(){
        return{
            loader:{isLoad:false,text:''},
            
            breadCrumbs:[
                {
                    text: 'Productos',
                    disabled: false,
                    href: '/productos',
                },
            ],
            search: '',
            headers: [
                { text: 'Id', value: 'producto_id' },
                { text: 'Categoria', value: 'categoria.desc_categoria' },
                { text: 'Material', value: 'material.desc_material' },
                { text: 'Modelo', value: 'modelo.desc_modelo' },
                { text: 'Tamaño', value: 'tamano_completo' },
                { text: 'Tipo', value: 'tipo.desc_tipo' },
                { text: 'Stock', value: 'stock' , width: '5%'},
                { text: 'Peso', value: 'peso', width: '5%' },
                { text: '%', value: 'porcentaje', width: '5%' },
                { text: 'Estatus', value: 'activo' },
                { text: 'Acciones', value: 'acciones' , width: '5%'},
            ],
            modalProductos:{
                isShowModal:false,
                isNuevo:false,
                titleModal:''
            },
            modalPrecios:{
                isShowModal:false,
                isNuevo:false,
                titleModal:''
            },
            producto:{},
            isProductoPrecio:false,
            isProductoDetalle:false

        }
    },
    methods:{
        async fetchProductos(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{              
                let filtro = { categoria_id: 0, material_id: 0, modelo_id: 0, tamano_id: 0, tipo_id: 0, tipo_filtro:1 }
                await this.$store.dispatch(mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.fetchProductos,filtro)
            }catch(error){
                 this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async nuevoProducto(producto){
            this.modalProductos.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el producto?',null,"Si, crear!","No, cancelar!")){
                this.modalProductos.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando producto, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.createProducto,producto)
                this.alertSuccess(null,response.message)
                this.usuario = {}
                this.isProductoDetalle = false
            }catch(error){
                this.alertError(null,error.message)
                this.modalProductos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async editarProducto(producto){
            this.modalProductos.isShowModal = false
            if(!await this.alertQuestion('¿Desea editar el producto?',null,"Si, editar!","No, cancelar!")){
                this.modalProductos.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Editando producto, por favor espere...'}
            let payload = {
                payload : producto,
                filtro : { categoria_id: 0, material_id: 0, modelo_id: 0, tamano_id: 0, tipo_id: 0 , tipo_filtro:1}
            }
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.updateProducto,payload)
                this.alertSuccess(null,response.message)
                this.cliente = {}
                this.isProductoDetalle = false
            }catch(error){
                this.alertError(null,error.message)
                this.modalProductos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        }
           
        // async eliminarCliente(cliente){
        //     if(!await this.alertQuestion('¿Desea eliminar el cliente '+cliente.razon_social+'?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
        //         return
        //     }      
        //     this.loader = {isLoad:true,text:'Eliminando cliente, por favor espere...'}
        //     try{
        //         let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.deleteCliente,cliente.cliente_id)
        //         this.alertSuccess(null,response.message)
        //         this.usuario = {}
        //     }catch(error){
        //         this.alertError(null,error.message)
        //     }finally{
        //         this.loader = {isLoad:false,text:''}
        //     }
        // }
    },
    computed:{
        productos(){
            return this.$store.getters[mapHelper.mapmod.BYM_PRODUCTOS+mapHelper.mapfn.getProductos]
        },
        usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
    },
    mounted(){
        this.fetchProductos()
    }
}
</script>