<template>
    <div>
      <bm-loader v-if="loader.isLoad" :text="loader.text"/>
      <v-breadcrumbs
        class="pl-1"
        :items="breadCrumbs"
        large
        divider="/"
      ></v-breadcrumbs>
      <v-card elevation="0">
        <v-card-title>
          <h4>Prospectos</h4>
        </v-card-title>
      <v-card-text>
        <v-row class="text-right mb-2">
          <v-col>
              <v-select
                  :items="itemsFiltro"
                  label="Filtrar por"
                  item-text="desc"
                  item-value="tipo"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="tipo"
                  @change="getProspectos()"
              ></v-select>
          </v-col>
          <v-col v-if="isPermiso(17)">
              <v-select
                  :items="usuarios"
                  label="Filtrar por usuario"
                  :item-text=" item => item.nombre +' '+ item.apellido_paterno+' '+ item.apellido_materno"
                  item-value="usuario_id"
                  outlined
                  dense
                  hide-details="auto"
                  v-model="usuario_id_filtro"
                  @change="getProspectos()"
              ></v-select>
          </v-col>
          <v-col>
              <v-btn
                color="success"
                type="button"
                @click="modalProspectos.isShowModal = true, modalProspectos.titleModal = 'AGREGAR UN NUEVO PROSPECTO ',modalProspectos.isNuevo = true, prospecto = {}"
              >
                Agregar Prospecto
              </v-btn>
          </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="prospectos"
            :search="search"
        >
            <template v-slot:top>
                <v-text-field
                v-model="search"
                label="Buscador"
                class="mx-4"
                dense
                ></v-text-field>
            </template>
          <template v-slot:[`item.razon_social`]="{ item }">
              <v-chip
                v-if="item.no_cliente && !isFechaAnterior(item) "
                  class="ma-2"
                  :color="'grey_strong_status'"
                  text-color="white"
                >
                {{item.razon_social}}
              </v-chip>
              <v-chip
                v-else-if="item.prospectoComentario.length > 0  && !isFechaAnterior(item)"
                  class="ma-2"
                  :color="'yellow_status'"
                  text-color="white"
                >
                {{item.razon_social}}
              </v-chip>
              <v-chip
                v-else
                  class="ma-2"
                  :color="'red_status'"
                  text-color="white"
                  
                >
                {{item.razon_social}}
              </v-chip>
          </template>

          
          <template v-slot:[`item.es_cliente`]="{ item }">
            <v-chip
              class="ma-2"
              :color="item.cliente_id ? 'green_status' : 'red_status'"
              text-color="white"
            >
              {{item.cliente_id ? 'Si' : 'No'}}
            </v-chip>
          </template>

          <template v-slot:[`item.proxima_llamada`]="{ item }">
            <!-- {{getFechaLlamada(item.prospectoComentario || []) | fechaConvert}} -->
            {{item.prospectoComentario.length > 0 ? item.prospectoComentario[0].fecha_llamada : 'Sin datos'}}
          </template>

          <template v-slot:[`item.ultimo_comentario`]="{ item }">
            {{item.prospectoComentario.length > 0 ? item.prospectoComentario[0].descripcion.slice(0, 170) : 'Sin datos'}}
          </template>

          <template v-slot:[`item.fecha_contacto`]="{ item }">
            {{item.fecha_contacto | fechaConvert}}
          </template>
          
          <template v-slot:[`item.acciones`]="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="prospecto = {...item},getMunicipios(prospecto.estado_id), modalProspectos.isShowModal = true,modalProspectos.titleModal = 'EDITAR PROSPECTO', modalProspectos.isNuevo = false">
                  <v-list-item-title><v-icon left> mdi-table-edit </v-icon>Ver/Editar</v-list-item-title>
                </v-list-item>
                <v-list-item  @click="prospecto = item, cliente = {...item},getMunicipios(cliente.estado_id), modalClientes.isShowModal = true,modalClientes.titleModal = 'CREAR CLIENTE', modalClientes.isNuevo = true" v-if="!item.cliente_id && !item.no_cliente">
                  <v-list-item-title><v-icon left> mdi-account-cash-outline </v-icon>Crear cliente</v-list-item-title>
                </v-list-item>
                <v-list-item  @click="modalProspectoCometarios.isShowModal = true, modalProspectoCometarios.prospecto = item">
                  <v-list-item-title><v-icon left> mdi-comment-text-multiple-outline </v-icon>Comentarios</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="!item.no_cliente && !item.cliente_id" @click="noCliente(item)">
                  <v-list-item-title><v-icon left> mdi-account-cancel-outline </v-icon>No Cliente</v-list-item-title>
                </v-list-item>             
                <v-list-item v-if="isPermiso(16)" @click="eliminarProspecto(item)">
                  <v-list-item-title><v-icon left> mdi-table-row-remove </v-icon>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
         <!-- class="grey lighten-5" -->
      </v-card>
      <!-- <bm-clientes-detalles 
        :modalProspectos="modalProspectos"
        :cliente="cliente"
        :tipoClientes="tipoClientes"
        :estados="estados"
        :municipios="municipios"
        @nuevo-cliente="nuevoCliente"
        @editar-cliente="editarCliente"
        @obtener-municipios="getMunicipios"
        @cancelar="modalProspectos.isShowModal = false"
      >
      </bm-clientes-detalles> -->
      <bm-clientes-detalles 
        :modalClientes="modalClientes"
        :cliente="cliente"
        :tipoClientes="tipoClientes"
        :estados="estados"
        :municipios="municipios"
        :prospecto="prospecto"
        @nuevo-cliente="nuevoCliente"
        @obtener-municipios="getMunicipios"
        @cancelar="modalClientes.isShowModal = false"
      >
      </bm-clientes-detalles>
      <bm-prospectos-detalle
        :modalProspectos="modalProspectos"
        :prospecto="prospecto"
        :estados="estados"
        :municipios="municipios"
        @cancelar="modalProspectos.isShowModal = false"
        @obtener-municipios="getMunicipios"
        @nuevo-prospecto="nuevoProspecto"
        @editar-prospecto="editarProspecto"
      >
      </bm-prospectos-detalle>
      <bm-prospectos-comentarios
        v-if="modalProspectoCometarios.isShowModal"
        :prospecto="modalProspectoCometarios.prospecto"
        :filtro="{tipo:this.tipo,usuario_id:this.usuario_id_filtro}"
        @salir="modalProspectoCometarios.isShowModal = false"
      >
      </bm-prospectos-comentarios>

    </div>
</template>
<script>
import AlertMixin from '@/mixins/AlertMixin.vue'
import mapHelper from '@/store/modules/common/map-helper'
import BmLoader from '@/components/loader/BmLoader.vue'
import BmProspectosDetalle from './components/BmProspectosDetalle.vue'
import BmClientesDetalles from '@/views/clientes/components/BmClientesDetalles.vue'
import BmProspectosComentarios from './components/BmProspectosComentarios.vue'
import {isPermisoAccionValid} from '@/utils/permisoValidate'
export default {
    name:'BmProspectos',
    mixins:[AlertMixin],
    components:{BmProspectosDetalle,BmLoader,BmClientesDetalles,BmProspectosComentarios},
    data: () => ({
        search: '',
        headers: [
          { text: 'Razon social', value: 'razon_social' },
          { text : 'Cliente' , value : 'es_cliente'},
          { text: 'Telefono', value: 'telefono' },
          { text : 'Proxima llamada' , value : 'proxima_llamada'},
          //{ text: 'RFC', value: 'rfc' },
          //{ text: 'Fecha Contacto', value: 'fecha_contacto' },
          { text: 'Último comentario', value: 'ultimo_comentario' },
          //{ text: 'Municipio', value: 'municipio.desc_municipio'},
          { text: 'Acciones', value: 'acciones' },
        ],
        load:false,
        modalProspectos:{
          isShowModal:false,
          isNuevo:false,
          titleModal:''
        },
        modalClientes:{
          isShowModal:false,
          isNuevo:false,
          titleModal:''
        },
        loader:{isLoad:false,text:''},
        prospecto:{},
        cliente:{},
        activo: false,
        tipoClientes:[],
        estados:[],
        municipios:[],
        prospecto_id:null,
        breadCrumbs:[
          {
            text: 'Prospectos',
            disabled: false,
            href: '/prospectos',
          },
        ],
        modalProspectoCometarios:{
            isShowModal:false,
            prospecto:{},
        },
        itemsFiltro :[
          { tipo:1, desc: 'TODOS'},
          { tipo:2, desc: 'CLIENTES - SI'},
          { tipo:3, desc: 'CLIENTES - NO'},
          { tipo:4, desc: 'VIGENTES'},
          { tipo:5, desc: 'VENCIDOS'},
          { tipo:6, desc: 'CANCELADOS (NO CLIENTE)'},
        ],
        tipo:1,
        usuario_id_filtro:null
    }),
    methods:{

        async getData(){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                await this.getProspectos()
                await this.getTipoCliente()
                await this.getEstados()
                if(this.isPermiso(17)){
                  this.getUsuarios()
                }
            }catch(error){
                this.alertError(null,error.message)
            }finally{
               this.loader = {isLoad:false,text:''}
            }
        },
        async getProspectos() {
            try{
              this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
              let data = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
                await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.fetchProspectos,data)
            }catch(error){
                throw new Error(error.message)
            }finally{
               this.loader = {isLoad:false,text:''}
            }
        },
        async getTipoCliente(){
            let data = {idCatalogo:7,id:null}
            try{
                this.tipoClientes = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getEstados(){
            let data = {idCatalogo:8,id:null}
            try{
                this.estados = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                throw new Error(error.message)
            }
        },
        async getMunicipios(estado_id){
            this.loader = {isLoad:true,text:'Cargando, por favor espere...'}
            try{
                let data = {idCatalogo:9,id:estado_id}
                this.municipios = await this.$store.dispatch(mapHelper.mapmod.BYM_CATALOGOS+mapHelper.mapfn.getCatalogo,data)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async nuevoProspecto(prospecto){
            this.modalProspectos.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el prospecto?',null,"Si, crear!","No, cancelar!")){
                this.modalProspectos.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando prospecto, por favor espere...'}
            try{
                let filtro = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.createProspecto,{prospecto:prospecto,filtro:filtro})
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
                this.modalProspectos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async noCliente(prospecto){
            this.modalProspectos.isShowModal = false
            if(!await this.alertQuestion('¿Desea cancelar el prospecto?',null,"Si, cancelar!","No!")){
                return
            }
            let prospectoCancelado = {...prospecto,no_cliente:true}

            this.loader = {isLoad:true,text:'Cancelando prospecto, por favor espere...'}
            try{
                let filtro = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.updateProspecto,{prospecto:prospectoCancelado,filtro:filtro})
                this.alertSuccess(null,response.message)
                await this.getData()
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async editarProspecto(prospecto){
            this.modalProspectos.isShowModal = false
            if(!await this.alertQuestion('¿Desea editar el prospecto?',null,"Si, editar!","No, cancelar!")){
                this.modalProspectos.isShowModal = true
                return
            }
            let filtro = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
            this.loader = {isLoad:true,text:'Editando prospecto, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.updateProspecto,{prospecto:prospecto,filtro:filtro})
                this.alertSuccess(null,response.message)
                await this.getData()
            }catch(error){
                this.alertError(null,error.message)
                this.modalProspectos.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async eliminarProspecto(prospecto){
            if(!await this.alertQuestion('¿Desea eliminar el prospecto '+prospecto.razon_social+'?','No se podra revertir',"Si, eliminar!","No, cancelar!")){
                return
            }      
            this.loader = {isLoad:true,text:'Eliminando prospecto, por favor espere...'}
            let filtro = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.deleteProspecto,{prospecto_id:prospecto.prospecto_id,filtro:filtro})
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        async nuevoCliente({cliente,prospecto}){
            this.modalClientes.isShowModal = false
            if(!await this.alertQuestion('¿Desea crear el cliente?',null,"Si, crear!","No, cancelar!")){
                this.modalClientes.isShowModal = true
                return
            }
            
            this.loader = {isLoad:true,text:'Creando cliente, por favor espere...'}
            try{
                let response = await this.$store.dispatch(mapHelper.mapmod.BYM_CLIENTES+mapHelper.mapfn.createCliente,cliente)
                let data = {...prospecto,cliente_id:response.data.cliente_id}
                prospecto.cliente_id = response.cliente_id
                let filtro = {tipo:this.tipo,usuario_id:this.usuario_id_filtro}
                await this.$store.dispatch(mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.updateProspecto,{prospecto:data,filtro:filtro})
                this.alertSuccess(null,response.message)
            }catch(error){
                this.alertError(null,error.message)
                this.modalClientes.isShowModal = true
            }finally{
                this.loader = {isLoad:false,text:''}
            }
        },
        getFechaLlamada(comentarios){
          if(!comentarios || comentarios.length <= 0) { return }

          const fechasLlamada = comentarios.map(item => new Date(item.fecha_llamada).getTime());

          // Encontrar la fecha mínima de llamada
          const fechaMinimaLlamada = new Date(Math.min(...fechasLlamada));

          return fechaMinimaLlamada.toISOString().split('T')[0];
        },
        async getUsuarios() {
          try{
              await this.$store.dispatch(mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.fetchUsuarios)
            }catch(error){
              throw new Error(error.message)
            }
          },
        isPermiso(accion_id){
            return isPermisoAccionValid(this.usuario.rol.rol_id,accion_id)
        },
        getUsuariosListaFiltro(){
          let usuariosFiltro = this.usuarios
          let usuarioTodos = {
            usuario_id:null,
            nombre: "TODOS",
            apellido_paterno: "",
            apellido_materno: "",
          }
          usuariosFiltro.pop(usuarioTodos)
          return usuariosFiltro
        },
        isFechaAnterior(fecha){
          if(fecha.prospectoComentario.length <= 0){return false}
          const fechaHora = new Date();
          fechaHora.setUTCHours(0);
          return new Date(fecha.prospectoComentario[0].fecha_llamada) < fechaHora 

        }
    },
    computed:{
      prospectos(){

        let prospectosData = this.$store.getters[mapHelper.mapmod.BYM_PROSPECTOS+mapHelper.mapfn.getProspectos]
        const fechaHora = new Date();
        fechaHora.setUTCHours(0);

        if(this.tipo == 4){
          return prospectosData.filter((x) => x.prospectoComentario[0] && new Date(x.prospectoComentario[0].fecha_llamada) >= fechaHora)
        }else if(this.tipo == 5){
          return prospectosData.filter((x) => x.prospectoComentario[0] && new Date(x.prospectoComentario[0].fecha_llamada) < fechaHora)
        }

        return prospectosData
      },
      usuario(){
            return this.$store.getters[mapHelper.mapmod.BYM_AUTH+mapHelper.mapfn.getUsuario]
        },
      usuarios(){
        let usuariosFiltro = this.$store.getters[mapHelper.mapmod.BYM_USUARIOS+mapHelper.mapfn.getUsuarios]
          let usuarioTodos = {
            usuario_id:null,
            nombre: "TODOS",
            apellido_paterno: "",
            apellido_materno: "",
          }
          usuariosFiltro.push(usuarioTodos)
          return usuariosFiltro
      }
    },
    async mounted(){
      await this.getData()
    }
}
</script>