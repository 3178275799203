<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      persistent
      transition="dialog-bottom-transition"
    >
    <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <!-- <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <!-- <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Cerrar
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-container>
            <slot name="modal-content"></slot>
          </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    name:'BmModalFull',
    data: () => ({
      dialog: true,
    }),
    props:{
      title:{
        type:String,
        default:null
      }
    }
  }
</script>
<style scoped>
  .h3{
    color: red; 
  }
</style>